import { Column } from "@ant-design/plots";
import { Card } from "antd";

const ProxySpeedChart = ({ data }) => {
  return (
    <Card className="my-3 me-3">
      <div
        className="mb-4"
        style={{
          fontWeight: 700,
          fontSize: 15,
        }}
      >
        {data.title}
      </div>
      <div
        style={{
          width: 400,
          height: 250,
        }}
      >
        <Column data={data.dataSource} xField="label" yField="value" />
      </div>
    </Card>
  );
};

export default ProxySpeedChart;
