import { Card, Pagination, Table } from "antd";
import { useEffect, useState } from "react";
import axios from "axios";
import { clearToken } from "../../../scripts/token";
import config from "../../../config.json";
import { currencyFormat } from "../../../scripts/format";

export const ProductOrders = ({ product }) => {
  const [limit, setLimit] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [productOrders, setProductOrders] = useState([]);
  const [isLoadingProductOrders, setIsLoadingProductOrders] = useState(false);
  const [totalPageCount, setTotalPageCount] = useState(0);

  useEffect(() => {
    getProductOrders();
  }, [product, currentPage, limit]);

  const getProductOrders = () => {
    setIsLoadingProductOrders(true);
    axios
      .get(
        `${config.service}/api/order/${product.id}/orders/?&limit=${limit}&page=${currentPage}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              "admin_access_token"
            )}`,
          },
        }
      )
      .then((data) => {
        setTotalPageCount(data.data.totalCount);
        setProductOrders(data.data.productOrders);
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          clearToken();
        }
      })
      .finally(() => {
        setIsLoadingProductOrders(false);
      });
  };

  const getProductStateName = (state) => {
    switch (state) {
      case "NEW":
        return "Новый заказ";
      case "SIGN_REQUIRED":
        return "Заказ на подписании";
      case "PICKUP":
        return "Самовывоз";
      case "DELIVERY":
        return "Доставка";
      case "KASPI_DELIVERY":
        return "Kaspi Доставка, Kaspi Postomat";
      case "ARCHIVE":
        return "Архивный заказ";
      default:
        return "Статус неизвестен";
    }
  };

  const getProductStatusName = (status) => {
    switch (status) {
      case "APPROVED_BY_BANK":
        return "Одобрен банком";
      case "ACCEPTED_BY_MERCHANT":
        return "Принят на обработку продавцом";
      case "COMPLETED":
        return "Завершён";
      case "CANCELLED":
        return "Отменён";
      case "CANCELLING":
        return "Ожидает отмены";
      case "KASPI_DELIVERY_RETURN_REQUESTED":
        return "Ожидает возврата";
      case "RETURN_ACCEPTED_BY_MERCHANT":
        return "Ожидает решения по возврату";
      case "RETURNED":
        return "Возвращён";
      default:
        return "Состояние неизвестен";
    }
  };

  const dataSource = productOrders.map((item) => ({
    ...item,
    key: item._id,
    totalPrice: <div className="nowrap">{currencyFormat(item.totalPrice)}</div>,
    state: getProductStateName(item.state),
    status: getProductStatusName(item.status),
    creationDate: new Date(item.creationDate).toLocaleString(),
  }));

  const columns = [
    {
      title: <div className="nowrap">Код заказа</div>,
      dataIndex: "orderCode",
      key: "orderCode",
    },
    {
      title: "Цена",
      dataIndex: "totalPrice",
      key: "totalPrice",
    },
    {
      title: <div className="nowrap">Кол-во</div>,
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Состояние",
      dataIndex: "state",
      key: "state",
    },
    {
      title: "Статус",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Пунк выдачи",
      dataIndex: "addressDisplayName",
      key: "addressDisplayName",
    },
    {
      title: "Город",
      dataIndex: "town",
      key: "town",
    },
    {
      title: "Дата",
      dataIndex: "creationDate",
      key: "creationDate",
    },
  ];

  const getProductOrdersPagination = () => {
    return (
      <div className="d-flex justify-content-center">
        <Pagination
          className="mt-3"
          current={currentPage}
          disabled={isLoadingProductOrders}
          total={totalPageCount}
          onChange={(value) => {
            if (value === currentPage) {
              return;
            }

            setCurrentPage(value);
          }}
          onShowSizeChange={async (current, size) => {
            if (size === limit) {
              return;
            }

            setLimit(size);
          }}
          defaultPageSize={limit}
          size={limit}
        />
      </div>
    );
  };

  return (
    <div>
      <Card>
        <Table
          bordered
          size="small"
          dataSource={dataSource}
          columns={columns}
          loading={isLoadingProductOrders}
          pagination={false}
        />

        {getProductOrdersPagination()}
      </Card>
    </div>
  );
};
