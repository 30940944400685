import { Button, Form, Input, InputNumber, message, Modal, Select } from "antd";
import axios from "axios";
import config from "../../../config.json";
import { useState } from "react";

const activationTypes = [
  {
    value: "MONTH",
    label: "Месяц",
  },
  {
    value: "WEEK",
    label: "Неделя",
  },
  {
    value: "DAY",
    label: "День",
  },
  {
    value: "HOUR",
    label: "Час",
  },
];

const activationTariffTypes = [
  {
    value: "tariff250k",
    label: "250к",
  },
  {
    value: "tariff1.2M",
    label: "1,2 млн",
  },
];

const ActivateAnalytics = ({ user }) => {
  const [activationTariffType, setActivationTariffType] = useState(
    activationTariffTypes[0].value
  );
  const [visible, setVisible] = useState(false);
  const [price, setPrice] = useState(0);
  const [activationType, setActivationType] = useState(
    activationTypes[0].value
  );
  const [value, setValue] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const handleActivate = () => {
    setAnalyticsAccessTariff()
    setIsLoading(true);

    const body = {
      price: parseInt(price),
    };
    body[activationType.toLowerCase()] = value;

    const url = `${config.service}/api/payment/client/analytics/${user._id}`;

    axios
      .post(url, body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
        },
      })
      .then(() => {
        message.success("Аналитика пользователя успешно активирована");
      })
      .catch(() => {
        message.error("Не удалось активировать аналитику");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const setAnalyticsAccessTariff = () => {
    setIsLoading(true);
    
    axios
      .post(
        `${config.service}/api/analytics-access/${user._id}/set-tariff?&activationTariffType=${activationTariffType}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              "admin_access_token"
            )}`,
          },
        }
      )
      .then(() => {
        message.success("Доступ к аналитике успешно обновлен");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getActivationTypeLabel = () => {
    const found = activationTypes.find((v) => v.value === activationType);

    if (found) {
      return found.label;
    }

    return "Месяц";
  };

  return (
    <>
      <Button
        className="ms-3"
        type="primary"
        onClick={() => setVisible(true)}
        disabled={isLoading}
      >
        Активировать аналитику
      </Button>
      <Modal
        title="Активировать аналитику"
        visible={visible}
        destroyOnClose
        onCancel={() => setVisible(false)}
        okButtonProps={{
          loading: isLoading,
        }}
        okText="Активировать"
        cancelText="Закрыть"
        onOk={handleActivate}
      >
        <Form>
          <Form.Item>
            <div>Пользователь:</div>
            <Input disabled={true} value={user?.name + " " + user?.surname} />
          </Form.Item>
          <Form.Item>
            <div>Цена:</div>
            <InputNumber
              prefix={
                <div className="border-right pe-1" style={{ fontSize: 16 }}>
                  ₸
                </div>
              }
              placeholder="Сумма"
              value={price + ""}
              style={{ width: "100%" }}
              onChange={(event) => {
                if (event) {
                  event = event + "";
                  setPrice(event);
                } else {
                  setPrice("0");
                }
              }}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
              }
              parser={(value) => {
                value = value.toString();

                if (!value) {
                  return "";
                }

                const newValue = value.replace(/\$\s?|( *)/g, "");

                return newValue;
              }}
            />
          </Form.Item>
          <Form.Item>
            <div>Тип активации:</div>
            <Select
              value={activationType}
              options={activationTypes}
              onChange={(event) => {
                setActivationType(event);
              }}
            />
          </Form.Item>
          <Form.Item>
            <div>Срок активации ({getActivationTypeLabel()}):</div>
            <InputNumber
              placeholder={"Кол-во"}
              min={0}
              value={value}
              style={{ width: "100%" }}
              onChange={(event) => {
                setValue(event);
              }}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
              }
              parser={(value) => {
                if (!value) {
                  return "";
                }

                return value.replace(/\$\s?|( *)/g, "");
              }}
            />
          </Form.Item>
          <Form.Item>
          <div>Тарифы:</div>
            <Select
              value={activationTariffType}
              options={activationTariffTypes}
              onChange={(event) => {
                setActivationTariffType(event);
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ActivateAnalytics;
