import { Gauge } from "@ant-design/plots";
import axios from "axios";
import { useEffect, useState } from "react";
import { currencyFormat } from "../../../scripts/format";
import conf from "../../../config.json";
import { Card, Col, Row, Spin, Statistic } from "antd";
import { getLastDayOfMonth } from "../../../scripts/date";
import Countdown from "antd/lib/statistic/Countdown";
import useIsMobile from "../../../hooks/useIsMobile";

const GaugeTotalPricePlan = ({refreshCounter}) => {
  const isMobile = useIsMobile()
  const [plan, setPlan] = useState(0);
  const [analyticsPlan, setAnalyticsPlan] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalPriceSubscription, setTotalPriceSubscription] = useState(0);
  const [totalPriceAnalytics, setTotalPriceAnalytics] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    axios
      .get(`${conf.service}/api/payment/total-price`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
        },
      })
      .then((data) => {
        setPlan(data.data?.plan || 0);
        setTotalPrice(data.data?.totalPrice || 0);
        setTotalPriceSubscription(data.data?.totalPriceSubscription || 0);
        setTotalPriceAnalytics(data.data?.totalPriceAnalytics || 0);
        setAnalyticsPlan(data.data?.analyticsPlan || 0);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [{refreshCounter}]);

  if (isLoading) {
    return <Spin />;
  }

  return (
    <Card
      style={{
        marginBottom: 25,
      }}
    >
      <div className="d-flex flex-wrap justify-content-center mb-5 w-100">
        <div
          style={{
            maxWidth: 600,
          }}
          className="m-2"
        >
          <GaugeTotalPrice totalPrice={totalPriceSubscription} plan={plan} />{" "}
          <h2 className="text-center">Подписка и VIP магазины</h2>
        </div>
        <div
          style={{
            maxWidth: 600,
          }}
          className="m-2"
        >
          <GaugeTotalPrice
            totalPrice={totalPriceAnalytics}
            plan={analyticsPlan}
          />
          <h2 className="text-center">Аналитика</h2>
        </div>
      </div>
      <div
        style={{
          fontSize: 16,
        }}
      >
        <Row>
          
         
          {isMobile
            ?
              <>
                      <Row style={{width:'100%'}}>
                <Statistic
                    style={{display:'flex',alignItems:'center',justifyContent:'space-between',minWidth:'100%',gap:'20px',flexDirection:'row'}}
                    title="План"
                    value={currencyFormat(plan + analyticsPlan)}
                  />
                </Row>
                <Row style={{paddingTop:'10px',width:'100%'}}>
                  <Statistic
                    title="Текущяя сумма"
                    value={currencyFormat(totalPrice)}
                    style={{display:'flex',alignItems:'center',justifyContent:'space-between',minWidth:'100%',gap:'20px',flexDirection:'row'}}
                  />
                </Row>
                <Row style={{paddingTop:'10px',paddingBottom:'20px',width:'100%'}}>
                  <Statistic
                    title="Осталось"
                    value={currencyFormat(plan + analyticsPlan - totalPrice)}
                    style={{display:'flex',alignItems:'center',justifyContent:'space-between',minWidth:'100%',gap:'20px',flexDirection:'row'}}
                  />
                </Row>
              </>
            :
              <>
                <Col span={8}>
                  <Statistic
                    title="План"
                    value={currencyFormat(plan + analyticsPlan)}
                  />
                </Col> 

                <Col span={8}>
                  <Statistic
                    title="Текущяя сумма"
                    value={currencyFormat(totalPrice)}
                    style={{ fontSize: 12 }}
                  />
                </Col>
                <Col span={8}>
                  <Statistic
                    title="Осталось"
                    value={currencyFormat(plan + analyticsPlan - totalPrice)}
                  />
                </Col>
              </>
          }
          

          <Col span={16} style={{ marginTop: 12 }}>
            <Countdown
              title="До конца месяца осталось"
              value={getLastDayOfMonth(new Date().getMonth()).getTime()}
              format="D дн. H ч. m мин. s сек."
            />
          </Col>
        </Row>
      </div>
    </Card>
  );
};

const GaugeTotalPrice = ({ totalPrice, plan }) => {
  const [percent, setPercent] = useState(0);
  const isMobile = useIsMobile(560)
  useEffect(() => {
    setPercent(totalPrice / plan);
  }, [plan, totalPrice]);

  const getFormattedLabelPrice = (totalPrice) => {
    let label = currencyFormat(totalPrice);

    if (totalPrice.toString().length > 7) {
      label = `${totalPrice.toString()[0]}${totalPrice.toString()[1]},${
        totalPrice.toString()[2]
      } млн`;
    } else if (totalPrice.toString().length > 6) {
      label = `${totalPrice.toString()[0]},${totalPrice.toString()[1]} млн`;
    }

    return label;
  };

  const config = {
    percent,
    radius: 0.75,
    range: {
      ticks: [0, 1],
      color: ["l(0) 0:#F4664A 0.5:#FAAD14 1:#30BF78"],
      width: isMobile ? 8 : 10,
    },
    indicator: {
      pointer: {
        style: {
          stroke: "#D0D0D0",
        },
      },
      pin: {
        style: {
          stroke: "#D0D0D0",
        },
      },
    },
    statistic: {
      content: {
       
        formatter: () => {
          return `${getFormattedLabelPrice(totalPrice)} ₸`;
        },
        style:{fontSize: isMobile ? 18 : 22},
      },
      style: {
        fontSize: 18,
      },
    },
    gaugeStyle: {
      lineCap: "round",
    },
    axis: {
      label: {
        formatter(v) {
          return getFormattedLabelPrice(Number(v) * plan);
        },
      },
      subTickLine: {
        count: 5,
      },
    },
  };

  return (
    <div className="d-flex justify-content-center">
      <Gauge
        {...config}
        className="p-0 m-0"
        width={400}
        style={{ width: isMobile ? 320 : 400 }}
      />
    </div>
  );
};

export default GaugeTotalPricePlan;
