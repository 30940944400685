import { Checkbox, message } from "antd";
import axios from "axios";
import { useState } from "react";
import config from "../../../config.json";

const ChangeProxyActive = ({ proxy }) => {
  const [value, setValue] = useState(proxy.isActive);
  const [isLoading, setIsLoading] = useState(false);

  const change = (newValue) => {
    setIsLoading(true);

    const body = {
      isActive: newValue,
    };
    const options = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
      },
    };
    const url = `${config.service}/api/proxy/${proxy._id}`;

    axios
      .patch(url, body, options)
      .then(() => {
        message.success(`Обновлено`);

        setValue(newValue);
      })
      .catch(() => {
        message.error("Не удалось обновить");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div>
      <Checkbox
        disabled={isLoading}
        checked={value}
        onChange={(v) => {
          change(v.target.checked);
        }}
      >
        {value ? "Да" : "Нет"}
      </Checkbox>
    </div>
  );
};

export default ChangeProxyActive;
