import { Button, Popconfirm } from "antd";
import config from "../../../config.json";
import axios from "axios";
import { useState } from "react";

const DeleteGatheringForStoreButton = ({ store, fetch }) => {
  const [isLoading, setIsLoading] = useState(false);

  const deleteGathering = () => {
    setIsLoading(true);

    axios
      .delete(
        `${config.service}/api/store-product-position-analytics/stores/${store.storeForGatheringProductAnalyticsId}/delete`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              "admin_access_token"
            )}`,
          },
        }
      )
      .then(() => {
        fetch();
      })
      .finally(() => setIsLoading(false));
  };

  if (!store.analyticsGatheringExpirationDate) {
    return <></>;
  }

  if (new Date(store.analyticsGatheringExpirationDate) > new Date()) {
    return <></>;
  }

  return (
    <Popconfirm
      title={`Вы точно хотите удалить данные для магазина ${
        store.storeName || ""
      }?`}
      okText="Удалить"
      okButtonProps={{
        className: "text-danger border-danger bg-danger text-white",
      }}
      onConfirm={deleteGathering}
    >
      <Button className="text-danger border-danger mb-3" loading={isLoading}>
        Удалить все записи
      </Button>
    </Popconfirm>
  );
};

export default DeleteGatheringForStoreButton;
