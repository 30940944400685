import { Descriptions } from "antd";

const Privilege = ({ store }) => {
  return (
    <div>
      <Descriptions labelStyle={{color: '#202020'}}column={1}>
        <Descriptions.Item label="Дата активации">
          <b>
            {new Date(store?.privilege?.activatedDate || 0).toLocaleString()}
          </b>
        </Descriptions.Item>
        <Descriptions.Item label="Актвировал">
          <b>{store?.privilege?.activatedUser?.login || "Не известно"}</b>
        </Descriptions.Item>
        <Descriptions.Item label="Работает">
          <b>{store?.privilege?.isActive ? "Да" : "Нет"}</b>
        </Descriptions.Item>
        <Descriptions.Item label="В блоке">
          <b>{store?.privilege?.isBlocked ? "Да" : "Нет"}</b>
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
};

export default Privilege;
