import { Card, InputNumber, Skeleton } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import config from "../../../config.json";
import { currencyFormat } from "../../../scripts/format";

const PriceCalculator = () => {
  const [loads, setLoads] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [productsCount, setProductsCount] = useState(0);
  const [price, setPrice] = useState(0);

  useEffect(() => {
    axios
      .get(`${config.service}/api/product/total/loads`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
        },
      })
      .then((data) => {
        setLoads(data.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    setPrice(calculatePrice());
  }, [productsCount, loads]);

  const calculatePrice = () => {
    try {
      const sebest =
        (loads?.budget?.costs || 0) / loads?.allActiveProductsCount || 1;

      let nextMinPrice = sebest * productsCount;
      nextMinPrice =
        nextMinPrice + (nextMinPrice * loads?.budget?.margin || 0) / 100;
      nextMinPrice /= 1000.0;
      nextMinPrice = parseInt(Math.ceil(nextMinPrice) + 1 + "");
      nextMinPrice *= 1000;
      nextMinPrice = nextMinPrice < 13000 ? 13000 : nextMinPrice;

      return nextMinPrice;
    } catch (e) {
      console.log(e);

      return 0;
    }
  };

  return (
    <div
      style={{
        marginBottom: 15,
      }}
    >
      <Card>
        <h1
          style={{
            marginBottom: 15,
          }}
        >
          Калькулятор
        </h1>
        {isLoading && <Skeleton active={true} />}
        {!isLoading && (
          <div>
            <InputNumber
              className="calculator-input"
              value={productsCount}
              onChange={(event) => {
                setProductsCount(event);
              }}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
              }
              parser={(value) => {
                if (!value) {
                  return "";
                }

                return value.replace(/\$\s?|( *)/g, "");
              }}
            />
            <div
              style={{
                marginTop: 15,
              }}
            >
              Цена: <b>{currencyFormat(price)}</b>
            </div>
          </div>
        )}
      </Card>
    </div>
  );
};

export default PriceCalculator;
