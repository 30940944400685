import { Card, Table } from "antd";
import { Link } from "react-router-dom";
import { currencyFormat } from "../../../scripts/format";

const UserReferralStatsList = ({ record }) => {
  return (
    <Card>
      <Table
        bordered
        size="small"
        columns={[
          {
            dataIndex: "fullName",
            key: "fullName",
            title: "ФИО",
          },
          {
            dataIndex: "phoneNumber",
            key: "phoneNumber",
            title: "Номер тел.",
          },
          {
            dataIndex: "totalStoresCount",
            key: "totalStoresCount",
            title: "Магазины",
          },
          {
            dataIndex: "totalPrice",
            key: "totalPrice",
            title: "Сумма",
          },
        ]}
        dataSource={getDataSource(record.users)}
      />
    </Card>
  );
};

const getDataSource = (data) => {
  const result = [];

  if (Array.isArray(data)) {
    for (let d of data) {
      result.push({
        key: d._id,
        data: d,
        fullName: <Link to={`/client/${d._id}`}>{d.fullName}</Link>,
        phoneNumber: d.phoneNumber,
        totalUsersCount: d?.totalUsersCount || 0,
        totalStoresCount: d?.totalStoresCount || 0,
        totalPrice: currencyFormat(d?.totalPrice || 0),
      });
    }
  }

  return result;
};

export default UserReferralStatsList;
