import React, { useEffect, useRef, useState } from 'react';
import { DollarOutlined, UserOutlined, PlusOutlined, CustomerServiceOutlined } from '@ant-design/icons';
import { Layout, Menu, theme, Avatar, Typography, Button, Select, Space, Divider, Input, message } from 'antd';
import { formatPhoneNumber } from '../utils/helper';
import { addTag, changeResponsibleAdmin, closeChat } from '../utils/fetch';

const UserHeader = ({ user, tags, setTags, setCurrentUser }) => {
    const { Paragraph } = Typography;
    const [name, setName] = useState('');
    const [selectedTag, setSelectedTag] = useState('')
    const inputRef = useRef(null);
    const chatRole = localStorage.getItem('chatRole')
    const onNameChange = (event) => {
        setName(event.target.value);
    };
    const addItem = async (e) => {
        try {
            e.preventDefault();
            setName('');
            setTimeout(() => {
                inputRef.current?.focus();
            }, 0);
            await addTag(name)
        }
        catch (e) {
            message.error(e)
        }
        finally {
            message.success("Добавлен новый тег!")
            setTags([...tags, { name }]);

        }
    };

    const onCloseChat = async (e) => {
        if (selectedTag == '') {
            message.warning("Пожалуйста, выберите тег для закрытия чата!")
            return
        }
        else {
            try {
                await closeChat(user.chatId, selectedTag)
            }
            catch (e) {
                message.error(e)
            }
            finally {
                setCurrentUser({})
                message.success("Чат успешно закрыт!")
            }
        }
    };
    const sendToOP = async (e) => {
        try {
             await changeResponsibleAdmin(user.chatId, "SALES_MANAGER")
        }
        catch (e) {
            message.error(e)
        }
        finally {
            setCurrentUser({})
            message.success("Чат успешно отправлен в Отдел Продаж!")
        }

    };
    const sendToTech = async (e) => {
        try {
            await changeResponsibleAdmin(user.chatId, "TECH_MANAGER")
        }
        catch (e) {
            message.error(e)
        }
        finally {
            setCurrentUser({})
            message.success("Чат успешно отправлен в Тех Поддержку!")
        }

    };

    useEffect(()=>{
        setSelectedTag('') // TODO
    }, [user])
    if (!user.message) return <></>
    return (
        <>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", alignContent: 'center', gap: '8px' }}>
                <div style={{ height: '50px', display: 'flex' }}> <Avatar style={{ backgroundColor: '#87d068' }} size={50} icon={<UserOutlined style={{ fontSize: '24px' }} />} /></div>
                <div style={{ display: "grid", justifyContent: 'start' }}>
                    {user.message?.contactName && (
                        <>
                            <Paragraph style={{ margin: 0, color: "#090909", fontSize: "1rem", fontWeight: '500', lineHeight: '1' }} >
                                {user.message.contactName}
                            </Paragraph>
                            <Paragraph style={{ margin: 0, color: "#090909", fontSize: "0.8rem", fontWeight: '500', lineHeight: '1.5' }} >
                                {formatPhoneNumber(user.chatId)}
                            </Paragraph>
                        </>
                    )}
                    {!user.message?.contactName && (<Paragraph style={{ margin: 0, color: "#090909", fontSize: "1rem", fontWeight: '500', lineHeight: '1' }} > {formatPhoneNumber(user.chatId)}</Paragraph>)}

                </div>
            </div>
            <div style={{ display: 'flex', height: '100%', gap: '15px', alignItems: 'center' }}>
                <Space direction="vertical" style={{ width: '200px' }}>
                    <Select placeholder="Выберите тег" status="warning" value={selectedTag} style={{ width: '100%' }}
                        dropdownRender={(menu) => (chatRole == "ADMIN" ?
                            <>
                                {menu}
                                <Divider
                                    style={{
                                        margin: '8px 0',
                                    }}
                                />
                                <Space
                                    style={{
                                        padding: '0 8px 4px',
                                    }}
                                >
                                    <Input
                                        placeholder="Введите тег"
                                        ref={inputRef}
                                        value={name}
                                        onChange={onNameChange}
                                        onKeyDown={(e) => e.stopPropagation()}
                                    />
                                    <Button type="text" icon={<PlusOutlined />} onClick={addItem}></Button>
                                </Space>
                            </>
                            : <>{menu}</>
                        )}
                        options={
                            tags?.map(tag => ({ value: tag.name, label: tag.name }))
                        }
                        onChange={(event) => {
                            setSelectedTag(event);
                        }} />
                </Space>
                <Button danger onClick={onCloseChat}>Закрыть чат</Button>
                {chatRole == "TECH_MANAGER" && <Button type="primary" style={{ backgroundColor: '#42b625' }} icon={<DollarOutlined />} onClick={sendToOP}>Отправить в ОП</Button>}
                {chatRole == "SALES_MANAGER" && <Button type="primary" style={{ backgroundColor: '#253fb6' }} icon={<CustomerServiceOutlined />} onClick={sendToTech}>Тех поддержка</Button>}

            </div>
        </>

    )
}

export default UserHeader;