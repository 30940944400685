import { Card, Tree } from "antd";
import { PageHeader } from '@ant-design/pro-components';
import { Link } from "react-router-dom";
import useIsMobile from "../../hooks/useIsMobile";

const SaleScoutGuide = () => {
  const isMobile = useIsMobile()
  return (
    <Card className={isMobile ? 'mx-2' : 'mx-3'}>
      <PageHeader title="Как работает система SaleScout.me?" />
      <Tree
        treeData={[
          {
            title: <Link to="/guide">Пользователь</Link>,
            children: [],
          },
          {
            title: <Link to="/guide">Магазин</Link>,
            children: [
              {
                title: <Link to="/guide">Как добавить магазин в систему?</Link>,
              },
              {
                title: <Link to="/guide">Как удалить магазин из системы?</Link>,
              },
              {
                title: <Link to="/guide">Статус "Неверные уч. данные"</Link>,
              },
              {
                title: (
                  <Link to="/guide/store/price-change-methods">
                    Методы ценообразования
                  </Link>
                ),
              },
              {
                title: <Link to="/guide">Кнопка "Активировать магазин"</Link>,
              },
              {
                title: <Link to="/guide">Кнопка "Добавить город"</Link>,
              },
              {
                title: <Link to="/guide ">Кнопка "Сбросить активацию"</Link>,
              },
              {
                title: <Link to="/guide ">Вкладка "Настройки"</Link>,
              },
              {
                title: (
                  <Link to="/guide ">Вкладка "API токен для заказов"</Link>
                ),
              },
              {
                title: (
                  <Link to="/guide ">
                    Как остановить обновление цен на магазине?
                  </Link>
                ),
              },
            ],
          },
          {
            title: <Link to="/guide/products">Товары</Link>,
            children: [
              {
                title: (
                  <Link to="/guide ">
                    Лимиты маркетплейсов на изменение цены
                  </Link>
                ),
              },
              {
                title: (
                  <Link to="/guide ">
                    Актуализация состояние товаров с маркетплейсом (Кнопка
                    "Обновить список")
                  </Link>
                ),
              },
            ],
          },
          {
            title: <Link to="/guide ">Заказы</Link>,
          },
          {
            title: <Link to="/guide ">Аналитика</Link>,
          },
          {
            title: <Link to="/guide/turbo-analytics">Turbo Аналитика</Link>,
          },
          {
            title: <Link to="/guide/kaspi-courses">Kaspi обучение</Link>,
          },
          {
            title: (
              <Link to="/guide/daily-analytics">
                Ежедневная аналитика (Скрытый шедевр)
              </Link>
            ),
            children: [
              {
                title: <Link to="/guide/daily-analytics">Как работает?</Link>,
              },
              {
                title: (
                  <Link to="/guide/daily-analytics">Как активировать?</Link>
                ),
              },
            ],
          },
        ]}
        defaultExpandAll
      />
    </Card>
  );
};

export default SaleScoutGuide;
