import axios from "axios";
import config from "../../../config.json";

export const fetchInitialMessages = (chatId, offset) => {
  return axios.get(`${config.service}/api/whatsapp/messages/?chatId=${chatId}&offset=${offset}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
    },
  });
};


export const closeChat = (chatId, tag) => {
  return axios.post(`${config.service}/api/whatsapp/close-chat?chatId=${chatId}&tag=${tag}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
    },
  });
};

export const changeResponsibleAdmin = (chatId, chatRole) => {
  return axios.post(`${config.service}/api/whatsapp/change-manager?chatId=${chatId}&chatRole=${chatRole}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
    },
  });
};

export const getTags = () => {
  return axios.get(`${config.service}/api/whatsapp/tags`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
    },
  });
};


export const addTag = (name) => {
  return axios.post(`${config.service}/api/whatsapp/tags?name=${name}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
    },
  });
};



