import { Button, Card, Table } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import config from "../../../config.json";
import { currencyFormat } from "../../../scripts/format";
import { isBoolean, isNumber } from "class-validator";

const ProductInCity = ({ productId }) => {
  const [productInCities, setProductInCities] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    fetch();
  }, [productId]);
  const fetch = () => {
    setIsLoading(true);

    axios
      .get(`${config.service}/api/kaspi-product-in-city/${productId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
        },
      })
      .then((data) => {
        setProductInCities(data.data);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    const result = [];

    for (const productInCity of productInCities) {
      result.push({
        ...productInCity,
        price: productInCity.price ? (
          currencyFormat(productInCity.price)
        ) : (
          <i className="text-secondary">Нет данных</i>
        ),
        firstPlacePrice: productInCity.firstPlacePrice ? (
          currencyFormat(productInCity.firstPlacePrice)
        ) : (
          <i className="text-secondary">Нет данных</i>
        ),
        lastCheckedDate: productInCity.lastCheckedDate ? (
          new Date(productInCity.lastCheckedDate).toLocaleString()
        ) : (
          <i className="text-secondary">Нет данных</i>
        ),
        isMinus: isBoolean(productInCity.isMinus)
          ? productInCity.isMinus
            ? "Да"
            : "Нет"
          : "",
        place: isNumber(productInCity.place) ? productInCity.place : "",
      });
    }

    setDataSource(result);
  }, [productInCities]);

  return (
    <div>
      <div className="mb-3">
        <Button onClick={fetch}>Обновить</Button>
      </div>
      <div
        style={{
          overflow: "auto",
          maxWidth: "100%",
        }}
      >
        <Table
          style={{
            minWidth: 800,
          }}
          loading={isLoading}
          bordered
          pagination={false}
          dataSource={dataSource}
          columns={[
            {
              title: "Город",
              dataIndex: "cityName",
              key: "",
            },
            {
              title: "ID города",
              dataIndex: "cityId",
              key: "",
            },
            {
              title: "Цена",
              dataIndex: "price",
              key: "",
            },
            {
              title: "Цена 1 места",
              dataIndex: "firstPlacePrice",
              key: "",
            },
            {
              title: "Позиция",
              dataIndex: "place",
              key: "",
            },
            {
              title: "Ниже мин. цены",
              dataIndex: "isMinus",
              key: "",
            },
            {
              title: "Последняя проверка",
              dataIndex: "lastCheckedDate",
              key: "",
            },
          ]}
        />
      </div>
    </div>
  );
};

export default ProductInCity;
