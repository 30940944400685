import axios from "axios";
import config from "../../../config.json";

export const fetchEveryWeekStats = (onSuccess, onError, onFinish) => {
  axios
    .get(`${config.service}/api/payment/statistics/by-month`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
      },
    })
    .then((data) => {
      onSuccess(data.data);
    })
    .catch((err) => {
      onError(err);
    })
    .finally(() => {
      onFinish();
    });
};
