import React from 'react';
import { Card, Image, Typography } from 'antd';
import { CheckOutlined, LoadingOutlined} from '@ant-design/icons';

import { MessageBox } from "react-chat-elements";


const Message = ({ message, isEcho, time, status, type, contentUri }) => {
    const { Paragraph } = Typography;

    const formattedTime = new Date(time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });

    return (
        <div style={{
            width: 'fit-content',
            maxWidth: '450px',
            minHeight: '30px',
            borderRadius: '0.5rem',
            boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.13), 0 1px 6px -1px rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.12)",
            background: (isEcho ? "#FFFFFF" : "#8f38b4"),
            position: 'relative',
            margin: '0 5px 5px 0',
        }}>
            <div style={{ 
                display: (type == 'image' || type == 'video' ? 'grid' : 'flex'), 
                padding: (type == 'text' ? '10px' : '10px 10px 20px 10px'), 
                justifyContent: 'flex-start', 
                alignItems: 'flex-end', 
                height: '100%',
                whiteSpace: 'pre-line' 
            }}>
                {message && (<Paragraph
                    style={{ margin: (type == 'image' || type == 'video' ? '0 0 5px 0' : 0), color: (isEcho ? "#000000" : "#FFFFFF"), fontSize: "1rem", fontWeight: '450', lineHeight: '1' }}
                >{message + '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0'}</Paragraph>)}
                {type == 'audio' && (<MessageBox
                    type={"audio"}
                    notch={false}
                    data={{
                        audioURL: contentUri,
                    }}
                />)}
                {type == 'image' && (<Image
                    width={430}
                    src={contentUri}
                />)}
                {type == 'video' && (<Paragraph style={{ margin: '0 3px 0 0', color: (isEcho ? "#000000" : "#FFFFFF"), fontSize: "1rem", fontWeight: '500', lineHeight: '1' }} italic>Видео временно не поддерживаются</Paragraph>)}
                {type == 'document' && (<Paragraph style={{ margin: '0 3px 0 0', color: (isEcho ? "#000000" : "#FFFFFF"), fontSize: "1rem", fontWeight: '500', lineHeight: '1' }} italic>Данный формат временно не поддерижвается, Имран его должен допилить</Paragraph>)}

                <div style={{ display: 'flex', alignItems: 'center', position: 'absolute', right: '5px', bottom: '5px' }}>
                    <Paragraph
                        style={{ margin: '0 3px 0 0', color: (isEcho ? "#000000" : "#FFFFFF"), fontSize: "0.8rem", fontWeight: '500', lineHeight: '1' }}
                    >{formattedTime}</Paragraph>
                    {isEcho && (status == 'new' ? <LoadingOutlined /> : <CheckOutlined style={{ color: (status == 'read' ? '#1677ff' : 'gray') }} />)}
                </div>
            </div>
        </div>
    )
}

export default Message;