import { Card, Input, Select, Table } from "antd";
import { useEffect, useState } from "react";
import { getSegmentedClients } from "../utils/fetch";
import { Link } from "react-router-dom";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { currencyFormat } from "../../../scripts/format";

const ClientSegmentation = () => {
  const [clients, setClients] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSegment, setSelectedSegment] = useState("ALL");
  const [filter, setFilter] = useState("ALL");
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    setIsLoading(true);

    getSegmentedClients()
      .then(setClients)
      .finally(() => setIsLoading(false));
  }, []);

  const getDataSource = () => {
    const result = [];

    for (const client of clients) {
      if (selectedSegment !== client.segment && selectedSegment !== "ALL") {
        continue;
      }

      if (searchQuery) {
        const clientFullName = `${client.client.name} ${client.client.surname} ${client.client.name}`;
        const storeNames = client.stores.map((v) => v.name).join(", ");

        const str = `${clientFullName} ${storeNames}`.toLocaleLowerCase();

        if (!str.includes(searchQuery.toLocaleLowerCase())) {
          continue;
        }
      }

      const dealCycleDuration = client.dealCycleDuration;

      result.push({
        data: client,
        segment: client.segment,
        client: (
          <Link
            style={{ whiteSpace: "nowrap" }}
            target="_blank"
            to={`/client/${client.client._id}`}
          >
            {client.client.name} {client.client.surname}
          </Link>
        ),
        paymentsCount: `${client.nonZeroPaymentsCount} (${client.paymentsCount})`,
        dealCycleDuration: dealCycleDuration ? (
          `${dealCycleDuration.toFixed(2)} дн.`
        ) : (
          <div style={{ whiteSpace: "nowrap" }}>Не известно</div>
        ),
        averagePayment: currencyFormat(client.averagePayment.toFixed(2)),
        paymentRegularlyPercentage: `${client.paymentRegularlyPercentage.toFixed(
          2
        )}%`,
        stores: (
          <div>
            {client.stores.map((store) => {
              const isActive = new Date(store.expireDate) > new Date();

              return (
                <div>
                  <Link
                    style={{ whiteSpace: "nowrap" }}
                    to={`/store/${store._id}`}
                    target="_blank"
                    className="d-flex align-items-center"
                  >
                    <div>
                      {isActive ? (
                        <CheckCircleOutlined className="text-success" />
                      ) : (
                        <CloseCircleOutlined className="text-danger" />
                      )}
                    </div>
                    <div className="ms-2">{store.name}</div>
                  </Link>
                </div>
              );
            })}
          </div>
        ),
      });
    }

    return result;
  };

  return (
    <Card className="mt-3">
      <h1>Cегментация клиентов</h1>
      <div className="d-flex align-items-center">
        <Select
          disabled={isLoading}
          value={selectedSegment}
          onChange={(v) => setSelectedSegment(v)}
          style={{ width: 150 }}
          options={[
            {
              value: "ALL",
              label: "Все",
            },
            {
              value: "A",
            },
            {
              value: "B",
            },
            {
              value: "C",
            },
            {
              value: "D",
            },
            {
              value: "DEFAULT",
              label: "Без сегмента",
            },
          ]}
        />
        <Input
          className="ms-3"
          value={searchQuery}
          onChange={(v) => setSearchQuery(v.target.value)}
        />
      </div>
      <div style={{ overflow: "auto" }}>
        <Table
          loading={isLoading}
          className="mt-3"
          bordered
          columns={[
            {
              title: "Cегмент",
              dataIndex: "segment",
              key: "segment",
            },
            {
              title: "Клиент",
              dataIndex: "client",
              key: "client",
            },
            {
              title: <div style={{ width: 75 }}>Кол-во оплат</div>,
              dataIndex: "paymentsCount",
              key: "paymentsCount",
              sorter: (a, b) => {
                return (
                  a.data.nonZeroPaymentsCount - b.data.nonZeroPaymentsCount
                );
              },
            },
            {
              title: "Магазины",
              dataIndex: "stores",
              key: "stores",
            },
            {
              title: "Цикл сделки",
              dataIndex: "dealCycleDuration",
              key: "dealCycleDuration",
              sorter: (a, b) => {
                return a.data.dealCycleDuration - b.data.dealCycleDuration;
              },
            },
            {
              title: "Средний чек",
              dataIndex: "averagePayment",
              key: "averagePayment",
              sorter: (a, b) => {
                return a.data.averagePayment - b.data.averagePayment;
              },
            },
            {
              title: "Регулярность платежей",
              dataIndex: "paymentRegularlyPercentage",
              key: "paymentRegularlyPercentage",
              sorter: (a, b) => {
                return (
                  a.data.paymentRegularlyPercentage -
                  b.data.paymentRegularlyPercentage
                );
              },
            },
          ]}
          dataSource={getDataSource()}
          size="small"
        />
      </div>
    </Card>
  );
};

export default ClientSegmentation;
