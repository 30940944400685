import { Button, message, Modal } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import axios from "axios";
import config from "../../../config.json";

const AddNewStore = ({ user }) => {
  const [isLoading, setIsLoading] = useState();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({
    limit: 0,
    count: 0,
  });

  useEffect(() => {
    axios
      .get(`${config.service}/api/client/${user._id}/store-limit`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
        },
      })
      .then((d) => {
        setData({
          limit: d.data?.limit || 0,
          count: d.data?.count || 0,
        });
      });
  }, [open, isLoading]);

  const handleSubmit = () => {
    setIsLoading(true);

    axios
      .post(
        `${config.service}/api/client/${user._id}/store-limit`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              "admin_access_token"
            )}`,
          },
        }
      )
      .then(() => {
        message.success("Лимит успешно установлен");
      })
      .catch(() => {
        message.error("Не удалось установить лимит");
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <Button icon={<PlusOutlined />} onClick={() => setOpen(true)}>
        Новый магазин
      </Button>
      <Modal
        open={open}
        onCancel={() => setOpen(false)}
        destroyOnClose
        title="Добавить доступ на новый магазин"
        okButtonProps={{
          style: {
            display: "none",
          },
        }}
        cancelText="Отменить"
      >
        <div className="py-4 d-flex flex-column">
          <div className="mb-3">
            <div>
              Текущий лимит: <b>{data.limit}</b>
            </div>
            <div>
              Кол-во добавленных магазинов: <b>{data.count}</b>
            </div>
          </div>
          <Button type="primary" onClick={handleSubmit} loading={isLoading}>
            Дать доступ
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default AddNewStore;
