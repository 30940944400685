import { Card, Table } from "antd";
import { useEffect, useState } from "react";
import { getIdealClientsCategories } from "../utils/fetch";

const IdealClientsCategories = () => {
  const [idealClientsCategories, setIdealClientsCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getIdealClientsCategories()
      .then(setIdealClientsCategories)
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <Card className="mt-3">
      <h1>Список категории идеальных клиентов</h1>
      <Table
        columns={[
          {
            title: "Категория",
            dataIndex: "categoryName",
            key: "categoryName",
          },
          {
            title: "Кол-вол",
            dataIndex: "count",
            key: "count",
          },
        ]}
        dataSource={idealClientsCategories}
        loading={isLoading}
        size="small"
        pagination={false}
        bordered
      />
    </Card>
  );
};

export default IdealClientsCategories;
