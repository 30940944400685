import { Table } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import config from "../../../config.json";
import { currencyFormat } from "../../../scripts/format";
import UserReferralStatsList from "./UserReferralStatsList";

const UserReferralStats = () => {
  const [referrals, setReferrals] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetch();
  }, []);

  const fetch = () => {
    setIsLoading(true);

    const url = `${config.service}/api/client/referral`;

    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
        },
      })
      .then((data) => {
        setReferrals(data.data?.data || []);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="mt-3">
      <div
        style={{
          border: "1px solid lightgray",
          padding: 8,
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          marginTop: 15,
        }}
      >
        <h1 style={{ fontSize: 15 }}>Статистика по рефераллам</h1>
      </div>
      <Table
        pagination={false}
        loading={isLoading}
        bordered
        size="small"
        dataSource={getDataSource(referrals)}
        columns={[
          {
            dataIndex: "fullName",
            key: "fullName",
            title: "ФИО",
          },
          {
            dataIndex: "phoneNumber",
            key: "phoneNumber",
            title: "Номер тел.",
          },
          {
            dataIndex: "totalUsersCount",
            key: "totalUsersCount",
            title: "Пользователи",
          },
          {
            dataIndex: "totalStoresCount",
            key: "totalStoresCount",
            title: "Магазины",
          },
          {
            dataIndex: "totalPrice",
            key: "totalPrice",
            title: "Сумма",
          },
        ]}
        expandable={{
          expandedRowRender: (record) => {
            return <UserReferralStatsList record={record.data} />;
          },
        }}
      />
    </div>
  );
};

const getDataSource = (data) => {
  const result = [];

  if (Array.isArray(data)) {
    for (let d of data) {
      result.push({
        key: d._id,
        data: d,
        fullName: <Link to={`/client/${d._id}`}>{d.fullName}</Link>,
        phoneNumber: d.phoneNumber,
        totalUsersCount: d?.totalUsersCount || 0,
        totalStoresCount: d?.totalStoresCount || 0,
        totalPrice: currencyFormat(d?.totalPrice || 0),
      });
    }
  }

  return result;
};

export default UserReferralStats;
