import { Card, DatePicker, Descriptions, Input } from "antd";
import { Link } from "react-router-dom";
import DynamicFirstPlacePercentage from "./DynamicFirstPlacePercentage";
import { useState } from "react";
import AverageFirstPlacePercentage from "./AverageFirstPlacePercentage";
import StopGatheringForStoreButton from "./StopGatheringForStoreButton";
import ProductPositionHistory from "./ProductPositionHistory";
import ProductPositionAnalytics from "./ProductPositionAnalytics";
import DeleteGatheringForStoreButton from "./DeleteGatheringForStoreButton";

const { RangePicker } = DatePicker;

const StoreProductPositionAnalyticsInfo = ({ store, fetchStores }) => {
  const [startDate, setStartDate] = useState(0);
  const [endDate, setEndDate] = useState(
    new Date().getTime() + 24 * 60 * 60 * 1000
  );

  const [masterSku, setMasterSku] = useState("");

  return (
    <div>
      <Descriptions labelStyle={{color: '#202020'}}column={1}>
        <Descriptions.Item label="Магазин">
          <Link to={`/store/${store.storeId}`}>{store.storeName}</Link>
        </Descriptions.Item>
        <Descriptions.Item label="Дата начала сборки">
          <b>
            {new Date(store.createdAt).toLocaleString("ru-RU", {
              timeZone: "Asia/Almaty",
            })}
          </b>
        </Descriptions.Item>
        <Descriptions.Item label="Дата окончания сбора данных">
          <b>
            {new Date(store.analyticsGatheringExpirationDate).toLocaleString(
              "ru-RU",
              { timeZone: "Asia/Almaty" }
            )}
          </b>
        </Descriptions.Item>
      </Descriptions>
      <StopGatheringForStoreButton store={store} fetch={fetchStores} />
      <DeleteGatheringForStoreButton store={store} fetch={fetchStores} />
      <Card>
        <h2>Фильтрация</h2>
        <div>
          <div>Код товара (masterProductSku):</div>
          <Input
            style={{ maxWidth: 500 }}
            onBlur={(v) => setMasterSku(v.target.value)}
          />
        </div>
        <div className="mt-3">Период времени</div>
        <div>
          <RangePicker
            allowClear={false}
            showTime
            onChange={(values) => {
              setStartDate(values[0].toDate().getTime());
              setEndDate(values[1].toDate().getTime());
            }}
          />
        </div>
      </Card>
      <div>
        <AverageFirstPlacePercentage
          store={store}
          startDate={startDate}
          endDate={endDate}
          masterSku={masterSku}
        />

        <DynamicFirstPlacePercentage
          store={store}
          startDate={startDate}
          endDate={endDate}
          masterSku={masterSku}
        />

        <ProductPositionAnalytics
          store={store}
          startDate={startDate}
          endDate={endDate}
        />

        {masterSku && (
          <ProductPositionHistory
            store={store}
            startDate={startDate}
            endDate={endDate}
            masterSku={masterSku}
          />
        )}
      </div>
    </div>
  );
};

export default StoreProductPositionAnalyticsInfo;
