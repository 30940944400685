import { React, useEffect, useState } from 'react';
import { Layout, theme, Input } from 'antd';
import { UserHeader, UserMenu } from './components';
import SubmitForm from './components/SubmitForm';
import { fetchInitialMessages, getTags } from './utils/fetch';
import ChatMessages from './components/ChatMessages';
import { io } from 'socket.io-client';
import { PageLoading } from '@ant-design/pro-components';
import config from '../../config.json'
import useIsMobile from '../../hooks/useIsMobile';

const { Header, Content, Footer } = Layout;

const Chat = () => {
  const isMobile = useIsMobile()
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const [newMessage, setNewMessage] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [mySocket, setMySocket] = useState(null);
  const [currentUser, setCurrentUser] = useState({})
  const [tags, setTags] = useState([])
  const sendMessage = (newMessage) => {
    setNewMessage(newMessage);
  };

  useEffect(() => {}, [newMessage]);

  useEffect(() => {
    const socket = io(config.service);
    socket.on('connect', () => {
      setIsLoading(false);
    });
    setMySocket(socket);

    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    async function fetchTags(){
      const fetchedTags  = await getTags()
      setTags(fetchedTags.data)
    }
    fetchTags()

  }, []);

  const changeCurrrentUser = (newUser) => {
    console.log('changeCurrrentUser')
    console.log(newUser)

    setCurrentUser(newUser);
  };

  if(isLoading) return PageLoading 

  return (
    <Layout >
        <UserMenu socket={mySocket} onMenuChange={changeCurrrentUser} />
      <Layout style={{ position: 'relative' }}>
        <Header style={{ display: 'flex', margin: isMobile ? '0 8px 0' : '0 16px 0', background: colorBgContainer, borderRadius: borderRadiusLG, justifyContent: 'space-between', padding: '0 24px' }}>
          <UserHeader user={currentUser} tags = {tags} setTags={setTags}  setCurrentUser = {changeCurrrentUser}/>
        </Header>
        <Content style={{ margin: isMobile ? '8px' : '16px', height: 'calc (100vh - 20px)' }}>
          <div
            style={{
              padding: '0 24px',
              height: "100%",
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}
          >
            <ChatMessages socket={mySocket} newMessageSend={newMessage} chatId={currentUser.chatId} />
          </div>
        </Content>
        <Footer style={{
          margin: '30px',
          padding: '20px',
          textAlign: 'center',
          background: 'transparent',
          borderRadius: '8px',
          backdropFilter: 'blur(10px)',
          boxShadow: '0px 0px 15px 0px rgba(140, 188, 255, 0.7)',
          position: 'absolute',
          bottom: '0',
          width: 'calc(100% - 60px)'
        }}>
          <SubmitForm onSubmitForm={(e) => {
            sendMessage({
              text: e.message,
              isEcho: true,
              dateTime: new Date().toString(),
              status: 'new'
            })
          }} />
        </Footer>
      </Layout>
    </Layout>
  );
};

export default Chat;