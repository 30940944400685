import { Calendar, Card } from "antd";
import { useEffect, useState } from "react";
import { getMostCompetitiveCategories } from "../utils/fetch";
import { currencyFormat } from "../../../scripts/format";

const MostCompetitiveCategories = () => {
  const [mostCompetitiveCategories, setMostCompetitiveCategories] = useState(
    []
  );

  useEffect(() => {
    getMostCompetitiveCategories().then(setMostCompetitiveCategories);
  }, []);

  const getListData = (value) => {
    const selectedDate = value.toDate();

    let data = null;

    for (const mostCompetitiveCategory of mostCompetitiveCategories) {
      if (
        !mostCompetitiveCategory.mostCompetitiveCategoriesForMonthAndYear.length
      ) {
        continue;
      }

      if (
        selectedDate.getFullYear() === mostCompetitiveCategory.year &&
        selectedDate.getMonth() === mostCompetitiveCategory.monthIndex
      ) {
        data = mostCompetitiveCategory.mostCompetitiveCategoriesForMonthAndYear;
      }
    }

    if (!data) {
      return (
        <div className="h-100 w-100 d-flex align-items-center justify-content-center">
          <i>Нет данных</i>
        </div>
      );
    }

    return (
      <ul>
        {data.map((v, index) => {
          if (index >= 5) {
            return <></>;
          }

          return <li>{v.categoryName}</li>;
        })}
      </ul>
    );
  };

  return (
    <Card className="mt-3">
      <h1>Cамые конкурентные категории</h1>
      <Calendar monthCellRender={getListData} mode="year" />
    </Card>
  );
};

export default MostCompetitiveCategories;
