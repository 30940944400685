import { Card, Segmented } from "antd";
import { useState } from "react";
import { EveryWeekStats } from "./components/EveryWeekStats";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  BarElement,
} from "chart.js";
import BudgetInfo from "./components/BudgetInfo";
import Payments from "./components/Payments";
import useIsMobile from "../../hooks/useIsMobile";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  BarElement
);

const Budget = () => {
  const [mod, setMod] = useState("weekly-statistics");
  const isMobile = useIsMobile()
  const role = localStorage.getItem("role") || "";

  if (role === "") {
    return <></>;
  }

  console.log(role);

  const getBody = () => {
     if (role != "SUPER_ADMIN")  {
      return <EveryWeekStats />;
    }

    if (mod === "weekly-statistics") {
      return <EveryWeekStats />;
    }

    return <Payments />;
  };

  return (
    <Card className={isMobile ? 'mx-2' :'mx-3'}>
      <div>
         {(role === "SUPER_ADMIN") && <BudgetInfo />}
        {(role === "SUPER_ADMIN" ) && (
          <Segmented
            block
            size="large"
            value={mod}
            onChange={setMod}
            options={[
              {
                label: "По недельняя статистика",
                value: "weekly-statistics",
              },
              {
                label: "Все платежи",
                value: "payments",
              },
            ]}
          />
        )}
        {getBody()}
      </div>
    </Card>
  );
};

export default Budget;
