import { Card } from "antd";
import kaspiIcon from "../../../assets/icons/kaspi-logo.ico";
import jmartIcon from "../../../assets/icons/jmart-icon.png";
import halykIcon from "../../../assets/icons/halyk-icon.png";
export const ServiceHealthCard = ({ service }) => {
  let bgColor = "bg-success";
  if (service.status === 3) {
    bgColor = "bg-danger";
  } else if (service.status === 2) {
    bgColor = "bg-warning";
  }
  let icon = kaspiIcon;
  if (service.marketplaceName === "Halyk") {
    icon = halykIcon;
  } else if (service.marketplaceName === "Jmart") {
    icon = jmartIcon;
  }

  const messages = (service.messages || []).filter((v) => v);

  return (
    <Card
      className={`mb-3 me-3 ${bgColor}`}
      style={{ width: 350, position: "relative" }}
    >
      <h3
        className="m-0"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        {service.serviceName}
        <img src={icon} alt="icon" style={{ width: 30 }} />
      </h3>
      <div className="ms-3 mt-2">

        {
          service.serviceName !== "Рассылка WhatsApp" &&
            <ul className="m-0">
              {messages.map((message, index) => {
                return <li key={index}>{message}</li>;
              })}
            </ul>
        }
        
        {
          service.serviceName === "Рассылка WhatsApp" &&
          <>
            <ul className="m-0">
              {messages.slice(0, messages.length - 1).map((message, index) => {
                return <li key={index}>{message}</li>;
              })}
            </ul>
            {messages[messages.length - 1].split("</br>").map((v) => <p>{v}</p>)}
          </>
        }
        
      </div>
    </Card>
  );
};
