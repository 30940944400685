import {
  Button,
  Card,
  Collapse,
  Descriptions,
  message,
  Segmented,
  Select,
  Table,
} from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import config from "../../config.json";
import { ReloadOutlined } from "@ant-design/icons";
import { ProxySpeedInfo } from "./components/";
import {
  AddProxy,
  ProxyLogs,
  ChangeProxyActive,
  DeleteProxy,
} from "./components/";
import ProxyCalendar from "./components/ProxyCalendar";
import { CalendarOutlined } from "@ant-design/icons";
import ChangeExpireDate from "./components/ChangeExpireDate";
import useIsMobile from "../../hooks/useIsMobile";
// import { formatPhone } from "../Client/components/ShowClients";

export const formatPhone = (phone) => {
 
  let res = "+7 (7";

  res +=
    phone[3] +
    phone[4] +
    ") " +
    phone[5] +
    phone[6] +
    phone[7] +
    " " +
    phone[8] +
    phone[9] +
    phone[10] +
    phone[11];

  return res;
};

const { Panel } = Collapse;

const proxyInstanceCounts = [
  { value: 1 },
  { value: 2 },
  { value: 3 },
  { value: 4 },
];
const numbers = [];
const isMainNumber = document.getElementsByName("phoneNumber");
const proxyTypes = [
  {
    value: "SHOP",
    count: 20,
  },
  {
    value: "SHOP_STATIC",
    count: 40,
  },
  {
    value: "MERCHANTCABINET",
    count: 15,
  },
  {
    value: "ANALYTICS",
    count: 40,
  },
  {
    value: "JMART",
    count: 5,
  },
  {
    value: "MERCHANT_CABINET_LOAD_PRODUCTS",
    count: 15,
  },
  {
    value: "PRICE_CHANGER",
    count: 15,
  },
  {
    value: "XML",
    count: 5,
  },
  {
    value: "SHOP_PRIVILEGE",
    count: 2,
  }
];

const Proxy = () => {
  const isMobile = useIsMobile()
  const [isLoading, setIsLoading] = useState(true);
  const [proxies, setProxies] = useState([]);
  const [mod, setMod] = useState("speed");

  useEffect(() => {
    fetchProxies();
  }, []);

  const fetchProxies = () => {
    setIsLoading(true);

    axios
      .get(`${config.service}/api/proxy`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
        },
      })
      .then((data) => {
        console.log("data", data);
        setProxies(data.data?.data || []);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const updateProxy = (id, data) => {
    const loadingMsg = message.loading("Прокси обновляется", 0);
    axios
      .patch(`${config.service}/api/proxy/${id}`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
        },
      })
      .then(() => {
        message.success("Прокси успешно обновлен");

        fetchProxies();
      })
      .catch((e) => {
        console.log("cannot update proxy", e);
        message.error("Не удалось обновить прокси");
      })
      .finally(() => {
        loadingMsg();
      });
  };

  const isDangerDate = (date) => {
    const expireDate = new Date(date);
    if (expireDate.getFullYear() < 2020) {
      return false;
    }

    if (
      expireDate &&
      expireDate.getTime() - 2 * 24 * 60 * 60 * 1000 < new Date().getTime()
    ) {
      return true;
    }
  };
  function sortPhone(a, b) {
    const extractNumber = (str) => {
      const matches = str.match(/\d+/);
      return matches ? parseInt(matches[0]) : 0;
    };

    const aNumber = extractNumber(a.data.phoneName);
    const bNumber = extractNumber(b.data.phoneName);

    if (aNumber !== bNumber) {
      return aNumber - bNumber;
    }

    const extractNumericPart = (str) => {
      return str.replace(/\D/g, "");
    };

    const aNumericPart = extractNumericPart(a.data.name);
    const bNumericPart = extractNumericPart(b.data.name);

    const lengthComparison = a.data.name.length - b.data.name.length;

    if (lengthComparison !== 0) {
      return lengthComparison;
    }

    return parseInt(aNumericPart) - parseInt(bNumericPart);
  }

  const typeFilters = [];
  for (let proxy of proxies) {
    const index = typeFilters.findIndex((v) => v.value === proxy.type);

    if (index < 0) {
      typeFilters.push({
        text: proxy.type,
        value: proxy.type,
      });
    }
  }

  let totalExpectationCount = 0;
  for (const p of proxyTypes) {
    totalExpectationCount += p.count;
  }

  const role = localStorage.getItem("role") || "";

  return (
    <Card className={isMobile ? 'mx-2' : 'mx-3'}>
      <h1 className="border-bottom pb-3 mb-3">Прокси</h1>
      <div className="d-flex align-items-center justify-content-between">
        <Button
          loading={isLoading}
          type="primary"
          icon={<ReloadOutlined />}
          onClick={fetchProxies}
        >
          Обновить список
        </Button>
        <AddProxy
          onSaved={() => {
            fetchProxies();
          }}
        />
      </div>
      <div className="my-3">
        <Collapse>
          <Panel
            header={
              <div>
                <CalendarOutlined /> <span className="ms-1">Календарь</span>
              </div>
            }
          >
            <ProxyCalendar proxies={proxies} isLoading={isLoading} />
          </Panel>
        </Collapse>
      </div>
      <Descriptions labelStyle={{ color: '#202020' }} className="my-3" column={1}>
        {proxyTypes.map((proxyType, index) => {
          const activeProxiesType = proxies.filter(
            (v) => v.isActive && v.type === proxyType.value
          );
          const inactiveProxiesType = proxies.filter(
            (v) => !v.isActive && v.type === proxyType.value
          );
          const expireDate = proxies.filter((v) => {
            const date = new Date(v.expireDate);

            return (
              isDangerDate(date) && v.type === proxyType.value && v.isActive
            );
          });

          return (
            <Descriptions.Item key={index} label={proxyType.value}>
              <span className="text-success me-2">
                Работает: {activeProxiesType.length}
              </span>
              /
              <span className="text-warning mx-2">
                Скоро истекает: {expireDate.length}
              </span>
              /
              <span className="text-danger mx-2">
                Остановлено: {inactiveProxiesType.length}
              </span>
              /
              <span className="text-primary ms-2">
                Должно быть: {proxyType.count}
              </span>
            </Descriptions.Item>
          );
        })}
        <Descriptions.Item label="Общее количество">
          <span className="text-success me-2">
            Работает: {proxies.filter((v) => v.isActive).length}
          </span>
          /
          <span className="text-warning mx-2">
            Скоро истекает:{" "}
            {
              proxies.filter((v) => {
                if (!v.expireDate) {
                  return false;
                }

                console.log(v);

                const date = new Date(v.expireDate);

                return isDangerDate(date) && v.isActive;
              }).length
            }
          </span>
          /
          <span className="text-danger mx-2">
            Остановлено: {proxies.filter((v) => !v.isActive).length}
          </span>
          /
          <span className="text-primary ms-2">
            Должно быть: {totalExpectationCount}
          </span>{" "}
        </Descriptions.Item>
      </Descriptions>
      <div className="my-3">
        <div>
          <span
            className="py-1 px-3"
            style={{
              backgroundColor: "#fc9f97",
            }}
          ></span>
          <span className="ms-1">
            - дата истекает, надо срочно продлить или заменить
          </span>
        </div>
      </div>
      <div style={{ overflow: "auto" }} className="proxy">
        <Table
          pagination={false}
          loading={isLoading}
          bordered
          size="small"
          onRow={(data) => {
            if (isDangerDate(data.data.expireDate) && data.data.isActive) {
              return {
                style: {
                  backgroundColor: "#fc9f97",
                },
              };
            }
          }}
          columns={[
            {
              title: "Телефон",
              dataIndex: "phoneName",
              sorter: (a, b) => {
                return sortPhone(a, b);
              },
            },
            {
              title: "Прокси",
              dataIndex: "proxy",
            },
            {
              title: "Скорость",
              dataIndex: "speed",
              sorter: (v1, v2) => {
                return v1.data.speed - v2.data.speed;
              },
            },
            {
              title: "Кол-во инстанцев",
              dataIndex: "instanceCount",
              sorter: (c1, c2) => {
                return c1 >= c2;
              },
            },
            {
              title: "Активный",
              dataIndex: "isActive",
            },
            {
              title: "Тип",
              dataIndex: "type",
              onFilter: (value, record) => record.data.type === value,
              filters: typeFilters,
            },
            {
              title: (
                <div>
                  <div>Оплачен до</div>
                  <div>(включительно)</div>
                </div>
              ),
              dataIndex: "expireDate",
              key: "expireDate",
              sorter: (v1, v2) => {
                const ex1 = v1.data.expireDate || "";
                const ex2 = v2.data.expireDate || "";
                return ex1.localeCompare(ex2);
              },
            },
            {
              title: <div>Номер телефона</div>,
              dataIndex: "phoneNumber",
            },
            {
              title: "Авторизация",
              dataIndex: "auth",
            },
            {
              title: "Комментарий",
              dataIndex: "comment",
              width: 100,
            },
            {
              title: "Главный номер",
              dataIndex: "mainNumber",
              width: 100,
            },
            role !== "DEVELOPER" && role !== ""
              ? {
                title: "",
                dataIndex: "delete",
                width: 20,
              }
              : {},
          ]}
          dataSource={proxies.map((proxy) => {
            return {
              key: proxy._id,
              data: proxy,

              phoneNumber: `${proxy.host}:${proxy.port}`,
              phoneNumber: (
                <input
                  defaultValue={proxy.phoneNumber}
                  onBlur={(event) => {
                    updateProxy(proxy._id, {
                      phoneNumber: event.target.value,
                    });
                  }}
                />
              ),

              proxy: (
                <>
                  <input
                    defaultValue={proxy.host}
                    onBlur={(event) => {
                      updateProxy(proxy._id, {
                        host: event.target.value,
                      });
                    }}
                  />
                  <input
                    defaultValue={proxy.port}
                    onBlur={(event) => {
                      updateProxy(proxy._id, {
                        port: event.target.value,
                      });
                    }}
                  />
                </>
              ),
              comment: (
                <input
                  defaultValue={proxy.comment}
                  onBlur={(event) => {
                    updateProxy(proxy._id, {
                      comment: event.target.value,
                    });
                  }}
                />
              ),

              mainNumber: (
                <input
                  defaultValue={proxy.mainNumber}
                  onBlur={(event) => {
                    updateProxy(proxy._id, {
                      mainNumber: event.target.value,
                    });
                  }}
                />
              ),

              isActive: <ChangeProxyActive proxy={proxy} />,
              speed: (
                <div>
                  <span className="text-success" style={{ minWidth: 40 }}>
                    {proxy.speed || 0}
                  </span>
                  <span className="mx-1">/</span>
                  <span className="text-danger">{proxy.error || 0}</span>
                </div>
              ),
              instanceCount: (
                <Select
                  style={{ width: 75 }}
                  onChange={(c) => {
                    updateProxy(proxy._id, {
                      instanceCount: c,
                    });
                  }}
                  defaultValue={`${proxy.instanceCount}`}
                  options={proxyInstanceCounts}
                />
              ),
              type: (
                <Select
                  style={{ width: 175 }}
                  onChange={(v) => {
                    updateProxy(proxy._id, {
                      type: v,
                    });
                  }}
                  defaultValue={`${proxy.type || ""}`}
                  options={proxyTypes}
                />
              ),
              auth: (
                <>
                  <input
                    defaultValue={proxy.login}
                    onBlur={(event) => {
                      updateProxy(proxy._id, {
                        login: event.target.value,
                      });
                    }}
                  />
                  <input
                    defaultValue={proxy.password}
                    onBlur={(event) => {
                      updateProxy(proxy._id, {
                        password: event.target.value,
                      });
                    }}
                  />
                </>
              ),
              phoneName: (
                <div style={{ width: 150 }}>
                  {proxy.name || proxy.phoneName || ""}
                </div>
              ),
              lastUpdatedIpDate: proxy?.lastUpdatedIpDate
                ? new Date(proxy.lastUpdatedIpDate).toLocaleString()
                : "",
              action: <ProxyAction proxy={proxy} />,
              delete: (
                <DeleteProxy
                  proxy={proxy}
                  onDelete={() => {
                    fetchProxies();
                  }}
                />
              ),
              expireDate: (
                <ChangeExpireDate proxy={proxy} updateProxy={updateProxy} />
              ),
            };
          })}
          expandable={{
            expandedRowRender: (record) => {
              return (
                <Card>
                  <Segmented
                    onChange={setMod}
                    value={mod}
                    options={[
                      {
                        label: "Скорость",
                        value: "speed",
                      },
                      {
                        label: "Логи",
                        value: "logs",
                      },
                    ]}
                    block
                    size="middle"
                  />
                  <div className="mt-3">
                    {mod === "speed" && <ProxySpeedInfo data={record.data} />}
                    {mod === "logs" && <ProxyLogs data={record.data} />}
                  </div>
                </Card>
              );
            },
          }}
        />
      </div>
    </Card>
  );
};

const ProxyAction = ({ proxy }) => {
  const [isLoading, setIsLoading] = useState(false);

  if (!proxy.changeIpUrl) {
    return <></>;
  }

  const changeIp = () => {
    setIsLoading(true);

    window.open(proxy.changeIpUrl);
  };

  return (
    <Button
      loading={isLoading}
      size="small"
      onClick={changeIp}
      icon={<ReloadOutlined />}
    >
      Изменить IP
    </Button>
  );
};

export default Proxy;
