import { Button, Card, Descriptions, Form, InputNumber, message } from "antd";
import config from "../../../config.json";
import axios from "axios";
import { useEffect, useState } from "react";
import { currencyFormat } from "../../../scripts/format";
import DailyAnalyticsProductItem from "./DailyAnalyticsProductItem";

const DailyAnalyticsActivation = ({ storeId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isActivating, setIsActivating] = useState(false);
  const [dailyAnalyticsProducts, setDailyAnalyticsProducts] = useState([]);
  const [paidDailyProduct, setPaidDailyProduct] = useState(null);
  const [price, setPrice] = useState(0);

  const handleActivation = () => {
    if (!paidDailyProduct) {
      return;
    }

    setIsActivating(true);

    axios
      .post(
        `${config.service}/api/store-daily-product-analytics/${paidDailyProduct._id}/activate`,
        {
          price: parseInt(price),
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              "admin_access_token"
            )}`,
          },
        }
      )
      .then(() => {
        message.success("Платный товар успешно активирован");

        fetchDailyAnalytics();
      })
      .catch(() => {
        message.error("Не удалось активировать платный товар");
      })
      .finally(() => setIsActivating(false));
  };

  useEffect(() => {
    const product = dailyAnalyticsProducts.find(
      (v) => v.productType === "PAID"
    );

    setPrice(product?.storeDailyProductAnalyticsPrice || 0);
  }, [dailyAnalyticsProducts]);

  useEffect(() => {
    if (!Array.isArray(dailyAnalyticsProducts)) {
      return;
    }

    const data = dailyAnalyticsProducts.find(
      (v) => v.productType === "PAID" && !v.hasAccess
    );

    setPaidDailyProduct(data);
  }, [dailyAnalyticsProducts]);

  const fetchDailyAnalytics = () => {
    setIsLoading(true);

    axios
      .get(`${config.service}/api/store-daily-product-analytics/${storeId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
        },
      })
      .then((data) => {
        setDailyAnalyticsProducts(data.data);
      })
      .finally(() => setIsLoading(false));
  };
  useEffect(() => {
    fetchDailyAnalytics();
  }, [storeId]);

  return (
    <Card>
      <div className="mb-3">
        {dailyAnalyticsProducts.map((product, index) => {
          return <DailyAnalyticsProductItem product={product} key={index} />;
        })}
      </div>
      {paidDailyProduct && (
        <Form onFinish={handleActivation}>
          <Form.Item>
            <InputNumber
              disabled={isActivating}
              prefix={
                <div className="border-right pe-1" style={{ fontSize: 16 }}>
                  ₸
                </div>
              }
              placeholder="Сумма"
              value={price + ""}
              style={{ width: "100%" }}
              onChange={(event) => {
                if (event) {
                  event = event + "";
                  setPrice(event);
                } else {
                  setPrice("0");
                }
              }}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
              }
              parser={(value) => {
                value = value.toString();

                if (!value) {
                  return "";
                }

                const newValue = value.replace(/\$\s?|( *)/g, "");

                return newValue;
              }}
            />
          </Form.Item>

          <Button
            type="primary"
            ghost
            className="w-100"
            loading={isActivating}
            htmlType="submit"
          >
            Активировать платный товар
          </Button>
        </Form>
      )}
    </Card>
  );
};

export default DailyAnalyticsActivation;
