import { Button, Empty, Spin, Table } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import config from "../../../config.json";

const ChangePriceRequestResult = ({ product }) => {
  const productId = product.data._id;

  const [histories, setHistories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetch = () => {
    setIsLoading(true);

    axios
      .get(
        `${config.service}/api/product/${productId}/change-price-request-result`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              "admin_access_token"
            )}`,
          },
        }
      )
      .then((data) => setHistories(data.data))
      .finally(() => setIsLoading(false));
  };
  useEffect(fetch, [productId]);

  if (isLoading) {
    return (
      <div className="d-flex align-items-center justify-content-center my-5">
        <Spin />
      </div>
    );
  }

  if (histories.length === 0) {
    return (
      <div>
        <Button onClick={fetch}>Обновить</Button>

        <div className="d-flex align-items-center justify-content-center my-5">
          <Empty />
        </div>
      </div>
    );
  }

  return (
    <div>
      <Button onClick={fetch}>Обновить</Button>

      <div>
        <i>* Данные за последние 24 часа</i>
      </div>
      <Table
        className="mt-3"
        bordered
        size="small"
        columns={[
          {
            title: "Дата",
            dataIndex: "date",
            key: "date",
          },
          {
            title: "Время",
            dataIndex: "time",
            key: "time",
          },
          {
            title: "Статус",
            dataIndex: "status",
            key: "status",
          },
          {
            title: "Сообщение",
            dataIndex: "message",
            key: "message",
          },
        ]}
        dataSource={histories.map((history, index) => {
          return {
            key: history._id,
            date: new Date(history.createdAt).toLocaleDateString(),
            time: new Date(history.createdAt).toLocaleTimeString(),
            status: history?.data?.status || 0,
            message: history?.data?.message || "",
            requestData: history?.requestData || {},
          };
        })}
        expandable={{
          expandedRowRender: (record) => {
            return (
              <div>
                <pre>{JSON.stringify(record.requestData, null, 2)}</pre>
              </div>
            );
          },
        }}
      />
    </div>
  );
};

export default ChangePriceRequestResult;
