import { Card } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import config from "../../../config.json";
import DailyAnalyticsProductItem from "./DailyAnalyticsProductItem";

const DailyAnalyticsHistory = ({ storeId }) => {
  const [dailyAnalyticsProducts, setDailyAnalyticsProducts] = useState([]);
  const [isLoading, setIsLoading] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const fetchDailyAnalytics = () => {
    setIsLoading(true);

    axios
      .get(
        `${config.service}/api/store-daily-product-analytics/${storeId}/history?limit=1000&page=1`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              "admin_access_token"
            )}`,
          },
        }
      )
      .then((data) => {
        setDailyAnalyticsProducts(data.data.data || []);
        setTotalCount(data.data.totalCount || 0);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(fetchDailyAnalytics, [storeId]);

  return (
    <Card>
      {dailyAnalyticsProducts.map((product, index) => {
        return <DailyAnalyticsProductItem product={product} key={index} />;
      })}
    </Card>
  );
};

export default DailyAnalyticsHistory;
