import { useEffect, useState } from "react";



const useIsMobile = (size=768) => {
    const [isMobile,setIsMobile] = useState(false)

    useEffect(() => {
        const checker = () => {
            setIsMobile(window.innerWidth <= size)
        }
        checker();

        window.addEventListener('resize',checker);

        return () => {
            window.removeEventListener('resize',checker)
        }
    },[])

    return isMobile
}


export default useIsMobile;