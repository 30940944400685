import { Table } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import config from "../../../config.json";
import { currencyFormat } from "../../../scripts/format";
import { clearToken } from "../../../scripts/token";

const TodayPaid = ({ refreshCounter }) => {
  const [payments, setPayments] = useState([]);
  const [total, setTotal] = useState(0);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);

    axios
      .get(`${config.service}/api/payment/today`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
        },
      })
      .then((data) => {
        setPayments(data.data.payments);
        setTotal(data.data.total);
        setCount(data.data.count);
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          clearToken();
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [refreshCounter]);

  const columns = [
    {
      title: "#",
      dataIndex: "number",
      key: "number",
    },
    {
      title: "Магазин",
      dataIndex: "storeName",
      key: "storeName",
    },
    {
      title: "Активировал",
      dataIndex: "activatedBy",
      key: "activatedBy",
    },
    {
      title: "Сумма",
      dataIndex: "sum",
      key: "sum",
    },
    {
      title: "Тип покупки",  ///////////////////////////////////////////////////////
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Активирован до",
      dataIndex: "newExpireDate",
      key: "newExpireDate",
    },
  ];

  const data = [];
  let i = 1;

  const getActivatedUserName = (payment) => {
    try {
      if (payment.activatedUser.name || payment.activatedUser.surname) {
        return `${payment.activatedUser.name} ${payment.activatedUser.surname}`;
      } else {
        return payment.activatedUser.login;
      }
    } catch (e) {}

    return "";
  };

  for (let payment of payments) {
    data.push({
      number: i,
      sum: currencyFormat(payment.price),
      newExpireDate: new Date(payment.newExpireDate).toLocaleDateString(),
      storeName: (
        <a href={`/store/${payment?.store?._id}`}>{payment?.store?.name}</a>
      ),
      activatedBy: getActivatedUserName(payment),
      type: `${payment.type}`,  ////////////////////////////////////////////////////////////////////
    });

    i++;
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "start",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          border: "1px solid lightgray",
          padding: 8,
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
        }}
      >
        <h1 style={{ fontSize: 15 }}>Оплаченные на сегодня</h1>
        <h1 style={{ fontSize: 15 }}>{currencyFormat(total)} KZT</h1>
      </div>

      <Table
        size="small"
        bordered
        columns={columns}
        dataSource={data}
        pagination={false}
        loading={isLoading}
      />
    </div>
  );
};

export default TodayPaid;
