import { DeleteOutlined, PlusOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Card } from "antd";
import { useEffect, useState } from "react";
import AddNotification from "./components/AddNotification";
import useIsMobile from "../../hooks/useIsMobile";

const showUsersOptions = [
  {
    value: "ALL",
    label: "Все",
  },
  {
    value: "ANALYTICS",
    label: "Есть аналитика",
  },
  {
    value: "EXISTS_STORE",
    label: "Есть магазин",
  },
  {
    value: "NOT_EXISTS_STORE",
    label: "Нет магазина",
  },
];

const Notification = () => {
  const [notifications, setNotifications] = useState([]);
  const [isAddNotification, setIsAddNotification] = useState(false);
  const isMobile = useIsMobile()
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = () => {
    setNotifications([
      {
        title: "Title",
        content: "Content",
        isActive: true,
        createdAt: new Date(),
        showUsers: "ALL",
      },
    ]);
  };

  return (
    <Card className={isMobile ? 'mx-2' : "mx-4"}>
      <AddNotification
        show={isAddNotification}
        onClose={() => setIsAddNotification(false)}
        onOk={fetchData}
      />
      <div className="d-flex align-items-center justify-content-between border-bottom pb-3 mb-3">
        <h1 className="m-0">Уведомления</h1>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => setIsAddNotification(true)}
        >
          Добавить
        </Button>
      </div>
      <div>
        {notifications.map((notification, index) => {
          return (
            <NotificationItem
              notification={notification}
              index={index}
              onChange={fetchData}
            />
          );
        })}
      </div>
    </Card>
  );
};

const NotificationItem = ({ notification, index, onChange }) => {
  const [isEditNotification, setIsEditNotification] = useState(false);

  const getShowUsersString = () => {
    const foundOption = showUsersOptions.find(
      (v) => notification.showUsers === v.value
    );

    if (foundOption) {
      return foundOption.label;
    }

    return "Никто";
  };

  return (
    <Card>
      <AddNotification
        show={isEditNotification}
        onClose={() => setIsEditNotification(false)}
        onOk={onChange}
        notification={notification}
      />
      <div className="d-flex">
        <div className="fw-bold me-3">Загаловок:</div>
        <div dangerouslySetInnerHTML={{ __html: notification.title || "" }} />
      </div>
      <div className="d-flex">
        <div className="fw-bold me-3">Контент:</div>
        <div dangerouslySetInnerHTML={{ __html: notification.content || "" }} />
      </div>
      <div className="d-flex">
        <div className="fw-bold me-3">Видит:</div>
        <div>{getShowUsersString()}</div>
      </div>
      <div>
        <Button
          className="border-danger text-danger mt-3"
          icon={<DeleteOutlined />}
        >
          Удалить
        </Button>
        <Button
          className="ms-3"
          icon={<EditOutlined />}
          onClick={() => setIsEditNotification(true)}
        >
          Редактировать
        </Button>
      </div>
    </Card>
  );
};

export default Notification;
