import { Card, Table } from "antd";
import { useEffect, useState } from "react";
import { getIdealClients } from "../utils/fetch";
import { Link } from "react-router-dom";

const IdealClients = () => {
  const [idealClients, setIdealClients] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getIdealClients()
      .then(setIdealClients)
      .finally(() => setIsLoading(false));
  }, []);

  const getTariffNames = (idealClient) => {
    const tariffNames = (
      <div>
        {idealClient.tariffNames.map((v) => {
          const activeTariffNames = idealClient.activeTariffNames || [];

          const exists = activeTariffNames.findIndex(
            (activeTariffName) => activeTariffName === v
          );

          return (
            <div
              style={{
                textDecoration: exists >= 0 ? "underline" : "",
              }}
            >
              {v}
            </div>
          );
        })}
      </div>
    );

    return tariffNames;
  };

  return (
    <Card className="mt-3">
      <h1>Cписок идеальных клиентов</h1>
      <Table
        bordered
        loading={isLoading}
        size="small"
        columns={[
          {
            title: "Клиент",
            dataIndex: "client",
            key: "client",
          },
          {
            title: "Кол-во платежей",
            dataIndex: "paymentsCount",
            key: "paymentsCount",
          },
          {
            title: "Тарифы",
            dataIndex: "tariffNames",
            key: "tariffNames",
          },
          {
            title: "Активные магазины",
            dataIndex: "activeStores",
            key: "activeStores",
          },
          {
            title: "Неактивные магазины",
            dataIndex: "inactiveStores",
            key: "inactiveStores",
          },
        ]}
        dataSource={idealClients.map((idealClient) => {
          return {
            client: (
              <Link target="_blank" to={`/client/${idealClient._id}`}>
                {idealClient.name} {idealClient.surname}
              </Link>
            ),
            paymentsCount: idealClient.paymentsCount,
            tariffNames: getTariffNames(idealClient),
            activeStores: (
              <ul>
                {idealClient.activeStores.map((v) => {
                  return (
                    <li>
                      <Link target="_blank" to={`/store/${v._id}`}>
                        {v.name} (кат: {v.categoryName})
                      </Link>
                    </li>
                  );
                })}
              </ul>
            ),
            inactiveStores: (
              <ul>
                {idealClient.inactiveStores.map((v) => {
                  return (
                    <li>
                      <Link target="_blank" to={`/store/${v._id}`}>
                        {v.name} (кат: {v.categoryName})
                      </Link>
                    </li>
                  );
                })}
              </ul>
            ),
          };
        })}
      />
    </Card>
  );
};

export default IdealClients;
