import React, { useEffect, useState } from 'react';
import { Select, Button, Form, message } from 'antd';
import config from "../../../config.json";
import axios from 'axios';

const { Option } = Select;

const StartAuction = () => {
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState(null);
  const [selectedDate, setSelectedDate] = useState('Сегодня');

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${config.service}/api/auction/categories`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
          },
        });
        setCategories(response.data);
      } catch (error) {
        console.error('Error fetching current auctions:', error);
      }
    };

    fetchCategories();
  }, []);

  const handleCategoryChange = (value) => {
    setCategory(value);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const formatDate = (date) => {
    const currentDate = new Date();
    if (date === 'Сегодня') {
      return currentDate.toISOString();
    } else if (date === 'Завтра') {
      const tomorrow = new Date(currentDate);
      tomorrow.setDate(currentDate.getDate() + 1);
      return tomorrow.toISOString();
    }
  };

  const handleSubmit = async () => {
    const selectedData = {
      category,
      startDate: formatDate(selectedDate),
    };
    try {
      const response = await axios.post(`${config.service}/api/auction/create-auction`, selectedData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
        },
      });
      if (response.status === 201) {
        message.success('Запрос успешно отправлен!');
      }
    } catch (error) {
      console.error('Error creating auction:', error);
    }
    console.log('Выбранные данные:', selectedData);
  };

  return (
    <Form layout="inline" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Form.Item label="Категория">
        <Select
          allowClear
          style={{ width: 300 }}
          placeholder="Выберите категорию"
          onChange={handleCategoryChange}
          showSearch
          optionFilterProp="children" 
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
          }

        >
          {categories.map((cat) => (
            <Option key={cat._id} value={cat.code.replace('Master -', '').trim()}>{cat.name}</Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Дата">
        <Button
          type={selectedDate === 'Сегодня' ? 'primary' : 'default'}
          onClick={() => handleDateChange('Сегодня')}
        >
          Сегодня
        </Button>
        <Button
          type={selectedDate === 'Завтра' ? 'primary' : 'default'}
          onClick={() => handleDateChange('Завтра')}
          style={{ marginLeft: 8 }}
        >
          Завтра
        </Button>
      </Form.Item>
      <Form.Item>
        <Button type="primary" onClick={handleSubmit}>
          Отправить
        </Button>
      </Form.Item>
    </Form>
  );
};

export default StartAuction;
