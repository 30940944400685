import { Modal } from "antd";

import CKEditor from "@ckeditor/ckeditor5-react";
import Editor from "@juniyadi/ckeditor5-custom-build";

const AddNotification = ({ show, onClose, onOk, notification }) => {
  const isCreateNewNotification = notification ? true : false;

  const submit = () => {
    onOk();
  };

  return (
    <Modal
      open={show}
      onCancel={onClose}
      onOk={submit}
      title={
        isCreateNewNotification
          ? "Добавить новое уведомление"
          : notification?.title || "Редактировать"
      }
      okText={isCreateNewNotification ? "Сохранить" : "Обновить"}
    >
      <CKEditor editor={Editor} data="<p>Hello from CKEditor 5!</p>" />
    </Modal>
  );
};

export default AddNotification;
