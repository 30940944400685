import {
  Form,
  Input,
  message,
  Modal,
  Switch,
  Select,
  InputNumber,
  Checkbox,
} from "antd";
import config from "../../../config.json";

import { useEffect, useState } from "react";
import { fetchTariffs } from "../utils/fetch";
import axios from "axios";
import { clearToken } from "../../../scripts/token";

const ActivateStoreModal = ({ isActivateStore, setIsActivateStore, store }) => {
  const [price, setPrice] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isOldClientNewStore, setIsOldClientNewStore] = useState(false);
  const [maxDempingProducts, setMaxDempingProducts] = useState();
  const [value, setValue] = useState(1);
  const [activationType, setActivationType] = useState("MONTH");
  const [selectedTariff, setSelectedTariff] = useState(null);
  const [doubleSpeed, setDoubleSpeed] = useState(false);
  const isPrivileged = store?.privilege ? true : false;
  const [selectedType, setSelectedType] = useState("SUBSCRIPTION");
  const [storeReceipt, setStoreReceipt] = useState(null);
  const [isLoadingReceipt, setIsLoadingReceipt] = useState(false);

  const [tariffs, setTariffs] = useState([]);
  useEffect(() => {
    fetchTariffs().then((data) => {
      const res = data.data || [];

      res.push({
        _id: "INDIVIDUAL",
        name: "Индивидуальный",
        price: 0,
        maxDempingProducts: 0,
        isChangeable: true,
      });

      setTariffs(res);
    });
  }, []);

  useEffect(() => {
    if (selectedType === "VIP_NAHUI") {
      fetchReceipt();
    }
  }, [selectedType]);

  const fetchReceipt = () => {
    setIsLoadingReceipt(true);

    axios
      .get(`${config.service}/api/privileged-store/${store._id}/receipt`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
        },
      })
      .then((data) => {
        setStoreReceipt(data.data);

        setPrice(Math.ceil(data?.data?.price || 0));
      })
      .finally(() => {
        setIsLoadingReceipt(false);
      });
  };

  useEffect(() => {
    if (selectedTariff) {
      let coefficient = 1 * value;
      if (activationType === "WEEK") {
        coefficient = (value * 7) / 30;
      } else if (activationType === "DAY") {
        coefficient = (value * 1) / 30;
      }

      let newPrice = Math.ceil(selectedTariff.price * coefficient);
      if (doubleSpeed) {
        newPrice *= 2;
      }

      setMaxDempingProducts(selectedTariff.maxDempingProductCount);
      setPrice(newPrice);
    }
  }, [selectedTariff, activationType, value, doubleSpeed]);

  const activationTypes = [
    {
      value: "MONTH",
      label: "Месяц",
    },
    {
      value: "WEEK",
      label: "Неделя",
    },
    {
      value: "DAY",
      label: "День",
    },
  ];

  const getActivationTypeLabel = () => {
    const found = activationTypes.find((v) => v.value === activationType);

    if (found) {
      return found.label;
    }

    return "Месяц";
  };

  const activateStoreSelect = () => {
    setIsLoading(true);

    const body = {
      storeId: store._id,
      price: parseInt(price),
      userId: store.userId,
      isOldClientNewStore,
      maxDempingProducts,
      tariffId: selectedTariff?._id,
      doubleSpeed,
      type: selectedType,
      receiptId: storeReceipt?._id,
    };
    body[activationType.toLowerCase()] = value;

    axios
      .post(`${config.service}/api/payment/select`, body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
        },
      })
      .then(() => {
        setIsActivateStore(false);

        message.success("Магазин успешно активирован", 3);
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          clearToken();
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Modal
      title="Активировать магазин"
      visible={isActivateStore}
      onOk={activateStoreSelect}
      onCancel={() => {
        setIsActivateStore(false);
      }}
      okText="Активировать"
      cancelText="Отменить"
      okButtonProps={{
        loading: isLoading,
      }}
    >
      <Form labelCol={{ span: 8 }} wrapperCol={{ span: 12 }}>
        <Form.Item>
          <div>Магазин:</div>
          <Input disabled={true} value={store?.name} />
        </Form.Item>
        {isPrivileged && (
          <Form.Item>
            <div>Тип оплаты</div>
            <Select
              onChange={(v) => {
                setSelectedType(v);
              }}
              value={selectedType}
              options={[
                {
                  label: "Подписка",
                  value: "SUBSCRIPTION",
                },
                {
                  label: "Комиссия, 0.7%",
                  value: "VIP_NAHUI",
                },
              ]}
            />
          </Form.Item>
        )}
        {selectedType === "SUBSCRIPTION" && (
          <>
            <Form.Item>
              <div>Тип активации:</div>
              <Select
                value={activationType}
                options={activationTypes}
                onChange={(event) => {
                  setActivationType(event);
                }}
              />
            </Form.Item>

            <Form.Item>
              <div>Тариф:</div>
              <Select
                options={tariffs.map((tariff) => {
                  return {
                    value: tariff._id,
                    label: tariff.name,
                  };
                })}
                value={selectedTariff?._id}
                onChange={(event) => {
                  const foundTariff = tariffs.find((v) => v._id === event);

                  if (!foundTariff) {
                    return;
                  }

                  setSelectedTariff(foundTariff);
                }}
              />
            </Form.Item>
            <Form.Item>
              <div>Срок активации ({getActivationTypeLabel()}):</div>
              <InputNumber
                placeholder={"Кол-во"}
                min={0}
                value={value}
                style={{ width: "100%" }}
                onChange={(event) => {
                  setValue(event);
                }}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                }
                parser={(value) => {
                  if (!value) {
                    return "";
                  }

                  return value.replace(/\$\s?|( *)/g, "");
                }}
              />
            </Form.Item>
          </>
        )}
        <Form.Item>
          <div>Цена:</div>
          <InputNumber
            disabled={isLoadingReceipt}
            prefix={
              <div className="border-right pe-1" style={{ fontSize: 16 }}>
                ₸
              </div>
            }
            placeholder="Сумма"
            value={price + ""}
            style={{ width: "100%" }}
            onChange={(event) => {
              if (event) {
                event = event + "";
                setPrice(event);
              } else {
                setPrice("0");
              }
            }}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
            }
            parser={(value) => {
              value = value.toString();

              if (!value) {
                return "";
              }

              const newValue = value.replace(/\$\s?|( *)/g, "");

              return newValue;
            }}
          />
        </Form.Item>
        {storeReceipt !== null && (
          <div>
            {storeReceipt?.weekNumber > 0 && (
              <div>
                Оплата за <b>{storeReceipt.weekNumber}-ю</b> неделю
              </div>
            )}
            <div>
              {storeReceipt?.startWeek && (
                <>
                  с{" "}
                  <b>{new Date(storeReceipt.startWeek).toLocaleDateString()}</b>
                </>
              )}{" "}
              {storeReceipt?.endWeek && (
                <>
                  по{" "}
                  <b>{new Date(storeReceipt.endWeek).toLocaleDateString()}</b>
                </>
              )}
            </div>
          </div>
        )}
        {selectedType === "SUBSCRIPTION" && (
          <Form.Item>
            <span
              style={{
                marginRight: 15,
              }}
            >
              Скорость х2
            </span>
            <Switch
              checked={doubleSpeed}
              onChange={(event) => {
                setDoubleSpeed(event);
              }}
            />
          </Form.Item>
        )}
        {selectedType === "SUBSCRIPTION" && (
          <Form.Item>
            <div>Кол-во активных товаров:</div>
            <InputNumber
              placeholder="Кол-во товара"
              min={0}
              disabled={!selectedTariff?.isChangeable}
              style={{ width: "100%" }}
              value={maxDempingProducts}
              onChange={(event) => {
                setMaxDempingProducts(event);
              }}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
              }
              parser={(value) => {
                if (!value) {
                  return "";
                }

                return value.replace(/\$\s?|( *)/g, "");
              }}
            />
          </Form.Item>
        )}
        {selectedType === "SUBSCRIPTION" && (
          <Form.Item name="isOldClientNewStore">
            <Checkbox
              onChange={(e) => {
                setIsOldClientNewStore(e.target.checked);
              }}
            >
              Старый клиент, но новый магазин
            </Checkbox>
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export default ActivateStoreModal;
