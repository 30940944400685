import { React, useContext, useEffect, useState } from 'react';
import { UploadOutlined, UserOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { Layout, Menu, theme, Avatar, Typography } from 'antd';
import { Socket } from 'socket.io-client';
import { formatDate, formatPhoneNumber } from '../utils/helper';
import { Search } from '@ant-design/pro-components';

const { Sider } = Layout;

const UserMenu = ({ socket, onMenuChange }) => {
    const { Paragraph } = Typography;
    const [chats, setChats] = useState([])
    const [chatChange, setChatChange] = useState(false)
    const adminId = localStorage.getItem('adminId')
    useEffect(() => {
        socket.emit('loadChats', {adminId: adminId, socketId: socket.id.toString()});

        const onLoadChatsListener = (data) => {
            setChats(prevChats => {
                const sortedNewData = data.sort((a, b) => {
                    const dateA = new Date(a.updatedAt);
                    const dateB = new Date(b.updatedAt);
                    return dateB - dateA;
                });
                return sortedNewData;
            });
        };
    
        const chatChangeListener = (data) => {
            setChatChange(prevChatChange => !prevChatChange); 
        };
    
        // Register event listeners
        socket.on('onLoadChats', onLoadChatsListener);
        socket.on('chatChange', chatChangeListener);
    
        // Clean up event listeners
        return () => {
            console.log("Unregistering events..");
            socket.off('onLoadChats', onLoadChatsListener);
            socket.off('chatChange', chatChangeListener);
        };
    
    }, [chatChange])

    const handleMenuSelect = ({ key }) => {
        const selectedItem = chats.find(item => item.chatId === key);
        onMenuChange(selectedItem);
    };

    return (
        <Sider
            breakpoint="lg"
            width={300}
            style={{ maxHeight: 'calc(100vh - 31px)', overflowX: 'hidden', overflowY: 'scroll' }}
        >
            <div className="demo-logo-vertical" />
           
            <Menu theme="dark" mode="inline" onSelect={handleMenuSelect} selectable={true} >
                {chats.map(item => (
                    <Menu.Item key={item.chatId} style={{ height: '70px', padding: '10px', paddingLeft: '10px' }} >
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", alignContent: 'center', gap: '8px' }}>
                            <div style={{ height: '50px', display: 'flex' }}> <Avatar style={{ backgroundColor: '#87d068' }} size={50} icon={<UserOutlined style={{ fontSize: '24px' }} />} /></div>
                            <div style={{ display: "grid", justifyContent: 'start' }}>
                                <Paragraph style={{ margin: 0, color: "#FFFFFF", fontSize: "1rem", fontWeight: '500', lineHeight: '1' }} > {item.message?.contactName ? item.message?.contactName : formatPhoneNumber(item.chatId)}</Paragraph>
                                <Paragraph italic={item.message?.type != 'text' ?? true} style={{ margin: 0, color: "#FFFFFF", opacity: '70%', fontSize: "0.9rem", fontWeight: '300', lineHeight: '2' }} >{item.message?.isEcho ? 'Вы:' : ''} {item.message?.type == 'text' ? item.message?.text : 'Вложение'}</Paragraph>
                            </div>
                            <Paragraph  style={{ margin: 0, color: "#FFFFFF", fontSize: "0.9rem", fontWeight: '400', lineHeight: '2', position: 'absolute', right:'10px', top:'0'}} > {formatDate(item.message?.dateTime)}</Paragraph>
                        </div>
                    </Menu.Item>
                ))}
            </Menu>
        </Sider>
    )
}

export default UserMenu;