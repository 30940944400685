import axios from "axios";
import config from "../../../config.json";

export const fetchStoreStats = (storeId) => {
  return axios.get(`${config.service}/api/product/${storeId}/loads`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
    },
  });
};
export const fetchTariffs = () => {
  return axios.get(`${config.service}/api/tariff`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
    },
  });
};
