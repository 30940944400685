import { Divider, Skeleton } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import config from "../../../config.json";
import { currencyFormat } from "../../../scripts/format";
import { clearToken } from "../../../scripts/token";

const ClientPayments = ({ clientId }) => {
  const [payments, setPayments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchPayments();
  }, [clientId]);

  const fetchPayments = () => {
    axios
      .get(`${config.service}/api/payment/client/${clientId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
        },
      })
      .then((data) => {
        setPayments(data.data);
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          clearToken();
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div>
      <Divider />
      <h1>Все оплаты</h1>
      <div>
        {isLoading && <Skeleton active />}
        {payments.map((payment, index) => {
          return <PaymentInfo key={index} payment={payment} />;
        })}
      </div>
    </div>
  );
};

const PaymentInfo = ({ payment }) => {
  return (
    <div
      style={{
        backgroundColor: "white",
        padding: 10,
        borderBottom: "1px solid lightgray",
      }}
    >
      {payment?.type !== "ANALYTICS" && (
        <div>
          <b>{payment?.store?.name}</b>
        </div>
      )}
      <div>
        Сумма: <b>{currencyFormat(payment.price)}</b>
      </div>
      <div>
        Дата: <b>{new Date(payment.createdAt).toLocaleString()}</b>
      </div>
      <div>
        Кол-во месяца: <b>{payment.numberOfMonth}</b>
      </div>
      <div>
        Тип: <b>{payment.type}</b>
      </div>
    </div>
  );
};

export default ClientPayments;
