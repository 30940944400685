import { Card, Table } from "antd";
import { useEffect, useState } from "react";
import RegisteredUserAnalytics from "./components/RegisteredUserAnalytics";
import {
  getClientsThatStartedPaying,
  getClientsThatStartedTesting,
  getDepartedUsers,
  getRegisteredUserAnalytics,
  getStoresThatStartedTesting,
  getdepartedStores,
  getstoresThatStartedPaying,
} from "./utils/fetch";
import { Column } from "@ant-design/plots";
import ClientAndStoreOverview from "./components/ClientAndStoreOverview";
import TopClientCategoriesByProductsForMonthAndYear from "./components/TopClientCategoriesByProductsForMonthAndYear";
import TopClientCategoriesByStoresForMonthAndYear from "./components/TopClientCategoriesByStoresForMonthAndYear";
import IdealClientsCategories from "./components/IdealClientsCategories";
import IdealClients from "./components/IdealClients";
import MostCompetitiveCategories from "./components/MostCompetitiveCategories";
import ClientSegmentation from "./components/ClientSegmentation";
import useIsMobile from "../../hooks/useIsMobile";

const Analytics = () => {
  const [isLoading, setIsLoading] = useState(true);
  const isMobile = useIsMobile()
  const [registeredUsers, setRegisteredUsers] = useState([]);
  const [departedUsers, setDepartedUsers] = useState([]);
  const [clientsThatStartedPaying, setClientsThatStartedPaying] = useState([]);
  const [clientsThatStartedTesting, setClientsThatStartedTesting] = useState(
    []
  );
  const [storesThatStartedTesting, setStoresThatStartedTesting] = useState([]);
  const [storesThatStartedPaying, setStoresThatStartedPaying] = useState([]);
  const [departedStores, setDepartedStores] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [chartData, setChartData] = useState([]);

  const a = [
    {
      title: "Начали тест (клиенты)",
      data: clientsThatStartedTesting,
    },
    {
      title: "Начали тест (магазины)",
      data: storesThatStartedTesting,
    },
    {
      title: "Начали платить (клиенты)",
      data: clientsThatStartedPaying,
    },
    {
      title: "Начали платить (магазины)",
      data: storesThatStartedPaying,
    },
    {
      title: "Ушедшие (клиенты)",
      data: departedUsers,
    },
    {
      title: "Ушедшие (магазины)",
      data: departedStores,
    },
  ];

  useEffect(() => {
    getRegisteredUserAnalytics()
      .then(setRegisteredUsers)
      .finally(() => setIsLoading(false));

    getDepartedUsers().then(setDepartedUsers);
    getClientsThatStartedPaying().then(setClientsThatStartedPaying);
    getClientsThatStartedTesting().then(setClientsThatStartedTesting);
    getStoresThatStartedTesting().then(setStoresThatStartedTesting);
    getstoresThatStartedPaying().then(setStoresThatStartedPaying);
    getdepartedStores().then(setDepartedStores);
  }, []);

  useEffect(() => {
    updateTableData();

    updateChartData();
  }, [
    departedStores,
    departedUsers,
    clientsThatStartedPaying,
    storesThatStartedPaying,
    clientsThatStartedTesting,
    storesThatStartedTesting,
  ]);

  const updateTableData = () => {
    const data = [];

    for (const b of a) {
      data.push(getDataForTable(b));
    }

    setTableData(data);
  };

  const updateChartData = () => {
    const data = [];

    for (const b of a) {
      b.data.map((v) => {
        data.push({
          title: b.title,
          value: v.value,
          key: v.key,
        });
      });
    }

    setChartData(data);
  };

  const getDataForTable = (data) => {
    const obj = {
      period: data.title,
    };
    // eslint-disable-next-line array-callback-return
    data.data.map((v) => {
      obj[v.key] = v.value;
    });

    return obj;
  };

  return (
    <Card className={isMobile ? 'mx-2' : 'mx-3'} id="analytics">
      <h1>Аналитика</h1>
      <ClientAndStoreOverview />
      <Card style={{ overflow: "auto" }}>
        <Table
          loading={isLoading}
          size="small"
          pagination={false}
          bordered
          columns={[
            {
              title: "",
              key: "period",
              dataIndex: "period",
            },
            ...registeredUsers.map((v) => {
              return {
                title: v.key,
                dataIndex: v.key,
                key: v.key,
              };
            }),
          ]}
          dataSource={tableData}
        />
      </Card>
      <Card className="mt-3">
        <Column
          data={chartData}
          xField="key"
          yField="value"
          seriesField="title"
          isGroup={true}
        />
      </Card>
      <TopClientCategoriesByProductsForMonthAndYear />
      <TopClientCategoriesByStoresForMonthAndYear />
      <IdealClientsCategories />
      <IdealClients />
      <ClientSegmentation />
      <MostCompetitiveCategories />
      <RegisteredUserAnalytics registeredUsers={registeredUsers} />
    </Card>
  );
};

export default Analytics;
