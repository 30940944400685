import React, { useState, useRef } from 'react';
import { Form, Input, Button, Upload, message } from 'antd';
import { UploadOutlined, SendOutlined, AudioOutlined, CameraOutlined  } from '@ant-design/icons';

const SubmitForm = ({onSubmitForm}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const inputRef = useRef(null); // Ref for the input element

  const onFinish = async (values) => {
    setLoading(true);
    await onSubmitForm(values)
    setLoading(false);
    form.resetFields();
    inputRef.current.focus(); // Focus on the input element after form submission
  };

  const onFileUploadChange = (info) => {
    if (info.file.status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  return (
    <Form
      form={form}
      name="submitForm"
      onFinish={onFinish}
      initialValues={{ remember: true }}
      style={{display: 'flex', width: '100%', gap: '8px', height: '50px'}}
    >
      <Form.Item
        name="image"
        valuePropName="fileList"
        getValueFromEvent={normFile}
      >
        <Upload
          name="image"
          action="/upload/image"
          listType="picture"
          onChange={onFileUploadChange}
        >
          <Button icon={<CameraOutlined />} style={{height: '50px', backgroundColor: 'rgba(255, 255, 255, 0.5)'}} disabled={true}>Изображение</Button>
        </Upload>
      </Form.Item>

      <Form.Item
        name="voiceMessage"
        valuePropName="fileList"
        getValueFromEvent={normFile}
      >
        <Upload
          name="voice"
          action="/upload/voice"
          listType="text"
          onChange={onFileUploadChange}
        >
          <Button icon={<AudioOutlined  />} style={{height: '50px' , backgroundColor: 'rgba(255, 255, 255, 0.5)'}} disabled={true}></Button>
        </Upload>
      </Form.Item>

      <Form.Item
        name="message"
        rules={[{ required: true, message: 'Введите сообщение' }]}
        style={{width: '100%'}}
      >
        <Input ref={inputRef} style={{height: '50px'}} autoComplete="off"/>
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading} icon={<SendOutlined />} style={{height: '50px'}}>
          Отправить
        </Button>
      </Form.Item>
    </Form>
  );
};

export default SubmitForm;
