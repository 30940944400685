import { React } from 'react';
import { Card, Divider, Grid, Select, Space } from 'antd';
import StartAuction from './components/StartAuction';
import Auctions from './components/Auctions';
import useIsMobile from '../../hooks/useIsMobile';



const Auction = () => {
  const isMobile = useIsMobile()
  return (
    <Card className={isMobile ? 'mx-2' : "mx-3"} id="analytics">
      <h1>Аукцион</h1>
      <Card style={{ overflow: 'auto' }}>
        <Divider orientation="left">Создать аукцион</Divider>
        <StartAuction />
        <Divider orientation="left">Текущие аукционы</Divider>
        <Auctions isCurrent={true}/>
        <Divider orientation="left">История аукционов</Divider>
        <Auctions isCurrent={false}/>
      </Card>
    </Card>

  );
};

export default Auction;