import { Skeleton } from "antd";
import { useEffect, useState } from "react";
import { currencyFormat } from "../../../scripts/format";
import { fetchStoreStats } from "../utils/fetch";

const StorePaymentInfo = ({ store }) => {
  const [storeStats, setStoreStats] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loadStoreStats();
  }, [store]);

  const loadStoreStats = () => {
    setIsLoading(true);

    if (!store?._id) {
      setIsLoading(false);
      return;
    }

    fetchStoreStats(store?._id)
      .then((data) => {
        setStoreStats(data.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (isLoading || !store) {
    return (
      <Skeleton
        style={{
          width: "45%",
        }}
      />
    );
  }

  return (
    <div
      style={{
        minWidth: 350,
        width: "45%",
      }}
    >
      <div>
        <div>
          Среднее кол-во активных товаров:{" "}
          <b>{currencyFormat(storeStats?.activeProductsCount)}</b>
        </div>
        <div>
          Активные города:{" "}
          <b>{currencyFormat(storeStats?.storeCityCount || 0)}</b>
        </div>
        <div>
          Последний платеж:{" "}
          <b>{currencyFormat(storeStats?.lastPaymentPrice)}</b>
        </div>
        <div>
          Минимальный следующий платеж:{" "}
          <b>{currencyFormat(storeStats?.nextMinPrice)}</b>
        </div>
        {store?.apiToken && (
          <div className="mt-3">
            <h3 className="mb-0">Заказы</h3>
            <div>
              До (
              {new Date(
                storeStats?.orders?.before?.date?.from
              ).toLocaleDateString()}
              -
              {new Date(
                storeStats?.orders?.before?.date?.till
              ).toLocaleDateString()}
              ):{" "}
              <b>
                {storeStats?.orders?.before?.quantity} (
                {currencyFormat(storeStats?.orders?.before?.totalPrice)})
              </b>
            </div>
            <div>
              После (
              {new Date(
                storeStats?.orders?.after?.date?.from
              ).toLocaleDateString()}
              -
              {new Date(
                storeStats?.orders?.after?.date?.till
              ).toLocaleDateString()}
              ):{" "}
              <b>
                {storeStats?.orders?.after?.quantity} (
                {currencyFormat(storeStats?.orders?.after?.totalPrice)})
              </b>
            </div>
            <div>
              Сейчас (
              {new Date(
                storeStats?.orders?.current?.date?.from
              ).toLocaleDateString()}
              -
              {new Date(
                storeStats?.orders?.current?.date?.till
              ).toLocaleDateString()}
              ):{" "}
              <b>
                {storeStats?.orders?.current?.quantity} (
                {currencyFormat(storeStats?.orders?.current?.totalPrice)})
              </b>
            </div>
          </div>
        )}
        {!store?.apiToken && (
          <div className="mb-3">
            <b>Не дал API Токен</b>
          </div>
        )}
      </div>
    </div>
  );
};

export default StorePaymentInfo;
