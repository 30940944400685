import { Alert, Card, Spin, Tabs } from "antd";
import { PageHeader } from '@ant-design/pro-components';
import axios from "axios";
import { useEffect, useState } from "react";
import confg from "../../config.json";
import StoreProductPositionAnalyticsInfo from "./components/StoreProductPositionAnalyticsInfo";
import { LoadingOutlined, CheckOutlined } from "@ant-design/icons";
import useIsMobile from "../../hooks/useIsMobile";

const StoreProductPositionAnalytics = () => {
  const [stores, setStores] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const isMobile = useIsMobile()
  const fetchStores = () => {
    setIsLoading(true);

    axios
      .get(`${confg.service}/api/store-product-position-analytics/stores`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
        },
      })
      .then((data) => setStores(data.data))
      .finally(() => {
        setIsLoading(false);
      });
  };
  useEffect(fetchStores, []);

  return (
    <Card className={isMobile ? 'mx-2' : "mx-3"}>
      <div>
        <PageHeader title="Аналитика позиции товаров магазина" />
      </div>
      {isLoading && (
        <div className="w-100 d-flex align-items-center justify-content-center my-5">
          <Spin />
        </div>
      )}
      {!isLoading && stores.length === 0 && (
        <div>
          <Alert description="На данный момент аналитика позиции магазина не собирается" />
        </div>
      )}
      {!isLoading && stores.length > 0 && (
        <div>
          <Tabs
            type="card"
            items={stores.map((store) => {
              let isActive = true;
              if (
                new Date(store.analyticsGatheringExpirationDate) < new Date()
              ) {
                isActive = false;
              }

              return {
                key: store.storeForGatheringProductAnalyticsId,
                label: (
                  <div>
                    {isActive ? <LoadingOutlined /> : <CheckOutlined />}
                    {store.storeName}
                  </div>
                ),
                children: (
                  <StoreProductPositionAnalyticsInfo
                    store={store}
                    fetchStores={fetchStores}
                  />
                ),
              };
            })}
          />
        </div>
      )}
    </Card>
  );
};

export default StoreProductPositionAnalytics;
