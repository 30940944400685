import { React, useState, useEffect, useRef } from 'react'
import { Alert, Typography } from 'antd'

const SelectChat = () => {
  const { Paragraph } = Typography;

  return (
    <div style={{ 
      display: 'grid',     
      height: '100%',
      alignItems: 'center',
      alignContent: 'center'
       }}>
      <Alert style={{height: '200px', fontSize: '3rem'}} message="Выберите чат!" type="info" showIcon />
    </div>

  )
}

export default SelectChat;