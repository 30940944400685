import axios from "axios";
import config from "../../../config.json";

export const getDepartedUsers = async () => {
  return await axios
    .get(`${config.service}/api/client-analytics/departed`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
};

export const getClientsThatStartedPaying = async () => {
  return await axios
    .get(`${config.service}/api/client-analytics/started-paying`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
};

export const getRegisteredUserAnalytics = async () => {
  return await axios
    .get(`${config.service}/api/client-analytics/registered`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
};

export const getClientsThatStartedTesting = async () => {
  return await axios
    .get(`${config.service}/api/client-analytics/started-testing`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
};

export const getStoresThatStartedTesting = async () => {
  return await axios
    .get(`${config.service}/api/store-analytics/started-testing`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
};

export const getstoresThatStartedPaying = async () => {
  return await axios
    .get(`${config.service}/api/store-analytics/started-paying`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
};

export const getdepartedStores = async () => {
  return await axios
    .get(`${config.service}/api/store-analytics/departed`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
};

export const getClientLifetimeValue = async () => {
  return await axios
    .get(`${config.service}/api/client-analytics/lifetime-value`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return {
        arithmeticLifetimeValue: 0,
        medianLifetimeValue: 0,
      };
    });
};

export const getAverageDealTimeWithClient = async () => {
  return await axios
    .get(`${config.service}/api/client-analytics/average-deal-time`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return {
        arithmeticAverageDealTime: 0,
        medianAverageDealTime: 0,
      };
    });
};

export const getTopClientCategoriesByProductsForMonthAndYear = async () => {
  return await axios
    .get(
      `${config.service}/api/client-analytics/top-product-categories-by-month`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
};

export const getTopClientCategoriesByStoresForMonthAndYear = async () => {
  return await axios
    .get(
      `${config.service}/api/client-analytics/top-stores-categories-by-month`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
};

export const getIdealClientsCategories = async () => {
  return await axios
    .get(`${config.service}/api/client-analytics/ideal-clients-categories`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
};

export const getIdealClients = async () => {
  return await axios
    .get(`${config.service}/api/client-analytics/ideal-clients`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
};

export const getMostCompetitiveCategories = async () => {
  return await axios
    .get(`${config.service}/api/analytics/most-competitive-categories`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
};

export const getSegmentedClients = async () => {
  return await axios
    .get(`${config.service}/api/client-analytics/segmented-clients`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
};
