import { Checkbox, Tooltip } from "antd";

export const AutoAcceptOrderSettings = ({ product }) => {
  return (
    <div>
      <Tooltip>
        <Checkbox checked={product.autoacceptOrder}>
          Автопринятие заказа
        </Checkbox>
      </Tooltip>
    </div>
  );
};

export default AutoAcceptOrderSettings;
