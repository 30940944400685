import { Button, Card, Descriptions, Table } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import config from "../../../config.json";
import cities from "../../../constants/cities.json";
import { currencyFormat } from "../../../scripts/format";
import { clearToken } from "../../../scripts/token";

export const PriceHistory = ({ product }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [lastChanges, setLastChanges] = useState([]);

  useEffect(() => {
    fetchLastChanges();
  }, [product.id]);

  const fetchLastChanges = () => {
    setIsLoading(true);
    setLastChanges([]);
    console.log(product);

    axios
      .get(
        `${config.service}/api/price-history/last?storeId=${product.storeId}&sku=${product.sku}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              "admin_access_token"
            )}`,
          },
        }
      )
      .then((data) => {
        setLastChanges(data.data);
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          clearToken();
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getFilters = () => {
    const result = [];

    for (let lastChange of lastChanges) {
      const foundIndex = result.findIndex((v) => v.value === lastChange.city);

      if (foundIndex === -1) {
        result.push({
          text: cities[lastChange.city]?.name || "",
          value: cities[lastChange.city]?.id || "",
        });
      }
    }

    return result;
  };

  const columns = [
    {
      title: "Город",
      dataIndex: "cityName",
      key: "cityName",
      filters: getFilters(),
      filterMode: "tree",
      onFilter: (value, record) => {
        return record.data.city.includes(value);
      },
    },
    {
      title: "Старая цена",
      dataIndex: "oldPrice",
      key: "oldPrice",
    },
    {
      title: "Новая цена",
      dataIndex: "newPrice",
      key: "newPrice",
    },
    {
      title: "Время",
      dataIndex: "time",
      key: "time",
    },
  ];

  const data = [];
  for (let lastChange of lastChanges) {
    data.push({
      data: lastChange,
      key: lastChange._id,
      time: new Date(lastChange.createdAt).toLocaleString(),
      oldPrice: currencyFormat(lastChange.price.old),
      newPrice: currencyFormat(lastChange.price.new),
      cityName: cities[lastChange.city]?.name || "",
    });
  }

  return (
    <div>
      <Button className="mb-3" onClick={fetchLastChanges}>
        Обновить
      </Button>

      <div>
        <i>* Данные за последние 72 часа</i>
      </div>
      <Table
        className="mt-3"
        size="small"
        bordered
        loading={isLoading}
        columns={columns}
        dataSource={data}
        // expandable={{
        //   expandedRowRender: (record) => {
        //     return <PriceHistoryInfo history={record.data} />;
        //   },
        // }}
      />
    </div>
  );
};

const PriceHistoryInfo = ({ history }) => {
  const stores = history?.stores || [];

  return (
    <Card>
      <Descriptions>
        <Descriptions.Item label="Мин. цена">
          {currencyFormat(history.availableMinPrice)}
        </Descriptions.Item>
      </Descriptions>
      <div className="mt-3">
        <Table
          bordered
          size="small"
          columns={[
            {
              title: "Позиция",
              dataIndex: "position",
              key: "position",
              width: 40,
              align: "center",
            },
            {
              title: "Магазин",
              dataIndex: "storeName",
              key: "storeName",
            },
            {
              title: "Цена",
              dataIndex: "price",
              key: "price",
            },
          ]}
          dataSource={stores.map((store, index) => {
            return {
              position: index + 1,
              storeName: store.merchantName || "",
              price: currencyFormat(store.price),
              merchantId: store.merchantId,
              _id: store._id,
            };
          })}
        />
      </div>
    </Card>
  );
};
