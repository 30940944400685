import { Button, Form, Input } from "antd";
import axios from "axios";
import config from "../../config.json";
import { useState } from "react";
import { message } from "antd";
import { LoginOutlined } from "@ant-design/icons";

const Login = () => {
  const [isLoading, setIsLoading] = useState();

  const onFinish = (values) => {
    setIsLoading(true);

    axios
      .post(`${config.service}/api/user/login`, values)
      .then((data) => {
        localStorage.setItem("admin_access_token", data.data.access_token);
        window.location.reload();
      })
      .catch((err) => {
        message.error(
          `Не удалось авторизоваться: Cтатус ошибки: ${
            err?.response?.status || "Не известно"
          }`
        );
      })
      .finally(() => setIsLoading(false));
  };

  const onFinishFailed = (errorInfo) => {};

  return (
    <div
      className="d-flex flex-column align-items-center w-100 px-3"
      style={{
        marginTop: 100,
      }}
    >
      <h2>Авторизация</h2>
      <Form
        style={{
          maxWidth: 400,
        }}
        className="w-100"
        name="basic"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Логин"
          name="login"
          rules={[
            {
              required: true,
              message: "Please input your login!",
            },
          ]}
          labelCol={{ span: 24 }}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Пароль"
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
          labelCol={{ span: 24 }}
        >
          <Input.Password />
        </Form.Item>

        <div className="d-flex justify-content-center">
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            icon={<LoginOutlined />}
            className="px-4"
          >
            Войти
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default Login;
