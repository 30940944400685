import { Button, Empty, Spin, Table } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import config from "../../../config.json";

const ProductWithdrawFromSale = ({ product }) => {
  const productId = product.data._id;

  const [histories, setHistories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetch = () => {
    setIsLoading(true);

    axios
      .get(
        `${config.service}/api/product/${productId}/withdraw-from-sale-info`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              "admin_access_token"
            )}`,
          },
        }
      )
      .then((data) => {
        setHistories(data.data);
      })
      .finally(() => setIsLoading(false));
  };
  useEffect(fetch, [productId]);

  if (isLoading) {
    return (
      <div className="d-flex align-items-center justify-content-center my-5">
        <Spin />
      </div>
    );
  }

  if (histories.length === 0) {
    return (
      <div>
        <Button onClick={fetch}>Обновить</Button>
        <div className="d-flex align-items-center justify-content-center my-5">
          <Empty />
        </div>
      </div>
    );
  }

  const columns = [
    {
      title: "Дата",
      dataIndex: "data",
      key: "data",
    },
    {
      title: "Время",
      dataIndex: "time",
      key: "time",
    },
    {
      title: "Статус",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Метод",
      dataIndex: "method",
      key: "method",
    },
  ];

  const getStatusName = (status) => {
    switch (status) {
      case "SUCCESS":
        return "Успешно";
      case "ERROR":
        return "Ошибка";
      default:
        return "Статус неизвестен";
    }
  };

  const getMethodName = (method) => {
    switch (method) {
      case "MANUALLY":
        return "Вручную";
      case "ORDER":
        return "Автоматический (Заказ)";
      default:
        return "Метод неизвестен";
    }
  };

  const dataSource = histories.map((item) => ({
    ...item,
    key: item._id,
    method: getMethodName(item.method),
    status: getStatusName(item.status),
    data: new Date(item.createdAt).toLocaleDateString(),
    time: new Date(item.createdAt).toLocaleTimeString(),
  }));

  return (
    <div>
      <Button className="mb-3" onClick={fetch}>
        Обновить
      </Button>
      <div>
        <Table columns={columns} dataSource={dataSource}></Table>
      </div>
    </div>
  );
};

export default ProductWithdrawFromSale;
