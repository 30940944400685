import { Button, Card, Descriptions, Statistic, Table } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import config from "../../../config.json";
import {
  currencyFormat,
  getFormattedDateString,
} from "../../../scripts/format";
import FileSaver from "file-saver";
import ExcelJS from "exceljs";
import { ReloadOutlined } from "@ant-design/icons";
import ProductPositionHistory from "./ProductPositionHistory";

const ProductPositionAnalytics = ({ store, startDate, endDate }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetch = () => {
    setIsLoading(true);

    axios
      .get(
        `${config.service}/api/store-product-position-analytics/${store.storeForGatheringProductAnalyticsId}/product-position-analytics?startDate=${startDate}&endDate=${endDate}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              "admin_access_token"
            )}`,
          },
        }
      )
      .then((data) => setData(data.data))
      .finally(() => setIsLoading(false));
  };

  const downloadExcel = () => {
    if (!Array.isArray(data)) {
      return;
    }

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet 1");

    const header = [
      "Название товара",
      "SKU",
      "Код товара",
      "Общее время",
      "На первом месте",
      "Не на первом месте",
      "Процент",
      "Cтатистика конкурентов вне SaleScout",
      "Cтатистика конкурентов внутри SaleScout",
      "Дата начала сбора данных",
      "Дата окончания сбора данных",
      "Ниже мин. цены",
      "Ссылка на товар",
    ];
    worksheet.addRow(header);

    for (const product of data) {
      const anotherStoresPositionAnalytics = [];
      const anotherSaleScoutClientsPositionAnalytics = [];
      for (const client of product.anotherStoresPositionAnalytics) {
        if (!client.merchantName) {
          continue;
        }

        const anotherSaleScoutClient = product.anotherSaleScoutClients.find(
          (v) => v.merchantId === client.merchantId
        );
        if (anotherSaleScoutClient) {
          continue;
        }

        anotherStoresPositionAnalytics.push(
          `${client.merchantName} - ${getFormattedDateString(
            client.firstPlace || 0
          )} (${currencyFormat(
            (client.firstPlace / product.totalTime) * 100
          )}%)`
        );
      }
      for (const client of product.anotherSaleScoutClients) {
        const analytics = product.anotherStoresPositionAnalytics.find(
          (v) => v.merchantId === client.merchantId
        );
        if (!analytics) {
          continue;
        }

        anotherSaleScoutClientsPositionAnalytics.push(
          `${analytics.merchantName} - ${getFormattedDateString(
            analytics.firstPlace || 0
          )} (${currencyFormat(
            (analytics.firstPlace / product.totalTime) * 100
          )}%)`
        );
      }

      const body = [
        product.productName || "",
        product.productSku || "",
        product.masterSku || "",
        getFormattedDateString(product.totalTime || 0),
        `${getFormattedDateString(
          product.firstPlaceTime || 0
        )} (${currencyFormat(product.percent)}%)`,
        getFormattedDateString(product.notFirstPlaceTime || 0),
        `${product.percent || 0}%`,
        anotherStoresPositionAnalytics.join(". "),
        anotherSaleScoutClientsPositionAnalytics.join(". "),
        new Date(product.startGatheringDate).toLocaleString("ru-RU", {
          timeZone: "Asia/Almaty",
        }),
        new Date(product.endGatheringDate).toLocaleString("ru-RU", {
          timeZone: "Asia/Almaty",
        }),
        `${getFormattedDateString(
          product?.belowMinPriceInfo?.belowMinPriceTime || 0
        )}`,
        product.productUrl || "",
      ];

      worksheet.addRow(body);
    }

    worksheet.columns.forEach(function (column, i) {
      let maxLength = 0;
      column["eachCell"]({ includeEmpty: true }, function (cell) {
        var columnLength = cell.value ? cell.value.toString().length : 10;
        if (columnLength > maxLength) {
          maxLength = columnLength;
        }
      });
      column.width = maxLength < 10 ? 10 : maxLength;
    });

    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      FileSaver.saveAs(
        blob,
        `${
          store.storeName
        } - Product Position Analytics | ${new Date().toLocaleDateString()}.xlsx`
      );
    });
  };

  useEffect(() => {
    fetch();
  }, [store, startDate, endDate]);

  return (
    <Card className="my-3">
      <div className="my-3 d-flex align-items-center justify-content-between">
        <Button
          onClick={() => fetch()}
          type="primary"
          ghost
          icon={<ReloadOutlined />}
          loading={isLoading}
        >
          Обновить
        </Button>
        <Button
          type="primary"
          ghost
          onClick={downloadExcel}
          disabled={isLoading}
        >
          Скачать Excel
        </Button>
      </div>
      <Table
        size="small"
        loading={isLoading}
        bordered
        columns={[
          {
            dataIndex: "productInfo",
            title: "Товар",
            width: 250,
          },
          {
            dataIndex: "positionInfo",
            title: "Позиция",
          },
          {
            dataIndex: "gatheringInfo",
            title: "Сбор данных",
          },
        ]}
        dataSource={data.map((product) => {
          return {
            key: product.masterSku,
            data: product,
            productInfo: (
              <div>
                <div>
                  <a href={product.productUrl} target="_blank" rel="noreferrer">
                    {product.productName}
                  </a>
                </div>
                <div style={{ fontSize: 12 }} className="text-secondary">
                  <div>SKU: {product.productSku}</div>
                  <div>Код товара: {product.masterSku}</div>
                </div>
              </div>
            ),
            positionInfo: (
              <div>
                <div>
                  Общее время:{" "}
                  <b>{getFormattedDateString(product.totalTime)}</b>
                </div>
                <div>
                  На первом месте:{" "}
                  <b>{getFormattedDateString(product.firstPlaceTime)}</b>
                </div>
                <div>
                  Не на первом месте:{" "}
                  <b>{getFormattedDateString(product.notFirstPlaceTime)}</b>
                </div>
                <Statistic value={`${product.percent || 0}%`} />
              </div>
            ),
            gatheringInfo: (
              <div>
                <div>
                  <div>Дата начала сбора данных</div>
                  <b>{new Date(product.startGatheringDate).toLocaleString()}</b>
                </div>
                <div>
                  <div>Дата окончания сбора данных</div>
                  <b>{new Date(product.endGatheringDate).toLocaleString()}</b>
                </div>
              </div>
            ),
          };
        })}
        expandable={{
          expandedRowRender: (product) => {
            const anotherStoresPositionAnalytics =
              product?.data?.anotherStoresPositionAnalytics || [];
            const anotherSaleScoutClients =
              product?.data?.anotherSaleScoutClients || [];

            const totalTime = product?.data?.totalTime || 0;

            return (
              <div>
                <Card className="mb-2" column={1}>
                  <Descriptions labelStyle={{color: '#202020'}}className="m-0 p-0">
                    {(product?.data?.belowMinPriceInfo?.belowMinPriceTime ||
                      0) > 0 && (
                      <Descriptions.Item
                        label="Ниже мин. цены"
                        className="p-0 m-0"
                      >
                        <b>
                          {getFormattedDateString(
                            product?.data?.belowMinPriceInfo
                              ?.belowMinPriceTime || 0
                          )}
                        </b>
                      </Descriptions.Item>
                    )}

                    {(product?.data?.belowMinPriceInfo?.notBelowMinPriceTime ||
                      0) > 0 && (
                      <Descriptions.Item
                        label="Не ниже мин. цены"
                        className="p-0 m-0"
                      >
                        <b>
                          {getFormattedDateString(
                            product?.data?.belowMinPriceInfo
                              ?.notBelowMinPriceTime || 0
                          )}
                        </b>
                      </Descriptions.Item>
                    )}
                  </Descriptions>
                </Card>
                {anotherStoresPositionAnalytics.length > 0 && (
                  <Card className="mb-2">
                    <h4>Конкуренты магазина в товаре:</h4>
                    <div>
                      {anotherStoresPositionAnalytics.map((client, index) => {
                        const percent = currencyFormat(
                          (client.firstPlace / totalTime) * 100
                        );

                        return (
                          <div key={index}>
                            {client.merchantName} ({client.merchantId}) -{" "}
                            <b>
                              {getFormattedDateString(client.firstPlace || 0)}
                            </b>{" "}
                            <b>({percent} %)</b>
                          </div>
                        );
                      })}
                    </div>
                  </Card>
                )}
                {anotherSaleScoutClients.length > 0 && (
                  <Card className="mb-2">
                    <h4>Другие клиенты SaleScout:</h4>
                    <div>
                      {anotherSaleScoutClients.map((client, index) => {
                        return (
                          <div key={index}>
                            {client.merchantName} ({client.merchantId})
                          </div>
                        );
                      })}
                    </div>
                  </Card>
                )}

                <ProductPositionHistory
                  store={store}
                  startDate={startDate}
                  endDate={endDate}
                  masterSku={product.key}
                />
              </div>
            );
          },
        }}
      />
    </Card>
  );
};

export default ProductPositionAnalytics;
