import { Table } from "antd";
import { Link } from "react-router-dom";

const formatDate = (date) => {
  const arr = date.split(".");
  const day = parseInt(arr[0]);
  const month = parseInt(arr[1]);
  const year = parseInt(arr[2]);

  return new Date(year, month, day);
};

 export const formatPhone = (phone) => {
  let res = "+7 (7";

  res +=
    phone[3] +
    phone[4] +
    ") " +
    phone[5] +
    phone[6] +
    phone[7] +
    " " +
    phone[8] +
    phone[9] +
    phone[10] +
    phone[11];

  return res;
};

const columns = [
  {
    title: "ФИО",
    dataIndex: "fullName",
    key: "fullName",
  },
  {
    title: "Номер телефона",
    dataIndex: "phoneNumber",
    key: "phoneNumber",
  },
  {
    title: "Дата регистрации",
    dataIndex: "registrationDate",
    key: "registrationDate",
    sorter: (a, b) =>
      formatDate(a.registrationDate).getTime() -
      formatDate(b.registrationDate).getTime(),
  },
];

const ShowClients = ({ clients, isLoading }) => {
  const data = [];

  for (let client of clients) {
    data.push({
      fullName: (
        <Link to={`/client/${client._id}`}>
          {client.name + " " + client.surname}
        </Link>
      ),
      phoneNumber: formatPhone(client.email),
      registrationDate: new Date(client.createdAt).toLocaleDateString(),
      id: client._id,
    });
  }

  return (
    <div
      style={{
        margin: "30px 0",
      }}
    >
      <Table
        loading={isLoading}
        size="small"
        columns={columns}
        dataSource={data}
        pagination={false}
        bordered
      />
    </div>
  );
};

export default ShowClients;
