import { Badge, Calendar, Card, Spin } from "antd";

const ProxyCalendar = ({ proxies, isLoading }) => {
  const getListData = (value) => {
    let listData = [];
    const date = value.date();
    const month = value.month();
    const year = value.year();

    for (const proxy of proxies) {
      const expireDate = new Date(proxy.expireDate);

      if (
        expireDate.getFullYear() === year &&
        month === expireDate.getMonth() &&
        date === expireDate.getDate()
      ) {
        listData.push({
          type: "error",
          content: proxy.phoneName || proxy.name || proxy.proxy || "",
        });
      }
    }

    return listData || [];
  };

  const dateCellRender = (value) => {
    const listData = getListData(value);
    return (
      <ul className="events">
        {listData.map((item) => (
          <li key={item.content}>
            <Badge status={item.type} text={item.content} />
          </li>
        ))}
      </ul>
    );
  };

  if (isLoading) {
    return (
      <div
        style={{
          minHeight: 400,
        }}
        className="w-100 d-flex align-items-center justify-content-center"
      >
        <Spin size="default" />
      </div>
    );
  }

  return (
    <Card>
      <Calendar dateCellRender={dateCellRender} />;
    </Card>
  );
};

export default ProxyCalendar;
