import { Button, Form, InputNumber, Modal, message } from "antd";
import config from "../../../config.json";
import { useState } from "react";
import axios from "axios";

export const ActivateExpressAnalytics = ({ user }) => {
  const [open, setOpen] = useState(false);
  const [price, setPrice] = useState(0);
  const [expressAnalyticsRequestCount, setExpressAnalyticsRequestCount] =
    useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const handleActivate = () => {
    setIsLoading(true);

    axios
      .post(
        `${config.service}/api/payment/express-analytics`,
        {
          price: parseInt(price),
          expressAnalyticsRequestCount: parseInt(expressAnalyticsRequestCount),
          clientId: user._id,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              "admin_access_token"
            )}`,
          },
        }
      )
      .then(() => {
        message.success("Turbo аналитика успешно активирована");
        setOpen(false);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <Button type="primary" className="ms-3" onClick={() => setOpen(true)}>
        Активировать Turbo аналитику
      </Button>
      <Modal
        open={open}
        title={"Активировать Turbo аналитику"}
        okText="Активировать"
        onCancel={() => setOpen(false)}
        onOk={handleActivate}
        okButtonProps={{
          loading: isLoading,
        }}
      >
        <Form>
          <Form.Item>
            <div>Цена:</div>
            <InputNumber
              prefix={
                <div className="border-right pe-1" style={{ fontSize: 16 }}>
                  ₸
                </div>
              }
              placeholder="Сумма"
              value={price + ""}
              style={{ width: "100%" }}
              onChange={(event) => {
                if (event) {
                  event = event + "";
                  setPrice(event);
                } else {
                  setPrice("0");
                }
              }}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
              }
              parser={(value) => {
                value = value.toString();

                if (!value) {
                  return "";
                }

                const newValue = value.replace(/\$\s?|( *)/g, "");

                return newValue;
              }}
            />
          </Form.Item>
          <Form.Item>
            <div>Кол-во запросов:</div>
            <InputNumber
              placeholder="Кол-во запросов"
              value={expressAnalyticsRequestCount + ""}
              style={{ width: "100%" }}
              onChange={(event) => {
                if (event) {
                  event = event + "";
                  setExpressAnalyticsRequestCount(event);
                } else {
                  setExpressAnalyticsRequestCount("0");
                }
              }}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
              }
              parser={(value) => {
                value = value.toString();

                if (!value) {
                  return "";
                }

                const newValue = value.replace(/\$\s?|( *)/g, "");

                return newValue;
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
