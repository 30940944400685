import { Button, Card, Spin } from "antd";
import { ServiceHealthCard } from "./components/ServiceHealthCard";
import { useEffect, useState } from "react";
import axios from "axios";
import config from "../../config.json";
import { ReloadOutlined } from "@ant-design/icons";
import useIsMobile from "../../hooks/useIsMobile";

export const SystemHealthDashboard = () => {
  const [services, setServices] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [lastUpdatedDate, setLastUpdatedDate] = useState(null);

  const fetch = () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    axios
      .get(`${config.service}/api/system-health`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
        },
      })
      .then((data) => {
        setLastUpdatedDate(new Date());
        setServices(data.data.services);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetch();

    for (let i = 0; i < 5000; i++) {
      clearInterval(i);
    }

    setInterval(fetch, 15000);
  }, []);
  const sortOrder = ["Kaspi", "Halyk", "Jmart"];
  services
    .sort((a, b) => {
      return (
        sortOrder.indexOf(a.marketplaceName) -
        sortOrder.indexOf(b.marketplaceName)
      );
    })
    .sort((a, b) => b.status - a.status);

  return (
    <Card className={ useIsMobile() ? 'mx-2' : "mx-3"}>
      <div className="d-flex align-items-center">
        <h2 className="m-0">Состояния системы</h2>
        {lastUpdatedDate && (
          <div className="ms-5">
            Последнее обновление: <b>{lastUpdatedDate.toLocaleString()}</b>
          </div>
        )}
      </div>
      <div className="mx-3 mt-2">
        <ul className="">
          <li>
            <b>1001</b> - Товар на предзаказе
          </li>
          <li>
            <b>1002</b> - Товар не доступен ни в одном складе
          </li>
          <li>
            <b>1003</b> - Товар не найден
          </li>
          <li>
            <b>1004</b> - Товар не продается ни в одном городе
          </li>
          <li>
            <b>1006</b> - TimeOutError
          </li>
        </ul>
      </div>
      <Button
        className="mt-2"
        icon={<ReloadOutlined />}
        loading={isLoading}
        onClick={fetch}
      >
        Обновить
      </Button>

      <div className="d-flex flex-wrap mt-4">
        {services.map((service, index) => {
          return <ServiceHealthCard key={index} service={service} />;
        })}
      </div>
    </Card>
  );
};
