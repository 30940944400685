import { Pagination, Table } from "antd";
import { useEffect, useState } from "react";
import config from "../../../config.json";
import { clearToken } from "../../../scripts/token";
import axios from "axios";

const StoreStateHistory = ({ store }) => {
  const storeId = store._id;

  const [limit, setLimit] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [histories, setHistories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalPageCount, setTotalPageCount] = useState(0);

  const fetch = () => {
    setIsLoading(true);

    axios
      .get(
        `${config.service}/api/action/${storeId}/store-state?&limit=${limit}&page=${currentPage}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              "admin_access_token"
            )}`,
          },
        }
      )
      .then((data) => {
        setHistories(data.data.actionHistories);
        setTotalPageCount(data.data.totalCount);
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          clearToken();
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetch();
  }, [storeId, limit, currentPage]);

  const columns = [
    {
      title: "Дата",
      dataIndex: "data",
      key: "data",
    },
    {
      title: "Время",
      dataIndex: "time",
      key: "time",
    },
    {
      title: "Действие",
      dataIndex: "action",
      key: "action",
    },
    {
      title: "Автор",
      dataIndex: "author",
      key: "author",
    }
  ];

  const getStoreActionPagination = () => {
    return (
      <Pagination
        current={currentPage}
        disabled={isLoading}
        total={totalPageCount}
        onChange={(value) => {
          if (value === currentPage) {
            return;
          }

          setCurrentPage(value);
        }}
        onShowSizeChange={async (current, size) => {
          if (size === limit) {
            return;
          }

          setLimit(size);
        }}
        defaultPageSize={limit}
        size={limit}
      />
    );
  };

  const dataSource = histories.map((item) => ({
    ...item,
    key: item._id,
    action: item.isStarted ? "ЗАПУСТИТЬ БОТ" : "ОСТАНОВИТЬ БОТ",
    author: item.author,
    data: new Date(item.createdAt).toLocaleDateString(),
    time: new Date(item.createdAt).toLocaleTimeString(),
  }));

  return (
    <div>
      <Table
        size={"small"}
        bordered
        loading={isLoading}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
      ></Table>
      {getStoreActionPagination()}
    </div>
  );
};

export default StoreStateHistory;
