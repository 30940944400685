import { Card, Checkbox, Descriptions, Table } from "antd";
import { currencyFormat } from "../../../scripts/format";
import { useState } from "react";

export const ProductCities = ({ product }) => {
  const cities = product.cities || [];
  const [productMerchants, setProductMerchants] = useState([]);

  const columns = [
    {
      title: "Город",
      dataIndex: "cityName",
      key: "cityName",
    },
    {
      title: "",
      dataIndex: "prices",
      key: "prices",
    },
    {
      title: "",
      dataIndex: "demping",
      key: "demping",
    },
  ];

  const data = [];
  for (let city of cities) {
    data.push({
      cityName: city.cityName,
      prices: (
        <div>
          <div>Мин.&nbsp;цена: {currencyFormat(city.availableMinPrice)}</div>
          <div>Макс.&nbsp;цена: {currencyFormat(city.availableMaxPrice)}</div>
        </div>
      ),
      demping: (
        <div>
          <Checkbox checked={city.isDemping} disabled={true}>
            Автосниж.
          </Checkbox>
        </div>
      ),
    });
  }

  return (
    <div className="product-city">
      <Card>
        <Descriptions labelStyle={{color: '#202020'}}column={1}>
          <Descriptions.Item label="Шаг снижения цены (тг)">
            <b>{product.dempingPrice || 0}</b>
          </Descriptions.Item>
          <Descriptions.Item label="Менять только в гл. городе">
            <b>{product.isDempingOnlyMainCity ? "Да" : "Нет"}</b>
          </Descriptions.Item>
          <Descriptions.Item label="Обновлять каждые 7 дней">
            <b>{product.isUpdateEveryWeek ? "Да" : "Нет"}</b>
          </Descriptions.Item>
          <Descriptions.Item label="Снять с продажи, если нет в наличии">
            <b>{product.isWithdrawFromSale ? "Да" : "Нет"}</b>
          </Descriptions.Item>
          <Descriptions.Item label="Последняя проверка">
            <b>{new Date(product.lastCheckedDate).toLocaleString()}</b>
          </Descriptions.Item>
          <Descriptions.Item label="Последняя актуализация складов">
            <b>
              {product.lastCityDataUpdatedDate &&
                new Date(product.lastCityDataUpdatedDate).toLocaleString()}
              {!product.lastCityDataUpdatedDate && <i>Нет</i>}
            </b>
          </Descriptions.Item>
        </Descriptions>
      </Card>
      {data.length > 0 && (
        <div>
          <Table
            bordered
            size="small"
            columns={columns}
            dataSource={data}
            pagination={false}
          />
        </div>
      )}
    </div>
  );
};
