import axios from "axios";
import {
  Card,
  Pagination,
  Form,
  Button,
  DatePicker,
  Table,
  Select} from "antd";
import { PageHeader } from '@ant-design/pro-components';
import Search from "antd/lib/transfer/search";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { clearToken } from "../../scripts/token";
import config from "../../config.json";
import { currencyFormat } from "../../scripts/format";
import { SearchOutlined } from "@ant-design/icons";

const StoreOrders = () => {

  const [query, setQuery] = useState("");
  const [limit, setLimit] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [isLoadingStoreOrders, setIsLoadingStoreOrders] = useState(false);
  const params = useParams();
  const storeId = params.id;
  const { Option } = Select;
  const [state, setState] = useState("");
  const [status, setStatus] = useState("");
  const [dateRange, setDateRange] = useState(null);
  const [storeOrders, setStoreOrders] = useState([]);
  const navigate = useNavigate();

  const onBack = () => {
    navigate(`/store/${storeId}`);
  };

  useEffect(() => {
    getStoreOrders();
  }, [storeId, state, status, currentPage, limit, dateRange]);

  const getFormattedDate = (date) => {
    if (date !== "") {
      const newDate = new Date(date);
      const year = newDate.getFullYear();
      const month = newDate.getMonth() + 1;
      const day = newDate.getDate();
      return `${year}-${month}-${day}`;
    }

    return "";
  };

  const getStoreOrders = () => {
    setIsLoadingStoreOrders(true);

    let formattedDateStart = "";
    let formattedDateEnd = "";

    if (dateRange !== null) {
      formattedDateStart = getFormattedDate(dateRange[0]);
      formattedDateEnd = getFormattedDate(dateRange[1]);
    }

    axios
      .get(
        `${config.service}/api/order/store/${storeId}/orders/?&q=${query}&limit=${limit}&page=${currentPage}&state=${state}&status=${status}&dateStart=${formattedDateStart}&dateEnd=${formattedDateEnd}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              "admin_access_token"
            )}`,
          },
        }
      )
      .then((data) => {
        setStoreOrders(data.data.storeOrders);
        setTotalPageCount(data.data.totalCount);
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          clearToken();
        }
      })
      .finally(() => {
        setIsLoadingStoreOrders(false);
      });
  };

  const states = [
    { value: "NEW", label: "Новый заказ" },
    { value: "SIGN_REQUIRED", label: "Заказ на подписании" },
    { value: "PICKUP", label: "Самовывоз" },
    { value: "DELIVERY", label: "Доставка" },
    { value: "KASPI_DELIVERY", label: "Kaspi Доставка, Kaspi Postomat" },
    { value: "ARCHIVE", label: "Архивный заказ" },
    { value: "", label: "Все" },
  ];

  const statuses = [
    { value: "APPROVED_BY_BANK", label: "Одобрен банком" },
    { value: "ACCEPTED_BY_MERCHANT", label: "Принят на обработку продавцом" },
    { value: "COMPLETED", label: "Завершён" },
    { value: "CANCELLED", label: "Отменён" },
    { value: "CANCELLING", label: "Ожидает отмены" },
    { value: "KASPI_DELIVERY_RETURN_REQUESTED", label: "Ожидает возврата" },
    {
      value: "RETURN_ACCEPTED_BY_MERCHANT",
      label: "Ожидает решения по возврату",
    },
    { value: "RETURNED", label: "Возвращён" },
    { value: "", label: "Все" },
  ];

  const getFilterForm = () => {
    return (
      <div className="mt-3 mb-3 d-flex">
        <Select
          disabled={isLoadingStoreOrders}
          loading={isLoadingStoreOrders}
          value={state}
          placeholder="Выберите состояние..."
          style={{ width: 200 }}
          onChange={(v) => {
            setState(v);
            setCurrentPage(1);
          }}
        >
          {states.map((m, index) => {
            return (
              <Option key={index} value={m?.value}>
                <div className="d-flex align-items-center">
                  <div>{m?.label}</div>
                </div>
              </Option>
            );
          })}
        </Select>
        <Select
          disabled={isLoadingStoreOrders}
          loading={isLoadingStoreOrders}
          value={status}
          placeholder="Выберите статус..."
          style={{ width: 200, marginLeft: 16 }}
          onChange={(v) => {
            setStatus(v);
            setCurrentPage(1);
          }}
        >
          {statuses.map((m, index) => {
            return (
              <Option key={index} value={m?.value}>
                <div className="d-flex align-items-center">
                  <div>{m?.label}</div>
                </div>
              </Option>
            );
          })}
        </Select>
        <DatePicker.RangePicker
          disabled={isLoadingStoreOrders}
          style={{ width: 200, marginLeft: 16 }}
          format="YYYY-MM-DD"
          showTime
          onChange={(v) => {
            setDateRange(v);
            setCurrentPage(1);
          }}
          value={dateRange}
        />
      </div>
    );
  };

  const columns = [
    {
      title: "Название товара",
      dataIndex: "productName",
      key: "productName",
    },
    {
      title: "Код товара",
      dataIndex: "productCode",
      key: "productCode",
    },
    {
      title: "Код заказа",
      dataIndex: "orderCode",
      key: "orderCode",
    },
    {
      title: "Цена",
      dataIndex: "totalPrice",
      key: "totalPrice",
    },
    {
      title: <div className="nowrap">Кол-во</div>,
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Состояние",
      dataIndex: "state",
      key: "state",
    },
    {
      title: "Статус",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Пунк выдачи",
      dataIndex: "addressDisplayName",
      key: "addressDisplayName",
    },
    {
      title: "Город",
      dataIndex: "town",
      key: "town",
    },
    {
      title: "Дата",
      dataIndex: "creationDate",
      key: "creationDate",
    },
  ];

  const getProductStateName = (state) => {
    switch (state) {
      case "NEW":
        return "Новый заказ";
      case "SIGN_REQUIRED":
        return "Заказ на подписании";
      case "PICKUP":
        return "Самовывоз";
      case "DELIVERY":
        return "Доставка";
      case "KASPI_DELIVERY":
        return "Kaspi Доставка, Kaspi Postomat";
      case "ARCHIVE":
        return "Архивный заказ";
      default:
        return "Статус неизвестен";
    }
  };

  const getProductStatusName = (status) => {
    switch (status) {
      case "APPROVED_BY_BANK":
        return "Одобрен банком";
      case "ACCEPTED_BY_MERCHANT":
        return "Принят на обработку продавцом";
      case "COMPLETED":
        return "Завершён";
      case "CANCELLED":
        return "Отменён";
      case "CANCELLING":
        return "Ожидает отмены";
      case "KASPI_DELIVERY_RETURN_REQUESTED":
        return "Ожидает возврата";
      case "RETURN_ACCEPTED_BY_MERCHANT":
        return "Ожидает решения по возврату";
      case "RETURNED":
        return "Возвращён";
      default:
        return "Состояние неизвестен";
    }
  };

  const dataSource = storeOrders.map((item) => ({
    ...item,
    key: item._id,
    totalPrice: <div className="nowrap">{currencyFormat(item.totalPrice)}</div>,
    state: getProductStateName(item.state),
    status: getProductStatusName(item.status),
    creationDate: new Date(item.creationDate).toLocaleString(),
  }));

  const getStoreOrdersPagination = () => {
    return (
      <div className="d-flex justify-content-center">
        <Pagination
          className="mt-3"
          current={currentPage}
          disabled={isLoadingStoreOrders}
          total={totalPageCount}
          onChange={(value) => {
            if (value === currentPage) {
              return;
            }
            setCurrentPage(value);
          }}
          onShowSizeChange={async (current, size) => {
            if (size === limit) {
              return;
            }

            setLimit(size);
          }}
          defaultPageSize={limit}
          size={limit}
        />
      </div>
    );
  };

  const getSearchForm = () => {
    return (
      <Form className="d-flex" onFinish={getStoreOrders}>
        <Search
          placeholder="Поиск по ключевому слову"
          allowClear
          enterButton="Поиск"
          size="large"
          disabled={isLoadingStoreOrders}
          value={query}
          onChange={(event) => {
            setQuery(event.target.value);
          }}
        />
        <Button
          htmlType="submit"
          type="primary"
          loading={isLoadingStoreOrders}
          className="ms-1"
          icon={<SearchOutlined />}
        >
          Поиск
        </Button>
      </Form>
    );
  };

  return (
    <Card className="mx-3">
      <div>
        <PageHeader title="Заказы магазина" onBack={onBack} />
      </div>
      {getSearchForm()}
      {getFilterForm()}
      <Table
        bordered
        size="small"
        dataSource={dataSource}
        columns={columns}
        loading={isLoadingStoreOrders}
        pagination={false}
      />
      {getStoreOrdersPagination()}
    </Card>
  );
};

export default StoreOrders;
