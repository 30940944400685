import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ProTable } from '@ant-design/pro-components';
import config from "../../../config.json";

const Auctions = ({isCurrent}) => {
    const [currentAuctions, setCurrentAuctions] = useState([]);
    const [auctionProducts, setAuctionProducts] = useState({});
    const [loadingProducts, setLoadingProducts] = useState({});
    const [productPagination, setProductPagination] = useState({});

    useEffect(() => {
        fetchCurrentAuctions();
    }, []);

    const fetchCurrentAuctions = async () => {
        try {
            const response = await axios.get(`${config.service}/api/auction/${isCurrent ? "current-auctions" : "past-auctions"}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
                  },
            });
            setCurrentAuctions(response.data);
        } catch (error) {
            console.error('Error fetching current auctions:', error);
        }
    };

    const fetchAuctionProducts = async (auctionId, page, pageSize) => {
        try {
            const skip = (page-1) * pageSize
            setLoadingProducts((prev) => ({ ...prev, [auctionId]: true }));
            const response = await axios.get(`${config.service}/api/auction/auction-products/${auctionId}/${skip}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
                  },
            });
            setAuctionProducts((prev) => ({
                ...prev,
                [auctionId]: {
                    products: response.data.productsWithNames,
                    total: response.data.count,
                },
            }));
            setLoadingProducts((prev) => ({ ...prev, [auctionId]: false }));
        } catch (error) {
            console.error('Error fetching auction products:', error);
            setLoadingProducts((prev) => ({ ...prev, [auctionId]: false }));
        }
    };

    const handleProductTableChange = (auctionId, pagination) => {
        setProductPagination((prev) => ({
            ...prev,
            [auctionId]: pagination,
        }));
        console.log(pagination)
        fetchAuctionProducts(auctionId, pagination.current, pagination.pageSize);
    };

    const auctionColumns = [
        {
            title: 'Категория',
            dataIndex: 'categoryName',
            key: 'categoryName',
            ellipsis: true,
        },
        {
            title: 'Начало аукциона',
            dataIndex: 'startDate',
            key: 'startDate',
            render: (text) => new Date(text.props.children).toLocaleString(),
            ellipsis: true,
        },
        {
            title: 'Конец аукциона',
            dataIndex: 'endDate',
            key: 'endDate',
            render: (text) => new Date(text.props.children).toLocaleString(),
            ellipsis: true,
        },
        {
            title: 'Статус',
            dataIndex: 'isFinished',
            key: 'isFinished',
            render: (text) => (text.props.children == true ? 'Завершен' : 'В процессе'),
            ellipsis: true,
        },
    ];

    const productColumns = [
        {
            title: 'SKU',
            dataIndex: 'masterProductSku',
            key: 'masterProductSku',
            ellipsis: true,
        },
        {
            title: 'Наименование товара',
            dataIndex: 'productName',
            key: 'productName',
            ellipsis: true,
        },
        {
            title: 'Последняя\nСтавка',
            dataIndex: 'lastBid',
            key: 'lastBid',
            ellipsis: true,
            width: 200,
        },
        {
            title: 'Магазин',
            dataIndex: 'winnerStoreId',
            key: 'winnerStoreId',
            ellipsis: true,
        },
        {
            title: 'Оплачен',
            dataIndex: 'isPaid',
            key: 'isPaid',
            render: (text) => (text ? 'Да' : 'Нет'),
            ellipsis: true,
        },
    ];

    return (
        <ProTable
            columns={auctionColumns}
            dataSource={currentAuctions}
            rowKey="_id"
            pagination={{
                pageSize: 10,
            }}
            expandable={{
                expandedRowRender: (record) => (
                    <ProTable
                        columns={productColumns}
                        dataSource={auctionProducts[record._id]?.products || []}
                        rowKey="_id"
                        pagination={{
                            current: productPagination[record._id]?.current || 1,
                            pageSize: productPagination[record._id]?.pageSize || 20,
                            total: auctionProducts[record._id]?.total || 0,
                            onChange: (page, pageSize) => handleProductTableChange(record._id, { current: page, pageSize }),
                        }}
                        loading={loadingProducts[record._id]}
                        search={false}
                        options={false}
                    />
                ),
                onExpand: (expanded, record) => {
                    if (expanded && !auctionProducts[record._id]) {
                        fetchAuctionProducts(record._id, 1, 20);
                    }
                },
            }}
            editable={false}
            search={false}
            request={async () => {
                await fetchCurrentAuctions();
                return {
                    data: currentAuctions,
                };
            }}
        />
    );
};

export default Auctions;
