import {
  Button,
  Form,
  Input,
} from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import config from "../../../config.json";
import { clearToken } from "../../../scripts/token";

const OrderApiToken = ({ store }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [storeAPIToken, setStoreAPIToken] = useState("");

  const storeId = store._id;

  const getStoreApi = () => {
    setIsLoading(true);
    axios
      .get(`${config.service}/api/order-api-token/${storeId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
        },
      })
      .then((data) => {
          setStoreAPIToken(data.data.token);
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          clearToken();
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const createStoreApi = () => {
    setIsLoading(true);
    axios
      .post(`${config.service}/api/order-api-token/${storeId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
        },
      })
      .then((data) => {
        setStoreAPIToken(data.data.token);
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          clearToken();
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getStoreApi();
  }, [storeId]);

  return (
    <Form>
      <>
        <Form.Item>
          <Input value={storeAPIToken} />
        </Form.Item>
        <Button
          loading={isLoading}
          disabled={storeAPIToken !== ""}
          type="primary"
          onClick={createStoreApi}
        >
          Сгенерировать токен
        </Button>
      </>
    </Form>
  );
};

export default OrderApiToken;
