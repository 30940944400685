import { React, useState, useEffect, useRef } from 'react';
import Message from './Message';
import { fetchInitialMessages } from '../utils/fetch';
import SelectChat from './SelectChat';

const ChatMessages = ({ newMessageSend, chatId, socket }) => {
  const [messages, setMessages] = useState([]);
  const [firstScroll, setFirstScroll] = useState(true)
  const [offset, setOffset] = useState(0)
  const [numberOfMsg, setNumberOfMsg] = useState(40)
  const messagesEndRef = useRef(null);
  const loadMoreMessage = useRef(null);

  const sendMessage = (newMessage) => {
    if (newMessage.text) {
      socket.emit('sendNewMessage', { phone: chatId, message: newMessage.text });
      setMessages(prevMessages => [newMessage, ...prevMessages]);
    }
  };

  const loadMessageToTheTop = (newMessages) => {
    const updatedMessages = [...messages, ...newMessages];
    setMessages(updatedMessages);
  };

  useEffect(() => {
    sendMessage(newMessageSend)
    scrollToBottom()
  }, [newMessageSend])

  // useEffect(() => {
  //   firstMessageLoad();
  //   setOffset(offset+10)
  // }, []);

  useEffect(() => {
    setOffset(15)
    setFirstScroll(true)
    firstMessageLoad();
    setMessages([]);

  }, [chatId]);

  const firstMessageLoad = () => {
    fetchInitialMessages(chatId, 0)
      .then((data) => {
        setMessages(prevMessages => [...data.data, ...prevMessages]);
      })
      .finally(() => {
        scrollToBottom()
        setTimeout(() => { setFirstScroll(false) }, 1000)
      });
  };
  const loadMoreMessagesRequest = () => {
    if (offset >= numberOfMsg) {
      return
    }
    fetchInitialMessages(chatId, offset)
      .then((data) => {
        loadMessageToTheTop(data.data)
        setOffset(offset + 15)
      })
  };
  const handleOnMessage = (data) => {
    console.log(data.content.messageId)
    if (data.content?.chatId === chatId) {
      setMessages(prevMessages => {
        const updatedMessages = [...prevMessages];
        const existingMessageIndex = updatedMessages.findIndex(message => message.text === data.content.text && message.status === 'new');
        if (existingMessageIndex !== -1) {
          console.log(existingMessageIndex)
          updatedMessages[existingMessageIndex] = data.content;
        } else {
          updatedMessages.unshift(data.content);
        }

        return updatedMessages;
      });
    }
  };

  useEffect(() => {
    socket.on('onMessage', handleOnMessage);
    return () => {
      console.log("Unregistering events..");
      socket.off('onMessage', handleOnMessage);
    };
  }, [chatId]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(async (entry) => {
        if (entry.isIntersecting) {
          loadMoreMessagesRequest()
          //TODO: скролл вниз чтобы сообщения не грузились очень быстро
        }
      });
    });

    if (loadMoreMessage.current && !firstScroll) {
      observer.observe(loadMoreMessage.current);
    }

    return () => {
      if (loadMoreMessage.current && !firstScroll) {
        observer.unobserve(loadMoreMessage.current);
      }
    };


  }, [loadMoreMessagesRequest]);

  const scrollToBottom = () => {
    setTimeout(() => {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
    }, 10)
  };

  if (!chatId) return <SelectChat></SelectChat>
  return (
    <div style={{ display: 'grid', height: 'calc(100vh - 126px)', overflowY: 'scroll', scrollbarWidth: 'none' }}>
      <div style={{ display: 'flex', flexDirection: 'column-reverse' }}>
        <div ref={messagesEndRef} style={{ height: '120px' }} />
        {messages.map((message, index) => (
          <div
            key={index}
            style={{
              display: 'flex',
              justifyContent: message.isEcho ? 'flex-end' : 'flex-start',
              marginBottom: '10px',
            }}
          >
            <Message
              message={message.text}
              isEcho={message.isEcho}
              time={message.dateTime}
              status={message.status}
              type={message.type}
              contentUri={message.contentUri}
            />
          </div>
        ))}
        <div ref={loadMoreMessage} style={{ height: '30px' }} />
      </div>
    </div>
  )
}

export default ChatMessages;