import { Card, Col, Row, Statistic } from "antd";
import { useEffect, useState } from "react";
import {
  getAverageDealTimeWithClient,
  getClientLifetimeValue,
} from "../utils/fetch";
import { getFormattedDateString } from "../../../scripts/format";

const ClientAndStoreOverview = () => {
  const [lifetimeValue, setLifetimeValue] = useState({
    arithmeticLifetimeValue: 0,
    medianLifetimeValue: 0,
  });
  const [averageDealTime, setAverageDealTime] = useState({
    arithmeticAverageDealTime: 0,
    medianAverageDealTime: 0,
  });

  useEffect(() => {
    getClientLifetimeValue().then(setLifetimeValue);
    getAverageDealTimeWithClient().then(setAverageDealTime);
  }, []);

  return (
    <Card className="mb-3">
      <Row gutter={16}>
        <Col span={12}>
          <Statistic
            title="Средний LTV (арифметическое)"
            value={getFormattedDateString(
              lifetimeValue.arithmeticLifetimeValue
            )}
          />
        </Col>
        <Col span={12}>
          <Statistic
            title="Средний LTV (медианное)"
            value={getFormattedDateString(lifetimeValue.medianLifetimeValue)}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Statistic
            title="Среднее время сделки (арифметическое)"
            value={getFormattedDateString(
              averageDealTime.arithmeticAverageDealTime
            )}
          />
        </Col>
        <Col span={12}>
          <Statistic
            title="Среднее время сделки (медианное)"
            value={getFormattedDateString(
              averageDealTime.medianAverageDealTime
            )}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default ClientAndStoreOverview;
