import { Table } from "antd";
import { Link } from "react-router-dom";

const columns = [
  {
    title: "",
    dataIndex: "marketplaceLogo",
    key: "marketplaceLogo",
    width: 30,
  },
  {
    title: "Название",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Оплачен до",
    dataIndex: "expireDate",
    key: "expireDate",
  },
  {
    title: "Главный город",
    dataIndex: "mainCity",
    key: "mainCity",
  },
  {
    title: "Кол-во городов",
    dataIndex: "cityLimit",
    key: "cityLimit",
  },
  {
    title: "Остановлен",
    dataIndex: "isStarted",
    key: "isStarted",
  },
];

const ShowStores = ({ isLoading, stores }) => {
  const data = [];
  for (let store of stores) {
    data.push({
      id: store?._id,
      name: <Link to={`/store/${store._id}`}>{store?.name}</Link>,
      expireDate: (
        <div
          style={{
            color: new Date(store?.expireDate) > new Date() ? "green" : "red",
          }}
        >
          {new Date(store.expireDate).toLocaleDateString()}
        </div>
      ),
      mainCity: store?.mainCity?.name || "Алматы",
      cityLimit: store?.cityLimit || 0,
      isStarted: store?.isStarted ? "Нет" : "Да",
      marketplaceLogo: store?.marketplace?.logo ? (
        <img style={{ width: 25, height: 25 }} src={store?.marketplace?.logo} />
      ) : (
        ""
      ),
    });
  }

  return (
    <div
      style={{
        margin: "30px 0",
      }}
    >
      <Table
        style={{overflow:'scroll'}}
        loading={isLoading}
        size="small"
        columns={columns}
        dataSource={data}
        pagination={false}
        bordered
      />
    </div>
  );
};

export default ShowStores;
