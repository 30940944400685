import { Button, InputNumber, message } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import config from "../../../config.json";

const BudgetInfo = () => {
  const [budget, setBudget] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [costs, setCosts] = useState(0);
  const [margin, setMargin] = useState(0);

  useEffect(() => {
    setIsLoading(true);

    axios
      .get(`${config.service}/api/budget`)
      .then((data) => {
        setBudget(data.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    setCosts(budget?.costs || 0);
    setMargin(budget?.margin || 0);
  }, [budget]);

  const saveBudget = () => {
    setIsLoading(true);

    axios
      .post(`${config.service}/api/budget`, {
        costs,
        margin,
      })
      .then((data) => {
        message.success("Бюджет сохранен");

        setBudget(data.data);
      })
      .catch(() => {
        message.error("Не удалось сохранить бюджет"); 
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div>
      <h2 className="border-bottom mb-4 pb-3">Бюджет</h2>
      <div
        className="flex-column"
        style={{
          marginBottom: 25,
          display: "flex",
          maxWidth: 400,
        }}
      >
        <div>
          <div className="mb-1">Расходы:</div>
          <InputNumber
            className="w-100"
            addonBefore="₸"
            value={costs}
            onChange={(event) => {
              setCosts(event);
            }}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
            }
            parser={(value) => {
              if (!value) {
                return "";
              }

              return value.replace(/\$\s?|( *)/g, "");
            }}
          />
        </div>
        <div className="mt-3">
          <div className="mb-1">Маржа:</div>
          <InputNumber
            className="w-100"
            addonBefore="%"
            value={margin}
            onChange={(event) => {
              setMargin(event);
            }}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
            }
            parser={(value) => {
              if (!value) {
                return "";
              }

              return value.replace(/\$\s?|( *)/g, "");
            }}
          />
        </div>
        <div className="mt-3">
          <Button
            loading={isLoading}
            type="primary"
            onClick={() => {
              saveBudget();
            }}
          >
            Сохранить
          </Button>
        </div>
      </div>
    </div>
  );
};

export default BudgetInfo;
