import { Card, message, Select, Table, Input, Segmented } from "antd";
import { WhatsAppOutlined } from "@ant-design/icons";
import axios from "axios";
import { useEffect, useState } from "react";
import config from "../../config.json";
import useIsMobile from "../../hooks/useIsMobile";

const PotentialClient = () => {
  const [potentialClients, setPotentialClients] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState("NEW");
  const isMobile = useIsMobile()
  useEffect(() => {
    fetch();
  }, [status]);

  const fetch = () => {
    setIsLoading(true);
    setPotentialClients([]);

    axios
      .get(`${config.service}/api/kaspi-merchant?status=${status}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
        },
      })
      .then((data) => {
        setPotentialClients(data.data || []);
      })
      .finally(() => setIsLoading(false));
  };

  const onChange = (storeId, data) => {
    const loadingMsg = message.loading("Магазин обновляется");

    axios
      .patch(
        `${config.service}/api/kaspi-merchant/${storeId}`,
        {
          ...data,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              "admin_access_token"
            )}`,
          },
        }
      )
      .then(() => {
        message.success("Магазин обновлен");

        fetch();
      })
      .catch(() => {
        message.error("Не удалось обновить магазина");
      })
      .finally(() => {
        loadingMsg();
      });
  };

  const openWhatsapp = (storePhone) => {
    window.open(
      `https://api.whatsapp.com/send?phone=${storePhone.replace("+", "")}`
    );
  };
  
  return (
    <Card className={isMobile ? 'mx-2' : "mx-3"}>
      <h1 className="border-bottom pb-3 mb-3">
        Потенциальные клиенты ({potentialClients.length})
      </h1>
      <Segmented
        block
        onChange={(value) => setStatus(value)}
        options={[
          {
            label: "Новые",
            value: "NEW",
          },
          {
            label: "В работе",
            value: "IN_PROCESS",
          },
          {
            label: "Архив",
            value: "ARCHIVE",
          },
        ]}
        className="mb-3"
      />
      <div style={{ overflow: "auto" }}>
        <Table
          loading={isLoading}
          pagination={false}
          size="small"
          bordered
          columns={[
            {
              title: "Магазин",
              dataIndex: "storeName",
              key: "storeName",
              width: 175,
            },
            {
              title: <div>Номер&nbsp;телефона</div>,
              dataIndex: "storePhone",
              key: "storePhone",
              width: 150,
            },
            {
              title: "Товары",
              dataIndex: "storeProducts",
              key: "storeProducts",
              width: 100,
            },
            {
              title: "Статус",
              dataIndex: "status",
              key: "status",
              width: 150,
            },
            {
              title: "Действие",
              dataIndex: "action",
              key: "action",
              width: 50,
            },
            {
              title: "Комментарий",
              dataIndex: "comment",
              key: "comment",
            },
          ]}
          dataSource={potentialClients.map((potentialClient) => {
            const status = potentialClient?.techManagerData?.status || "NEW";
            const comment = potentialClient?.techManagerData?.comment || "";

            return {
              ...potentialClient,
              data: potentialClient,
              status: (
                <Select
                  style={{
                    width: 150,
                  }}
                  defaultValue={status}
                  onChange={(v) => {
                    onChange(potentialClient?.storeId, {
                      status: v,
                    });
                  }}
                  options={[
                    {
                      label: "Новый",
                      value: "NEW",
                    },
                    {
                      label: "В работе",
                      value: "IN_PROCESS",
                    },
                    {
                      label: "Уже наш клиент",
                      value: "СLIENT",
                    },
                    {
                      label: "Работает с конкурентами",
                      value: "USE_COMPETITORS",
                    },
                    {
                      label: "Отказ",
                      value: "REFUSED",
                    },
                  ]}
                />
              ),
              comment: (
                <Input.TextArea
                  style={{
                    minWidth: 200,
                  }}
                  defaultValue={comment}
                  onBlur={(v) => {
                    onChange(potentialClient?.storeId, {
                      comment: v.target.value,
                    });
                  }}
                />
              ),
              storeName: (
                <a
                  href={`https://kaspi.kz/shop/nur-sultan/info/merchant/${potentialClient.storeId}/address-tab/?merchantId=${potentialClient.storeId}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {potentialClient?.storeName}
                </a>
              ),
              storeProducts: (
                <a
                  href={`https://kaspi.kz/shop/search/?q=%3AallMerchants%3A${potentialClient?.storeId}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Открыть
                </a>
              ),
              action: (
                <div className="d-flex justify-content-center">
                  <WhatsAppOutlined
                    style={{ fontSize: 20 }}
                    onClick={() =>
                      openWhatsapp(potentialClient?.storePhone || "")
                    }
                  />
                </div>
              ),
            };
          })}
        />
      </div>
    </Card>
  );
};

export default PotentialClient;
