import { Button, Result } from "antd";

const Error = ({ status, title, subTitle }) => {
  console.log(status, title, subTitle);

  return (
    <Result
      status={status}
      title={title}
      subTitle={subTitle}
      extra={
        <Button
          type="primary"
          onClick={() => {
            window.location.href = "/";
          }}
        >
          На главную
        </Button>
      }
    />
  );
};

export default Error;
