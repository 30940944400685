import { Card } from "antd";
import { Column } from "@ant-design/plots";

const RegisteredUserAnalytics = ({ registeredUsers }) => {
  return (
    <Card className="my-3">
      <h1>Зарегистрированные пользователи</h1>
      <Column data={registeredUsers} xField="key" yField="value" />
    </Card>
  );
};

export default RegisteredUserAnalytics;
