import {
  Button,
  Card,
  Form,
  Image,
  Input,
  Pagination,
  Popconfirm,
  Segmented,
  Select,
  Table,
  Tooltip,
  message,
} from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import config from "../../config.json";
import { MappedProductsList } from "./components/mapped-products-list";
import { ProductMappingSimilarityResult } from "./components/kaspi-products-for-mapping";
import { ReloadOutlined, DeleteOutlined } from "@ant-design/icons";
import { GetVariantsRequestHistory } from "./components/get-variants-request-history";
import useIsMobile from "../../hooks/useIsMobile";

const ProductMapping = () => {
  const isMobile = useIsMobile()
  const [kaspiProducts, setKaspiProducts] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const fetch = () => {
    setIsLoading(true);

    const searchParams = new URLSearchParams();
    searchParams.append("query", query);
    searchParams.append("page", page);
    searchParams.append("limit", limit);
    if (selectedCategory !== null) {
      searchParams.append("categoryName", selectedCategory);
    }

    axios
      .get(
        `${
          config.service
        }/api/product-mapping/kaspi-products-for-parsing?${searchParams.toString()}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              "admin_access_token"
            )}`,
          },
        }
      )
      .then((data) => {
        setKaspiProducts(data.data.kaspiProducts);
        setTotalCount(data.data.totalCount);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetchCategories = () => {
    axios
      .get(`${config.service}/api/product-mapping/categories`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
        },
      })
      .then((data) => {
        setCategories(data.data.categories);
      });
  };

  const reloadMapping = (kaspiProductId) => {
    axios
      .post(
        `${config.service}/api/product-mapping`,
        {
          kaspiProductId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              "admin_access_token"
            )}`,
          },
        }
      )
      .then(() => {
        message.success("Маппинг успешно запущен");
      })
      .catch(() => {
        message.error("Не удалось запустить маппинг");
      });
  };

  useEffect(() => {
    fetch();
    fetchCategories();
  }, [limit, page, selectedCategory]);

  return (
    <Card className={isMobile ? 'mx-2' : "mx-3"}>
      <h2>Каспи товары для маппинга</h2>
      <Form onFinish={fetch} className="d-flex align-items-center mb-3">
        <Form.Item className="m-0 me-2">
          <Select
            showSearch
            value={selectedCategory}
            onChange={(v) => setSelectedCategory(v)}
            style={{ width: 300 }}
            options={[
              {
                value: null,
                label: "Все категории",
              },
              ...categories.map((c) => ({
                value: c._id,
                label: `${c.count} - ${c._id}`,
              })),
            ]}
          />
        </Form.Item>
        <Form.Item className="m-0 w-100 me-2">
          <Input
            placeholder="ску/бренд"
            value={query}
            onChange={(v) => setQuery(v.target.value)}
          />
        </Form.Item>
        <Button type="primary" htmlType="submit">
          Поиск
        </Button>
      </Form>
      <div style={{ overflow: "auto" }}>
        <Table
          pagination={false}
          loading={isLoading}
          bordered
          size="small"
          columns={[
            {
              title: "SKU",
              dataIndex: "sku",
            },
            {
              title: "Товар",
              dataIndex: "productName",
            },
            {
              title: "Бренд",
              dataIndex: "brand",
            },
            {
              title: "Категория",
              dataIndex: "categoryName",
            },
            {
              title: "",
              dataIndex: "actions",
              width: 32,
            },
          ]}
          dataSource={kaspiProducts.map((p) => {
            return {
              ...p,
              key: p.sku,
              productName: (
                <div
                  className="d-flex align-items-center"
                  style={{ height: 50 }}
                >
                  <div style={{ width: 32, height: 32 }} className="me-2">
                    <Image
                      style={{
                        width: 32,
                        maxHeight: 32,
                        objectFit: "contain",
                      }}
                      src={p.imageUrl}
                    />
                  </div>
                  <a href={p.productUrl} target="_blank" rel="noreferrer">
                    {p.productName}
                  </a>
                </div>
              ),
              actions: (
                <Tooltip title="Запустить маппинг по товару">
                  <Button
                    onClick={() => reloadMapping(p.sku)}
                    icon={<ReloadOutlined />}
                  />
                </Tooltip>
              ),
            };
          })}
          expandable={{
            expandedRowRender: (record) => {
              return (
                <ProductMappingExpandableContent kaspiProductId={record.sku} />
              );
            },
          }}
        />
      </div>
      <div className="mt-3 d-flex justify-content-center">
        <Pagination
          current={page}
          pageSize={limit}
          total={totalCount}
          onChange={(page) => setPage(page)}
          onShowSizeChange={(_current, size) => setLimit(size)}
        />
      </div>
    </Card>
  );
};

const ProductMappingExpandableContent = ({ kaspiProductId }) => {
  const [mod, setMod] = useState("mapped-products");
  const [isDeleting, setIsDeleting] = useState(false);
  const [count, setCount] = useState(0);

  const options = [
    {
      value: "mapped-products",
      label: "Смапленные товары",
    },
    {
      value: "product-mapping-similarity-result",
      label: "Результаты сравнения",
    },
    {
      value: "get-variants-request-history",
      label: "История запросов",
    },
  ];

  const getBody = () => {
    if (mod === "product-mapping-similarity-result") {
      return <ProductMappingSimilarityResult kaspiProductId={kaspiProductId} />;
    } else if (mod === "get-variants-request-history") {
      return (
        <GetVariantsRequestHistory
          kaspiProductId={kaspiProductId}
          marketplace="KASPI"
        />
      );
    }

    return <MappedProductsList kaspiProductId={kaspiProductId} />;
  };

  const clearHistory = () => {
    setIsDeleting(true);

    axios
      .delete(`${config.service}/api/product-mapping/${kaspiProductId}/clear`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
        },
      })
      .then(() => {
        message.success("История успешно очищена");

        setCount(count + 1);
      })
      .catch(() => {
        message.error("Не удалось очистить историю");
      })
      .finally(() => {
        setIsDeleting(false);
      });
  };

  return (
    <Card>
      <div className="mb-3 d-flex justify-content-end">
        <Popconfirm
          okButtonProps={{
            className: "text-white border-danger bg-danger",
            loading: isDeleting,
          }}
          okText="Очистить"
          title="Вы уверены, что хотите очистить историю?"
          onConfirm={clearHistory}
        >
          <Button
            loading={isDeleting}
            className="text-danger border-danger"
            icon={<DeleteOutlined />}
          >
            Очистить историю
          </Button>
        </Popconfirm>
      </div>

      <Segmented block value={mod} onChange={setMod} options={options} />

      <div className="mt-3">{getBody()}</div>
    </Card>
  );
};

export default ProductMapping;
