import axios from "axios";
import { useEffect, useState } from "react";
import config from "../../../config.json";
import { Button, Card, Checkbox, InputNumber, message } from "antd";

const AnalyticsAccess = ({ client }) => {
  const clientId = client?._id;

  const [analyticsAccess, setAnalyticsAccess] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const fetchData = () => {
    setIsLoading(true);

    axios
      .get(`${config.service}/api/analytics-access/${clientId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
        },
      })
      .then((data) => {
        setAnalyticsAccess(data.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  useEffect(fetchData, [clientId]);

  const updateAnalyticsAccess = () => {
    setIsLoading(true);

    axios
      .patch(
        `${config.service}/api/analytics-access/${clientId}`,
        analyticsAccess,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              "admin_access_token"
            )}`,
          },
        }
      )
      .then(() => {
        message.success("Доступ к аналитике успешно обновлен");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (!client) {
    return <></>;
  }

  const onChange = (data) => {
    setAnalyticsAccess({
      ...analyticsAccess,
      ...data,
    });
  };

  const getFilters = (selectedFilter) => {
    const filters = analyticsAccess?.filters || [];

    const index = filters.indexOf(selectedFilter);
    if (index === -1) {
      filters.push(selectedFilter);
    } else {
      filters.splice(index, 1);
    }

    return filters;
  };

  const isFilterExists = (filter) => {
    const filters = analyticsAccess?.filters || [];

    if (!Array.isArray(filters)) {
      return false;
    }

    const index = filters.findIndex((v) => v === filter);
    if (index >= 0) {
      return true;
    }

    return false;
  };

  const getColumns = (selectedColumn) => {
    const columns = analyticsAccess?.columns || [];

    const index = columns.indexOf(selectedColumn);
    if (index === -1) {
      columns.push(selectedColumn);
    } else {
      columns.splice(index, 1);
    }

    return columns;
  };
  const isColumnExists = (filter) => {
    const columns = analyticsAccess?.columns || [];

    if (!Array.isArray(columns)) {
      return false;
    }

    const index = columns.findIndex((v) => v === filter);
    if (index >= 0) {
      return true;
    }

    return false;
  };
  const getSorts = (selectedSort) => {
    const sorts = analyticsAccess?.sorts || [];

    const index = sorts.indexOf(selectedSort);
    if (index === -1) {
      sorts.push(selectedSort);
    } else {
      sorts.splice(index, 1);
    }

    return sorts;
  };
  const isSortExists = (sort) => {
    const sorts = analyticsAccess?.sorts || [];

    if (!Array.isArray(sorts)) {
      return false;
    }

    const index = sorts.findIndex((v) => v === sort);
    if (index >= 0) {
      return true;
    }

    return false;
  };

  return (
    <Card>
      <Button onClick={fetchData}>Сбросить данные</Button>
      <Card className="mt-3">
        <h3>История</h3>
        <div className="d-flex flex-wrap justify-content-between">
          <Checkbox
            disabled={isLoading}
            checked={analyticsAccess.turnoverHistory}
            onChange={(v) => {
              onChange({
                turnoverHistory: v.target.checked,
              });
            }}
          >
            Оборот
          </Checkbox>
          <Checkbox
            disabled={isLoading}
            checked={analyticsAccess.priceHistory}
            onChange={(v) => {
              onChange({
                priceHistory: v.target.checked,
              });
            }}
          >
            История изменения цены
          </Checkbox>
          <Checkbox
            disabled={isLoading}
            checked={analyticsAccess.sellersHistory}
            onChange={(v) => {
              onChange({
                sellersHistory: v.target.checked,
              });
            }}
          >
            Кол-во конкурентов
          </Checkbox>
          <Checkbox
            disabled={isLoading}
            checked={analyticsAccess.amountOfSellsHistory}
            onChange={(v) => {
              onChange({
                amountOfSellsHistory: v.target.checked,
              });
            }}
          >
            Кол-во продаж
          </Checkbox>
          <Checkbox
            disabled={isLoading}
            checked={analyticsAccess.popularityHistory}
            onChange={(v) => {
              onChange({
                popularityHistory: v.target.checked,
              });
            }}
          >
            История популярности товара
          </Checkbox>
          <Checkbox
            disabled={isLoading}
            checked={analyticsAccess.amountOfReviewsHistory}
            onChange={(v) => {
              onChange({
                amountOfReviewsHistory: v.target.checked,
              });
            }}
          >
            Кол-во отзывов
          </Checkbox>
          <Checkbox
            disabled={isLoading}
            checked={analyticsAccess.ratingHistory}
            onChange={(v) => {
              onChange({
                ratingHistory: v.target.checked,
              });
            }}
          >
            Рейтинг
          </Checkbox>
        </div>
      </Card>
      <Card className="mt-3">
        <h3>Фильтр</h3>
        <div className="d-flex flex-wrap justify-content-between">
          <Checkbox
            disabled={isLoading}
            checked={isFilterExists("price")}
            onChange={(v) => {
              onChange({
                filters: getFilters("price"),
              });
            }}
          >
            Цена
          </Checkbox>
          <Checkbox
            disabled={isLoading}
            checked={isFilterExists("position")}
            onChange={(v) => {
              onChange({
                filters: getFilters("position"),
              });
            }}
          >
            Абсолютная позиция
          </Checkbox>
          <Checkbox
            disabled={isLoading}
            checked={isFilterExists("sellers")}
            onChange={(v) => {
              onChange({
                filters: getFilters("sellers"),
              });
            }}
          >
            Кол-во продавцов
          </Checkbox>
          <Checkbox
            disabled={isLoading}
            checked={isFilterExists("reviews")}
            onChange={(v) => {
              onChange({
                filters: getFilters("reviews"),
              });
            }}
          >
            Кол-во отзывов
          </Checkbox>
          <Checkbox
            disabled={isLoading}
            checked={isFilterExists("amountOfSellsForLast30Days")}
            onChange={(v) => {
              onChange({
                filters: getFilters("amountOfSellsForLast30Days"),
              });
            }}
          >
            Кол-во продаж за последние 30 дней
          </Checkbox>
          <Checkbox
            disabled={isLoading}
            checked={isFilterExists("turnoverForLast30Days")}
            onChange={(v) => {
              onChange({
                filters: getFilters("turnoverForLast30Days"),
              });
            }}
          >
            Оборот за последние 30 дней
          </Checkbox>
          <Checkbox
            disabled={isLoading}
            checked={isFilterExists("rating")}
            onChange={(v) => {
              onChange({
                filters: getFilters("rating"),
              });
            }}
          >
            Рейтинг товара
          </Checkbox>
          <Checkbox
            disabled={isLoading}
            checked={isFilterExists("weight")}
            onChange={(v) => {
              onChange({
                filters: getFilters("weight"),
              });
            }}
          >
            Вес товара
          </Checkbox>
          <Checkbox
            disabled={isLoading}
            checked={analyticsAccess.searchProductByUrl}
            onChange={(v) => {
              onChange({
                searchProductByUrl: v.target.checked,
              });
            }}
          >
            Cсылка на товар
          </Checkbox>
          <Checkbox
            disabled={isLoading}
            checked={analyticsAccess.searchProductsByKeyword}
            onChange={(v) => {
              onChange({
                searchProductsByKeyword: v.target.checked,
              });
            }}
          >
            Включает ключевые слова
          </Checkbox>
        </div>
      </Card>
      <Card className="mt-3">
        <h3>Колонки</h3>
        <div className="d-flex flex-wrap justify-content-between">
          <Checkbox
            disabled={isLoading}
            checked={isColumnExists("image")}
            onChange={(v) => {
              onChange({
                columns: getColumns("image"),
              });
            }}
          >
            Фото
          </Checkbox>
          <Checkbox
            disabled={isLoading}
            checked={isColumnExists("name")}
            onChange={(v) => {
              onChange({
                columns: getColumns("name"),
              });
            }}
          >
            Название
          </Checkbox>
          <Checkbox
            disabled={isLoading}
            checked={isColumnExists("category")}
            onChange={(v) => {
              onChange({
                columns: getColumns("category"),
              });
            }}
          >
            Категория
          </Checkbox>
          <Checkbox
            disabled={isLoading}
            checked={isColumnExists("position")}
            onChange={(v) => {
              onChange({
                columns: getColumns("position"),
              });
            }}
          >
            Абсолютная позиция
          </Checkbox>
          <Checkbox
            disabled={isLoading}
            checked={isColumnExists("price")}
            onChange={(v) => {
              onChange({
                columns: getColumns("price"),
              });
            }}
          >
            Цена
          </Checkbox>
          <Checkbox
            disabled={isLoading}
            checked={isColumnExists("sellers")}
            onChange={(v) => {
              onChange({
                columns: getColumns("sellers"),
              });
            }}
          >
            Продавцы
          </Checkbox>
          <Checkbox
            disabled={isLoading}
            checked={isColumnExists("amountOfSellsForLast30Days")}
            onChange={(v) => {
              onChange({
                columns: getColumns("amountOfSellsForLast30Days"),
              });
            }}
          >
            Кол-во продаж за последние 30 дн.
          </Checkbox>
          <Checkbox
            disabled={isLoading}
            checked={isColumnExists("turnOverForLast30Days")}
            onChange={(v) => {
              onChange({
                columns: getColumns("turnOverForLast30Days"),
              });
            }}
          >
            Оборот за последние 30 дн.
          </Checkbox>
          <Checkbox
            disabled={isLoading}
            checked={isColumnExists("reviews")}
            onChange={(v) => {
              onChange({
                columns: getColumns("reviews"),
              });
            }}
          >
            Отзывы
          </Checkbox>
          <Checkbox
            disabled={isLoading}
            checked={isColumnExists("rating")}
            onChange={(v) => {
              onChange({
                columns: getColumns("rating"),
              });
            }}
          >
            Рейтинг
          </Checkbox>
          <Checkbox
            disabled={isLoading}
            checked={isColumnExists("weight")}
            onChange={(v) => {
              onChange({
                columns: getColumns("weight"),
              });
            }}
          >
            Вес
          </Checkbox>
          <Checkbox
            disabled={isLoading}
            checked={isColumnExists("action")}
            onChange={(v) => {
              onChange({
                columns: getColumns("action"),
              });
            }}
          >
            Действие
          </Checkbox>
        </div>
      </Card>
      <Card className="mt-3">
        <h3>Сортировки</h3>
        <div className="d-flex flex-wrap justify-content-between">
          <Checkbox
            disabled={isLoading}
            checked={isSortExists("position")}
            onChange={(v) => {
              onChange({
                sorts: getSorts("position"),
              });
            }}
          >
            Абсолютная позиция
          </Checkbox>
          <Checkbox
            disabled={isLoading}
            checked={isSortExists("price")}
            onChange={(v) => {
              onChange({
                sorts: getSorts("price"),
              });
            }}
          >
            Цена
          </Checkbox>
          <Checkbox
            disabled={isLoading}
            checked={isSortExists("sellers")}
            onChange={(v) => {
              onChange({
                sorts: getSorts("sellers"),
              });
            }}
          >
            Продавцы
          </Checkbox>
          <Checkbox
            disabled={isLoading}
            checked={isSortExists("amountOfSellsForLast30Days")}
            onChange={(v) => {
              onChange({
                sorts: getSorts("amountOfSellsForLast30Days"),
              });
            }}
          >
            Кол-во продаж за последние 30 дн.
          </Checkbox>
          <Checkbox
            disabled={isLoading}
            checked={isSortExists("turnOverForLast30Days")}
            onChange={(v) => {
              onChange({
                sorts: getSorts("turnOverForLast30Days"),
              });
            }}
          >
            Оборот за последние 30 дн.
          </Checkbox>
          <Checkbox
            disabled={isLoading}
            checked={isSortExists("reviews")}
            onChange={(v) => {
              onChange({
                sorts: getSorts("reviews"),
              });
            }}
          >
            Отзывы
          </Checkbox>
          <Checkbox
            disabled={isLoading}
            checked={isSortExists("rating")}
            onChange={(v) => {
              onChange({
                sorts: getSorts("rating"),
              });
            }}
          >
            Рейтинг
          </Checkbox>
          <Checkbox
            disabled={isLoading}
            checked={isSortExists("weight")}
            onChange={(v) => {
              onChange({
                sorts: getSorts("weight"),
              });
            }}
          >
            Вес
          </Checkbox>
        </div>
      </Card>
      <Card className="mt-3">
        <h3>Вкладки</h3>
        <div className="d-flex flex-wrap justify-content-between">
          <Checkbox
            disabled={isLoading}
            checked={analyticsAccess.calculatorWindow}
            onChange={(v) => {
              onChange({
                calculatorWindow: v.target.checked,
              });
            }}
          >
            Калькулятор
          </Checkbox>
          <Checkbox
            disabled={isLoading}
            checked={analyticsAccess.historyWindow}
            onChange={(v) => {
              onChange({
                historyWindow: v.target.checked,
              });
            }}
          >
            История
          </Checkbox>
        </div>
      </Card>
      <Card className="mt-3">
        <h3>Доп. ограничения</h3>
        <div className="d-flex flex-wrap justify-content-between">
          <Checkbox
            disabled={isLoading}
            checked={analyticsAccess.downloadExcel}
            onChange={(v) => {
              onChange({
                downloadExcel: v.target.checked,
              });
            }}
          >
            Скачать Excel
          </Checkbox>
          <Checkbox
            disabled={isLoading}
            checked={analyticsAccess.saveFilter}
            onChange={(v) => {
              onChange({
                saveFilter: v.target.checked,
              });
            }}
          >
            Cохранить фильтр
          </Checkbox>
          {/* <InputNumber
            disabled={isLoading}
            value={analyticsAccess.productHistoryDays}
            onChange={(v) => {
              onChange({
                productHistoryDays: v,
              });
            }}
          /> */}
        </div>
      </Card>
      <Card className="mt-3">
        <h3>Страницы</h3>
        <div className="d-flex flex-wrap justify-content-between">
          <Checkbox
            disabled={isLoading}
            checked={analyticsAccess.popularProductsPage}
            onChange={(v) => {
              onChange({
                popularProductsPage: v.target.checked,
              });
            }}
          >
            Популярные
          </Checkbox>
        </div>
      </Card>
      <Button className="mt-3" type="primary" onClick={updateAnalyticsAccess}>
        Сохранить
      </Button>
    </Card>
  );
};

export default AnalyticsAccess;
