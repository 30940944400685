import { Button, Popconfirm } from "antd";
import config from "../../../config.json";
import axios from "axios";

const StopGatheringForStoreButton = ({ store, fetch }) => {
  const stopGathering = () => {
    axios
      .post(
        `${config.service}/api/store-product-position-analytics/stores/${store.storeForGatheringProductAnalyticsId}/stop`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              "admin_access_token"
            )}`,
          },
        }
      )
      .then(() => {
        fetch();
      });
  };

  if (!store.analyticsGatheringExpirationDate) {
    return <></>;
  }

  if (new Date(store.analyticsGatheringExpirationDate) < new Date()) {
    return <></>;
  }

  return (
    <Popconfirm
      title={`Вы точно хотите остановить сборку данных для магазина ${
        store.storeName || ""
      }?`}
      okText="Остановить"
      okButtonProps={{
        className: "text-danger border-danger bg-danger text-white",
      }}
      onConfirm={stopGathering}
    >
      <Button className="text-danger border-danger mb-3">
        Остановить сборку данных
      </Button>
    </Popconfirm>
  );
};

export default StopGatheringForStoreButton;
