import { Button, Form, InputNumber, Modal, Select, message } from "antd";
import axios from "axios";
import { useState } from "react";
import config from "../../../config.json";
import { DeliveryIcon } from "../../../assets/icons";

const ActivateReviewsSendModal = ({ store }) => {
  const [open, setOpen] = useState(false);
  const [activationType, setActivationType] = useState("MONTH");
  const [value, setValue] = useState(1);
  const [price, setPrice] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const handleActivate = () => {
    setIsLoading(true);

    const body = {
      price: parseInt(price),
      storeId: store._id,
      userId: store.userId,
    };

    body[activationType.toLowerCase()] = value;

    axios
      .post(`${config.service}/api/payment/activate-reviews-sending`, body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
        },
      })
      .then(() => {
        setOpen(false);
        message.success("Рассылка отзывов по доставке успешно активирован");
      })
      .catch(() => {
        message.error("Не удалось активировать");
      })
      .finally(() => setIsLoading(false));
  };

  const activationTypes = [
    {
      value: "MONTH",
      label: "Месяц",
    },
    {
      value: "WEEK",
      label: "Неделя",
    },
    {
      value: "DAY",
      label: "День",
    },
  ];
  const getActivationTypeLabel = () => {
    const found = activationTypes.find((v) => v.value === activationType);

    if (found) {
      return found.label;
    }

    return "Месяц";
  };

  return (
    <>
      <Button icon={<img src={DeliveryIcon}></img>} type="primary" className="ms-3" onClick={() => setOpen(true)} style={
        {
          display: "flex",
          alignItems: "center"
        }}>
        Активировать рассылку отзывов
      </Button>
      <Modal
        title="Активировать рассылку отзывов"
        open={open}
        onCancel={() => setOpen(false)}
        onOk={handleActivate}
        okButtonProps={{
          loading: isLoading,
        }}
      >
        <div>
          <Form>
            <Form.Item>
              <div>Тип активации:</div>
              <Select
                value={activationType}
                options={activationTypes}
                onChange={(event) => {
                  setActivationType(event);
                }}
              />
            </Form.Item>
            <Form.Item>
              <div>Срок активации ({getActivationTypeLabel()}):</div>
              <InputNumber
                placeholder={"Кол-во"}
                min={0}
                value={value}
                style={{ width: "100%" }}
                onChange={(event) => {
                  setValue(event);
                }}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                }
                parser={(value) => {
                  if (!value) {
                    return "";
                  }

                  return value.replace(/\$\s?|( *)/g, "");
                }}
              />
            </Form.Item>
            <Form.Item>
              <div>Цена:</div>
              <InputNumber
                prefix={
                  <div className="border-right pe-1" style={{ fontSize: 16 }}>
                    ₸
                  </div>
                }
                placeholder="Сумма"
                value={price + ""}
                style={{ width: "100%" }}
                onChange={(event) => {
                  if (event) {
                    event = event + "";
                    setPrice(event);
                  } else {
                    setPrice("0");
                  }
                }}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                }
                parser={(value) => {
                  value = value.toString();

                  if (!value) {
                    return "";
                  }

                  const newValue = value.replace(/\$\s?|( *)/g, "");

                  return newValue;
                }}
              />
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default ActivateReviewsSendModal;
