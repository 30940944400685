import { Button, Card, Spin } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import config from "../../../config.json";
import { ReloadOutlined } from "@ant-design/icons";

const ProxyLogs = ({ data }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    fetchLogs();
  }, [data]);

  const fetchLogs = () => {
    setIsLoading(true);

    axios
      .get(`${config.service}/api/proxy/logs/?proxy=${data.proxy}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
        },
      })
      .then((data) => {
        setLogs(data.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Card>
      <div>
        <Button
          icon={<ReloadOutlined />}
          onClick={fetchLogs}
          loading={isLoading}
          className="mb-3"
          type="primary"
        >
          Обновить данные
        </Button>
        {isLoading && (
          <div
            className="w-100 d-flex justify-content-center align-items-center"
            style={{ height: 200 }}
          >
            <Spin />
          </div>
        )}
        {!isLoading && (
          <>
            {logs.map((log, index) => {
              return (
                <div key={index} className="border-bottom pb-1 mb-1">
                  <div className="text-secondary">
                    {new Date(log.createdAt).toLocaleString()} Статус:{" "}
                    <span
                      style={{
                        color: log.status === 200 ? "green" : "red",
                      }}
                    >
                      <b>{log.status}</b>
                    </span>
                  </div>
                  <div></div>
                </div>
              );
            })}
          </>
        )}
      </div>
    </Card>
  );
};

export default ProxyLogs;
