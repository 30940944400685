import { Button, Card, Descriptions, Empty, Spin, Table } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import config from "../../../config.json";
import cities from "../../../constants/cities.json";
import { currencyFormat } from "../../../scripts/format";

const OutputDemping = ({ product }) => {
  const productId = product.data._id;

  const [histories, setHistories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetch = () => {
    setIsLoading(true);

    axios
      .get(`${config.service}/api/product/${productId}/output-demping`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
        },
      })
      .then((data) => setHistories(data.data))
      .finally(() => setIsLoading(false));
  };
  useEffect(fetch, [productId]);

  if (isLoading) {
    return (
      <div className="d-flex align-items-center justify-content-center my-5">
        <Spin />
      </div>
    );
  }

  if (histories.length === 0) {
    return (
      <div>
        <Button onClick={fetch}>Обновить</Button>
        <div className="d-flex align-items-center justify-content-center my-5">
          <Empty />
        </div>
      </div>
    );
  }

  const getFilters = () => {
    const result = [];

    for (let history of histories) {
      const { cityId } = history;

      const foundIndex = result.findIndex((v) => v.value === cityId);

      if (foundIndex === -1) {
        result.push({
          text: cities[cityId]?.name || "",
          value: cities[cityId]?.id || "",
        });
      }
    }

    return result;
  };

  return (
    <div>
      <Button className="mb-3" onClick={fetch}>
        Обновить
      </Button>
      <div>
        <i>* Данные за последние 24 часа</i>
      </div>
      <Table
        className="mt-3"
        bordered
        size="small"
        columns={[
          {
            title: "Время",
            dataIndex: "time",
            key: "time",
          },
          {
            title: "Город",
            dataIndex: "city",
            key: "city",
            filters: getFilters(),
            filterMode: "tree",
            onFilter: (value, record) => {
              return record.data.cityId.includes(value);
            },
          },
          {
            title: "Цена",
            dataIndex: "price",
            key: "price",
          },
          {
            title: "Статус",
            dataIndex: "status",
            key: "status",
          },
          {
            title: "Позиция",
            dataIndex: "place",
            key: "place",
          },
        ]}
        dataSource={histories.map((history) => {
          const { cityId } = history;
          let city = cities[cityId]?.name || "";

          return {
            key: history._id,
            data: history,
            time: new Date(history.createdAt).toLocaleTimeString(),
            city,
            status: history.message,
            place: history.place,
            price: history?.oldPrice ? currencyFormat(history?.oldPrice) : "",
          };
        })}
        expandable={{
          expandedRowRender: (record) => {
            return <OutputDempingFullInfo data={record.data} />;
          },
        }}
      />
    </div>
  );
};

const OutputDempingFullInfo = ({ data }) => {
  const city = cities[data.cityId] || { name: "", id: "" };
  const notCompeteStores = data.notCompeteStores || [];

  return (
    <Card>
      <Descriptions labelStyle={{color: '#202020'}}column={1}>
        <Descriptions.Item label="Время проверки">
          <b>{new Date(data.createdAt).toLocaleString()}</b>
        </Descriptions.Item>
        <Descriptions.Item label="Город">
          <b>{city?.name}</b>
        </Descriptions.Item>
        <Descriptions.Item label="Мин. цена">
          <b>{currencyFormat(data.availableMinPrice)}</b>
        </Descriptions.Item>
        <Descriptions.Item label="Макс. цена">
          <b>{currencyFormat(data.availableMaxPrice)}</b>
        </Descriptions.Item>
        <Descriptions.Item label="Шаг снижения цены">
          <b>{data.dempingPrice}</b>
        </Descriptions.Item>
        <Descriptions.Item label="Позиция">
          <b>{data.place}</b>
        </Descriptions.Item>
        <Descriptions.Item label="Поставить мин. цену">
          <b>{data.isSetMinPrice ? "Да" : "Нет"}</b>
        </Descriptions.Item>
        <Descriptions.Item label="Ниже мин. цены">
          <b>{data.isMinus ? "Да" : "Нет"}</b>
        </Descriptions.Item>
        <Descriptions.Item label="Цена на каспи в момент проверки">
          <b>{currencyFormat(data.oldPrice)}</b>
        </Descriptions.Item>
        <Descriptions.Item label="Ожидаемая цена на каспи">
          <b>{data.newPrice ? currencyFormat(data.newPrice) : <i>Нет</i>}</b>
        </Descriptions.Item>
        {notCompeteStores.length > 0 && (
          <Descriptions.Item label="Не конкурировать с магазинами">
            <b>
              {[
                ...notCompeteStores.map(
                  (v) => `${v.merchantName} (${v.merchantId})`
                ),
              ].join(", ")}
            </b>
          </Descriptions.Item>
        )}
      </Descriptions>
      <Table
        bordered
        columns={[
          {
            title: "ID",
            key: "merchantId",
            dataIndex: "merchantId",
          },
          {
            title: "Магазин",
            key: "name",
            dataIndex: "name",
          },
          {
            title: "Цена",
            key: "price",
            dataIndex: "price",
          },
        ]}
        dataSource={data.offers.map((offer) => {
          const isNotCompeteStore = notCompeteStores.find(
            (v) => v.merchantId === offer.merchantId
          );

          let className = "text-dark";
          if (offer.merchantId === data.merchantId) {
            className = "text-primary";
          } else if (isNotCompeteStore) {
            className = "text-success";
          }

          return {
            name: <div className={className}>{offer.merchantName}</div>,
            merchantId: offer.merchantId,
            price: currencyFormat(offer.price),
          };
        })}
      />
    </Card>
  );
};

export default OutputDemping;
