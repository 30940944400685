import axios from "axios";
import { useEffect, useState } from "react";
import config from "../../../config.json";
import { Button, Table } from "antd";
import { ReloadOutlined } from "@ant-design/icons";

const ProductMerchantCity = ({ city, masterProductSku, step }) => {
  const [productMerchant, setProductMerchant] = useState(null);

  useEffect(() => {
    fetchProductMerchant();
  }, [city.cityId, masterProductSku, step]);
  const fetchProductMerchant = () => {
    axios
      .get(
        `${config.service}/api/product/product-merchant/${masterProductSku}/${city.cityId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              "admin_access_token"
            )}`,
          },
        }
      )
      .then((data) => {
        setProductMerchant(data.data);
      });
  };

  const resetProductMerchant = () => {
    axios.patch(
      `${config.service}/api/product/product-merchant/${masterProductSku}/${city.cityId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
        },
      }
    );
  };

  if (!productMerchant) {
    return <></>;
  }

  return (
    <Table
      bordered={true}
      pagination={false}
      showHeader={false}
      columns={[
        {
          dataIndex: "cityName",
        },
        {
          dataIndex: "cityId",
        },
        {
          dataIndex: "updatePeriod",
        },
        {
          dataIndex: "isActive",
        },
        {
          dataIndex: "nextUpdateDate",
        },
        {
          dataIndex: "reset",
          width: 50,
        },
      ]}
      dataSource={[
        {
          cityName: <div style={{ width: 90 }}>{city.cityName}</div>,
          cityId: <div style={{ width: 85 }}>{city.cityId}</div>,
          updatePeriod: (
            <div style={{ width: 50 }}>{productMerchant.updatePeriod} мин</div>
          ),
          nextUpdateDate: (
            <div>
              {new Date(productMerchant._nextUpdateDate).toLocaleString()}
            </div>
          ),
          reset: (
            <Button
              icon={<ReloadOutlined />}
              onClick={resetProductMerchant}
            ></Button>
          ),
          isActive: (
            <div style={{ width: 75 }}>
              {productMerchant?.isActive ? "Активный" : "Нет"}
            </div>
          ),
        },
      ]}
    />
  );
};

export default ProductMerchantCity;
