import { Button, Modal, Segmented } from "antd";
import { useState } from "react";
import DailyAnalyticsHistory from "./DailyAnalyticsHistory";
import DailyAnalyticsActivation from "./DailyAnalyticsActivation";

const DailyAnalyticsModal = ({ storeId }) => {
  const [open, setOpen] = useState(false);
  const [mod, setMod] = useState("activation");

  const getContent = () => {
    if (mod === "history") {
      return <DailyAnalyticsHistory storeId={storeId} />;
    }

    return <DailyAnalyticsActivation storeId={storeId} />;
  };

  return (
    <>
      <Button type="primary" onClick={() => setOpen(true)}>
        Ежедневная аналитика 2-х товаров
      </Button>
      <Modal
        open={open}
        onCancel={() => setOpen(false)}
        title="Ежедневная аналитика 2-х продаваемых товаров"
        okButtonProps={{ style: { display: "none" } }}
        cancelText="Закрыть"
      >
        <Segmented
          value={mod}
          onChange={setMod}
          block
          options={[
            {
              label: "Активация",
              value: "activation",
            },
            {
              label: "История",
              value: "history",
            },
          ]}
        />
        <div className="mt-3">{getContent()}</div>
      </Modal>
    </>
  );
};

export default DailyAnalyticsModal;
