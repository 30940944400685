import { Card, Descriptions } from "antd";

export const ProductStoreInfo = ({ storeInfo,store }) => {
  const getBoolName = (bool) => {
    if (bool) {
      return "Да";
    } else {
      return "Нет";
    }
  };

  if (!storeInfo.name) {
    return <></>;
  }

  return (
    <Descriptions labelStyle={{color: '#202020'}}column={1}>
      <Descriptions.Item label="Название магазина">
        <b>{storeInfo?.name || " - "}</b>
      </Descriptions.Item>
      <Descriptions.Item label="Логин">
        <b>{storeInfo?.login || ""}</b>
      </Descriptions.Item>
      <Descriptions.Item label="Пароль">
        <b>{storeInfo?.password || ""}</b>
      </Descriptions.Item>
      <Descriptions.Item label = "Номер телефона">
        <b>{store?.user?.email || ""}</b>
      </Descriptions.Item>
      <Descriptions.Item label="Главный город">
        <b>{storeInfo?.mainCity?.name || " - "}</b>
      </Descriptions.Item>
      <Descriptions.Item label="Метод ценообразования">
        <b>{storeInfo?.changePriceMethod || " - "}</b>
      </Descriptions.Item>
      <Descriptions.Item label="Активен до">
        <b>{new Date(storeInfo?.expireDate).toLocaleString()}</b>
      </Descriptions.Item>
      <Descriptions.Item label="Запущен">
        <b>{getBoolName(storeInfo?.isStarted) || " - "}</b>
      </Descriptions.Item>
      <Descriptions.Item label="Дата последней авторизации">
        <b>
          {storeInfo?.techData?.lastAuthDate
            ? new Date(storeInfo?.techData?.lastAuthDate).toLocaleString()
            : ""}
        </b>
      </Descriptions.Item>
    </Descriptions>
  );
};
