import { DatePicker } from "antd";
import { useState } from "react";
import { EditOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";

const ChangeExpireDate = ({ proxy, updateProxy }) => {
  const [expireDate, setExpireDate] = useState(proxy.expireDate);
  const [isChanging, setIsChanging] = useState(false);

  const dateFormat = "DD.MM.YYYY";

  const handleSubmit = () => {
    if (!expireDate) {
      return;
    }

    setIsChanging(false);

    updateProxy(proxy._id, {
      expireDate,
    });
  };

  if (isChanging) {
    return (
      <div className="d-flex align-items-center">
        <DatePicker
          format={dateFormat}
          style={{
            width: 130,
          }}
          onChange={(v) => {
            setExpireDate(v.toDate());
          }}
        />
        <div
          className="ms-2 cursor-pointer text-success border rounded border-success px-2"
          onClick={handleSubmit}
        >
          <CheckOutlined />
        </div>
        <div className="ms-2 cursor-pointer text-danger border rounded border-danger px-2">
          <CloseOutlined onClick={() => setIsChanging(false)} />
        </div>
      </div>
    );
  }

  return (
    <div className="d-flex align-items-center">
      {expireDate && new Date(expireDate).toLocaleDateString()}
      <EditOutlined
        className="ms-2 cursor-pointer"
        onClick={() => setIsChanging(true)}
      />
    </div>
  );
};

export default ChangeExpireDate;
