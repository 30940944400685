import { Button, Select, Table } from "antd";
import cities from "../../../constants/cities.json";
import { useEffect, useState } from "react";
import axios from "axios";
import config from "../../../config.json";

export const ProductRemains = ({ product }) => {
  const columns = [
    {
      title: "Город",
      dataIndex: "cityName",
      key: "cityName",
    },
    {
      title: "Склад",
      dataIndex: "warehouseName",
      key: "warehouseName",
    },
    {
      title: "Остаток",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Предзаказ",
      dataIndex: "preOrder",
      key: "preOrder",
    },
    {
      title: "Наличие",
      dataIndex: "available",
      key: "available",
    },
  ];

  const [productAvailabilities, setProductAvailabilities] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("ALL");
  const [dataSource, setDataSource] = useState([]);

  const productId = product._id;

  useEffect(() => {
    fetch();
  }, [productId]);

  const fetch = () => {
    setIsLoading(true);

    axios
      .get(`${config.service}/api/product/${productId}/availabilities`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
        },
      })
      .then((data) => setProductAvailabilities(data.data))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    const result = [];

    for (const warehouse of productAvailabilities) {
      if (selectedStatus !== "ALL" && selectedStatus !== warehouse.status) {
        continue;
      }

      result.push({
        cityName: warehouse.cityName,
        warehouseName: (
          <div className="d-flex align-items-center">
            <div className="me-2">{warehouse.displayName}</div>
            {warehouse.status === "ACTIVE" ? (
              <div className="text-success">Активный</div>
            ) : (
              <div className="text-danger">Отключен</div>
            )}
          </div>
        ),
        amount: warehouse.amount,
        preOrder: warehouse.preOrder,
        available: warehouse.available ? "Есть" : "Нет",
      });
    }

    setDataSource(result);
  }, [productId, selectedStatus, productAvailabilities]);

  return (
    <div>
      <div className="mb-3">
        <Button onClick={fetch} className="me-3">
          Обновить
        </Button>
        <Select
          style={{ width: 250 }}
          value={selectedStatus}
          onChange={setSelectedStatus}
          options={[
            {
              value: "ALL",
              label: "Все",
            },
            {
              value: "INACTIVE",
              label: "Отключен",
            },
            {
              value: "ACTIVE",
              label: "Активный",
            },
          ]}
        />
      </div>
      <Table
        loading={isLoading}
        size="small"
        bordered
        columns={columns}
        dataSource={dataSource}
        pagination={false}
      />
    </div>
  );
};
