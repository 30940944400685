import { Button, Image, Table, message } from "antd";
import { useEffect, useState } from "react";
import config from "../../../config.json";
import axios from "axios";
import { DeleteOutlined } from "@ant-design/icons";

export const ProductMappingSimilarityResult = ({ kaspiProductId }) => {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const fetch = () => {
    setIsLoading(true);

    const url = `${config.service}/api/product-mapping/${kaspiProductId}/product-mapping-similarity-result`;

    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
        },
      })
      .then((data) => {
        setProducts(data.data.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetch();
  }, [kaspiProductId]);

  const deleteMapping = async (id) => {
    const url = `${config.service}/api/product-mapping/${id}/similarity-result`;

    await axios
      .delete(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
        },
      })
      .then(() => {
        message.success("Маппинг успешно удален");
      })
      .catch(() => {
        message.error("Не удалось удалить маппинг");
      });
  };

  const clear = async () => {
    setIsDeleting(true);

    const promises = [];
    for (const product of products) {
      promises.push(deleteMapping(product._id));
    }

    try {
      await Promise.all(promises);

      message.success("Маппинги успешно удалены");

      fetch();
    } catch (e) {
      message.error("Не удалось удалить маппинги");
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <div>
      <div className="mb-3 d-flex align-items-center justify-content-between">
        <Button loading={isLoading} onClick={fetch}>
          Обновить
        </Button>

        <Button
          className="text-danger border-danger"
          onClick={clear}
          loading={isDeleting}
        >
          Очистить
        </Button>
      </div>
      <Table
        loading={isLoading}
        bordered
        size="small"
        columns={[
          {
            title: "",
            dataIndex: "createdAt",
            width: 75,
          },
          {
            title: "",
            dataIndex: "marketplaceLogo",
            width: 25,
          },
          {
            title: "Товар",
            dataIndex: "productName",
          },
          {
            title: "Бренд",
            dataIndex: "brand",
          },
          {
            title: "GPT модель",
            dataIndex: "gptModel",
          },
          {
            title: "source",
            dataIndex: "source",
          },
          {
            title: "",
            dataIndex: "similarity",
            width: 75,
          },
          {
            title: "",
            dataIndex: "delete",
            width: 30,
          },
        ]}
        dataSource={products.map((product) => {
          let marketplaceLogoUrl = "";
          if (product.marketplace.toLowerCase() === "ozon") {
            marketplaceLogoUrl =
              "https://api.foxyprice.me/api/images/marketplace/ozon.png";
          } else if (product.marketplace.toLowerCase() === "wildberries") {
            marketplaceLogoUrl =
              "https://api.foxyprice.me/api/images/marketplace/wildberries.png";
          }

          return {
            marketplaceLogo: (
              <img style={{ width: 25 }} src={marketplaceLogoUrl} alt="" />
            ),
            productName: (
              <div className="d-flex align-items-center" style={{ height: 50 }}>
                <div style={{ width: 25, height: 25 }} className="me-3">
                  <Image
                    style={{
                      width: 25,
                      maxHeight: 25,
                      objectFit: "contain",
                    }}
                    src={product.product.imageUrl}
                  />
                </div>
                <a
                  href={product.product.productUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  <div
                    dangerouslySetInnerHTML={{ __html: product.product.name }}
                  />
                </a>
              </div>
            ),
            similarity: `${product.similarity}%`,
            brand: product.product.brand,
            delete: (
              <Button
                className="text-danger border-danger"
                icon={<DeleteOutlined />}
                onClick={() =>
                  deleteMapping(product._id).then(() => {
                    fetch();
                  })
                }
              />
            ),
            gptModel: product.gptModel,
            source: product.source,
            createdAt: new Date(product.createdAt).toLocaleString(),
          };
        })}
      />
    </div>
  );
};
