export function formatPhoneNumber(phoneNumber) {
    if (!phoneNumber) {
        return ''; 
    }
    if (typeof phoneNumber !== 'string') {
        return '';
    }
    if (phoneNumber.length < 11) {
        return ''; 
    }
    const countryCode = phoneNumber.slice(0, 1);
    const firstPart = phoneNumber.slice(1, 4);
    const secondPart = phoneNumber.slice(4, 7);
    const thirdPart = phoneNumber.slice(7, 9);
    const fourthPart = phoneNumber.slice(9);
    const formattedPhoneNumber = `+${countryCode} (${firstPart}) ${secondPart}-${thirdPart}-${fourthPart}`;
    return formattedPhoneNumber;
}

export function formatDate(dateTime){
    const formatDate = new Date(dateTime);
    const today = new Date();
    today.setHours(0, 0, 0, 0); 
    
    if (formatDate < today && formatDate >= new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000)) {
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const dayOfWeek = days[formatDate.getDay()];
        return dayOfWeek
    } else if (formatDate < new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000)) {
        const formattedDate = `${formatDate.getDate()}-${formatDate.getMonth() + 1}-${formatDate.getFullYear()}`;
        return formattedDate
    } else {
        const formattedTime = formatDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
        return formattedTime
    }
}
