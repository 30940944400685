import { Button, Card, Pagination, Table } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import config from "../../../config.json";
import { currencyFormat } from "../../../scripts/format";
import { ReloadOutlined } from "@ant-design/icons";

const ProductPositionHistory = ({ store, startDate, endDate, masterSku }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetch = () => {
    setIsLoading(true);

    axios
      .get(
        `${config.service}/api/store-product-position-analytics/${store.storeForGatheringProductAnalyticsId}/product-position-history?masterSku=${masterSku}&limit=${limit}&page=${page}&startDate=${startDate}&endDate=${endDate}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              "admin_access_token"
            )}`,
          },
        }
      )
      .then((data) => {
        setData(data.data.data);
        setTotalCount(data.data.totalCount);
      })
      .finally(() => setIsLoading(false));
  };
  useEffect(() => {
    fetch();
  }, [store, startDate, endDate, masterSku, page, limit]);

  return (
    <Card>
      <h3>История позиции товара</h3>
      <Button
        className="m-3"
        onClick={() => fetch()}
        type="primary"
        ghost
        icon={<ReloadOutlined />}
        loading={isLoading}
      >
        Обновить
      </Button>
      <Table
        loading={isLoading}
        columns={[
          {
            dataIndex: "position",
            width: 100,
            title: "Позиция",
            align: "center",
          },
          {
            dataIndex: "date",
            title: "Дата",
            width: 175,
          },
          {
            dataIndex: "firstPlaceMerchantName",
            title: "Магазин на 1 месте",
            width: 200,
          },
          {
            dataIndex: "firstPlacePrice",
            title: "Цена на 1 месте",
          },
          {
            dataIndex: "price",
            title: "Цена магазина",
          },
          {
            dataIndex: "belowMinPrice",
            title: "Ниже мин. цены",
          },
        ]}
        dataSource={data.map((d) => {
          return {
            key: d._id,
            position: d.position,
            date: new Date(d.createdAt).toLocaleString("ru-RU", {
              timeZone: "Asia/Almaty",
            }),
            firstPlaceMerchantName: d.firstPlaceMerchantName || "",
            firstPlacePrice: currencyFormat(d.firstPlacePrice || 0),
            price: currencyFormat(d.price || 0),
            belowMinPrice: d.isMinus ? "Да" : "Нет",
          };
        })}
        pagination={false}
        size="small"
        bordered
      />
      <Pagination
        disabled={isLoading}
        className="mt-3"
        total={totalCount}
        pageSize={limit}
        onChange={(page, _pageSize) => {
          setPage(page);
        }}
        onShowSizeChange={(_current, size) => {
          setLimit(size);
        }}
        pageSizeOptions={[20, 50, 100, 500]}
      />
    </Card>
  );
};

export default ProductPositionHistory;
