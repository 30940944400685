import { Button, Card, Spin, Statistic } from "antd";
import axios from "axios";
import config from "../../../config.json";
import { useEffect, useState } from "react";
import { ReloadOutlined } from "@ant-design/icons";

const AverageFirstPlacePercentage = ({
  store,
  startDate,
  endDate,
  masterSku,
}) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetch = () => {
    setIsLoading(true);

    axios
      .get(
        `${config.service}/api/store-product-position-analytics/${
          store.storeForGatheringProductAnalyticsId
        }/average-first-place-percentage?startDate=${startDate}&endDate=${endDate}&masterSku=${
          masterSku || ""
        }`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              "admin_access_token"
            )}`,
          },
        }
      )
      .then((data) => setData(data.data))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    fetch();
  }, [store, startDate, endDate, masterSku]);

  return (
    <Card className="my-3">
      <h3>Средний процент товаров на первом месте за выбранный период</h3>
      <Button
        className="my-3"
        onClick={() => fetch()}
        type="primary"
        ghost
        icon={<ReloadOutlined />}
        loading={isLoading}
      >
        Обновить
      </Button>
      {!isLoading && (
        <Statistic
          value={`${Math.floor(data.averageFirstPlacePercentage || 0)}%`}
        />
      )}
      {isLoading && (
        <div className="my-3">
          <Spin />
        </div>
      )}
    </Card>
  );
};

export default AverageFirstPlacePercentage;
