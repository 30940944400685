import { Button, Card, Descriptions, Table } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import config from "../../../config.json";
import { clearToken } from "../../../scripts/token";
import { ReloadOutlined } from "@ant-design/icons";
import { currencyFormat } from "../../../scripts/format";
import { isArray, isBoolean, isNumber } from "class-validator";

const ProductChangeHistory = ({ product }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [actions, setActions] = useState([]);

  const productId = product.id;

  useEffect(() => {
    fetchLastChanges();
  }, [productId]);

  const fetchLastChanges = () => {
    setIsLoading(true);

    axios
      .get(`${config.service}/api/action/${productId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
        },
      })
      .then((data) => {
        setActions(data.data);
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          clearToken();
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div>
      <Button
        loading={isLoading}
        className="mb-3"
        onClick={fetchLastChanges}
        icon={<ReloadOutlined />}
      >
        Обновить
      </Button>
      <Table
        bordered
        size="small"
        loading={isLoading}
        columns={[
          {
            title: "Дата",
            dataIndex: "date",
            key: "date",
          },
          {
            title: "Время",
            dataIndex: "time",
            key: "time",
          },
          {
            title: "Мин. цена",
            dataIndex: "minPrice",
            key: "minPrice",
          },
          {
            title: "Автоснижение",
            dataIndex: "demping",
            key: "demping",
          },
        ]}
        dataSource={actions.map((action) => {
          const createdAt = new Date(action.createdAt);

          return {
            data: action,
            key: action._id,
            date: createdAt.toLocaleDateString(),
            time: createdAt.toLocaleTimeString(),
            minPrice: isNumber(action.newData.availableMinPrice)
              ? currencyFormat(action.newData.availableMinPrice)
              : "",
            demping: isBoolean(action.newData.isDemping)
              ? action.newData.isDemping
                ? "Вкл"
                : "Выкл"
              : "",
          };
        })}
        expandable={{
          expandedRowRender: (record) => {
            return <ProductChangeHistoryFullInfo data={record.data.newData} />;
          },
        }}
      />
    </div>
  );
};

const ProductChangeHistoryFullInfo = ({ data }) => {
  console.log(Array.isArray(data.productWarehouses));

  return (
    <Card>
      <div>
        <Descriptions labelStyle={{color: '#202020'}}column={1}>
          {isNumber(data.availableMinPrice) && (
            <Descriptions.Item label="Мин. цена">
              <b>{currencyFormat(data.availableMinPrice)}</b>
            </Descriptions.Item>
          )}
          {isNumber(data.availableMaxPrice) && (
            <Descriptions.Item label="Макс. цена">
              <b>{currencyFormat(data.availableMaxPrice)}</b>
            </Descriptions.Item>
          )}
          {isNumber(data.dempingPrice) && (
            <Descriptions.Item label="Шаг снижения цены">
              <b>{currencyFormat(data.dempingPrice)}</b>
            </Descriptions.Item>
          )}
          {isNumber(data.purchasePrice) && (
            <Descriptions.Item label="Цена закупа">
              <b>{currencyFormat(data.purchasePrice)}</b>
            </Descriptions.Item>
          )}
          {isBoolean(data.isDemping) && (
            <Descriptions.Item label="Автоснижение">
              <b>{data.isDemping ? "Вкл." : "Выкл."}</b>
            </Descriptions.Item>
          )}
          {isBoolean(data.isAutoRaise) && (
            <Descriptions.Item label="Автоподнятие">
              <b>{data.isAutoRaise ? "Вкл." : "Выкл."}</b>
            </Descriptions.Item>
          )}
          {isBoolean(data.isDempingOnlyMainCity) && (
            <Descriptions.Item label="Менять цену только в главном городе">
              <b>{data.isDempingOnlyMainCity ? "Вкл." : "Выкл."}</b>
            </Descriptions.Item>
          )}
          {isBoolean(data.isUpdateEveryWeek) && (
            <Descriptions.Item label="Обновлять каждые 7 дней">
              <b>{data.isUpdateEveryWeek ? "Вкл." : "Выкл."}</b>
            </Descriptions.Item>
          )}
          {isBoolean(data.autoacceptOrder) && (
            <Descriptions.Item label="Автопринятие заказа">
              <b>{data.autoacceptOrder ? "Вкл." : "Выкл."}</b>
            </Descriptions.Item>
          )}
          {isBoolean(data.isWithdrawFromSale) && (
            <Descriptions.Item label="Снять с продажи">
              <b>{data.autoacceptOrder ? "Вкл." : "Выкл."}</b>
            </Descriptions.Item>
          )}
        </Descriptions>
        {Array.isArray(data.productWarehouses) && (
          <div className="p-2 border rounded">
            <h3 className="mb-3 mt-2">Пункты самовывоза</h3>
            <Table
              bordered
              pagination={false}
              size="small"
              columns={[
                {
                  title: "Склад",
                  key: "warehouse",
                  dataIndex: "warehouse",
                },
                {
                  title: "Наличие",
                  key: "available",
                  dataIndex: "available",
                },
                {
                  title: "Предзаказ",
                  key: "preOrder",
                  dataIndex: "preOrder",
                },
                {
                  title: "Остаток",
                  key: "amount",
                  dataIndex: "amount",
                },
              ]}
              dataSource={data.productWarehouses.map((data) => {
                return {
                  warehouse: data.warehouseDisplayName,
                  available: isBoolean(data.available)
                    ? data.available
                      ? "Есть"
                      : "Нет"
                    : "",
                  preOrder: isNumber(data.preOrder) ? data.preOrder : "",
                  amount: isNumber(data.amount) ? data.amount : "",
                };
              })}
            />
          </div>
        )}
      </div>
    </Card>
  );
};

export default ProductChangeHistory;
