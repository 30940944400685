import { useState } from "react";
import { Error } from "../Error";
import { UtmStatistics } from "./components";
import useIsMobile from "../../hooks/useIsMobile";

const Dashboard = () => {
  const isMobile = useIsMobile()
  const [error, setError] = useState({
    isError: false,
    status: 0,
    title: "",
    subTitle: "",
  });

  if (error.isError) {
    return <Error {...error} />;
  }

  return (
    <div
      className={isMobile ? 'mx-2' : 'mx-3'}
      style={{
        marginBottom: 30,
      }}
    >
      <UtmStatistics setError={setError} />
    </div>
  );
};

export default Dashboard;
