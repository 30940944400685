export const currencyFormat = (num) => {
  try {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "decimal",
      minimumFractionDigits: 0,
    });
    return formatter.format(num).replace(/,/g, " ");
  } catch (e) {
    console.log(e);
    return num;
  }
};

export const getStartDate = (day = "today") => {
  if (day === "yesterday") {
    return new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() - 1,
      0,
      0,
      0,
      0
    );
  }

  return new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate(),
    0,
    0,
    0,
    0
  );
};

export const getEndDate = (day = "today") => {
  if (day === "yesterday") {
    return new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() - 1,
      23,
      59,
      59,
      999
    );
  }

  return new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate(),
    23,
    59,
    59,
    999
  );
};

export const getFormattedDateString = (milliseconds) => {
  const seconds = Math.floor(milliseconds / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  const secondsLeft = seconds % 60;
  const minutesLeft = minutes % 60;
  const hoursLeft = hours % 24;

  let result = "";
  if (days > 0) {
    result += `${days} дн. `;
  }
  if (hoursLeft > 0) {
    result += `${hoursLeft} ч. `;
  }
  if (minutesLeft > 0) {
    result += `${minutesLeft} мин. `;
  }
  if (secondsLeft > 0) {
    result += `${secondsLeft} с.`;
  }

  return result;
};
