import { Layout, Spin } from "antd";
import { Route, BrowserRouter, Routes as Switch } from "react-router-dom";
import Sidebar from "./components/Sidebar/Sidebar";
import Client from "./pages/Client/Client";
import ClientInfo from "./pages/ClientInfo/ClientInfo";
import { Dashboard } from "./pages/Dashboard";
import Login from "./pages/Login/Login";
import StoreInfo from "./pages/StoreInfo/StoreInfo";
import { StoreProducts } from "./pages/StoreProducts";
import { Stores } from "./pages/Stores/";
import { Home } from "./pages/Home";
import { Budget } from "./pages/Budget";
import { useEffect, useState } from "react";
import axios from "axios";
import config from "./config.json";
import Proxy from "./pages/Proxy/Proxy";
import { PotentialClient } from "./pages/PotentialClient";
import { Notification } from "./pages/Notification";
import { StoreOrders } from "./pages/StoreOrders";
import Analytics from "./pages/Analytics/Analytics";
import SaleScoutGuide from "./pages/SaleScoutGuide/SaleScoutGuide";
import StorePriceChangeMethodsGuide from "./pages/SaleScoutGuide/StorePriceChangeMethodsGuide";
import StoreProductPositionAnalytics from "./pages/StoreProductPositionAnalytics/StoreProductPositionAnalytics";
import { SystemHealthDashboard } from "./pages/SystemHealthDashboard/SystemHealthDashboard";
import ProductMapping from "./pages/ProductMapping";
import { Chat } from "./pages/Chat";
import { Auction } from "./pages/Auction";
import MobileMenu from "./components/MobileMenu/MobileMenu";

const Routes = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState();
  const [isMobile,setIsMobile] = useState(window.innerWidth <= 820)

  useEffect(() => {
    const handleResize = () => {
      if(window.innerWidth <= 820){
        setIsMobile(true)
      }else{
        setIsMobile(false)
      }
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  },[])

  useEffect(() => {
    if (!localStorage.getItem("admin_access_token")) {
      setIsLoading(false);
      return;
    }

    axios
      .get(`${config.service}/api/user`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
        },
      })
      .then((data) => {
        localStorage.setItem("role", data.data?.role);
        localStorage.setItem("chatRole", data.data?.chatRole);
        if (data.data?.chatRole == "ADMIN") {
          localStorage.setItem("adminId", "");
        } else {
          localStorage.setItem("adminId", data.data?._id);
        }

        setTimeout(() => {
          setUser(data.data);
        }, 750);
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          localStorage.removeItem("admin_access_token");

          window.location.reload();
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  if (!localStorage.getItem("admin_access_token")) {
    return <Login />;
  }

  if (isLoading) {
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  // const getDefaultNavigate = () => {
  // if (user?.role === "SALES_DEPARTMENT") {
  //   return "/potential-client";
  // } else if (user?.role === "TARGETOLOGIST") {
  //   return "/dashboard";
  // } else if (user?.role === "DEVELOPER") {
  //   return "/proxy";
  // }

  //   return "/";
  // };

  console.log(user?.role, "role");

  return (
    <>
      <Layout className="routes-layout">
        <BrowserRouter>
          {isMobile
            ?
              <MobileMenu/>
            :
              <Sidebar />
          }
      
          <Layout
            className="site-layout"
            style={{
              paddingTop: 30,
              minHeight: "100vh",
              minWidth: 340,
              overflow: "auto",
            }}
          >
            <Switch>
              {(user?.role === "SUPER_ADMIN" ||
                user?.role === "MANAGER" ||
                user?.role === "DEVELOPER") && (
                <>
                  <Route path="store">
                    <Route path=":id">
                      <Route
                        path="products"
                        element={<StoreProducts />}
                      ></Route>
                      <Route path="order" element={<StoreOrders />}></Route>
                      <Route index element={<StoreInfo />}></Route>
                    </Route>
                    <Route index element={<Stores />}></Route>
                  </Route>
                  <Route path="client">
                    <Route path=":id" element={<ClientInfo />}></Route>
                    <Route index element={<Client />}></Route>
                  </Route>
                  <Route path="notification">
                    <Route index element={<Notification />}></Route>
                  </Route>
                  <Route path="chat">
                    <Route index element={<Chat />}></Route>
                  </Route>
                  <Route path="auction">
                    <Route index element={<Auction />}></Route>
                  </Route>
                  <Route path="store-product-position-analytics">
                    <Route
                      index
                      element={<StoreProductPositionAnalytics />}
                    ></Route>
                  </Route>
                </>
              )}

              {(user?.role === "SUPER_ADMIN" ||
                user?.role === "MANAGER" ||
                user?.role === "MARKETING_MANAGER") && (
                <Route index element={<Home />}></Route>
              )}

              {(user?.role === "SUPER_ADMIN" ||
                user?.role === "TARGETOLOGIST" ||
                user?.role === "MARKETING_MANAGER") && (
                <Route path="dashboard">
                  <Route index element={<Dashboard />}></Route>
                </Route>
              )}

              {(user?.role === "SUPER_ADMIN" ||
                user?.role === "INTERN_DEVELOPER" ||
                user?.role === "DEVELOPER") && (
                <>
                  <Route path="proxy" element={<Proxy />}></Route>
                </>
              )}

              {(user?.role === "SUPER_ADMIN" ||
                user?.role === "MARKETING_MANAGER") && (
                <Route path="analytics" element={<Analytics />}></Route>
              )}

              {(user?.role === "SUPER_ADMIN" ||
                user?.role === "MANAGER" ||
                user?.role === "MARKETING_MANAGER") && (
                <>
                  <Route path="budget">
                    <Route index element={<Budget />}></Route>
                  </Route>
                </>
              )}

              {user?.role === "SUPER_ADMIN" && (
                <>
                  <Route path="product-mapping" element={<ProductMapping />} />
                </>
              )}

              {(user?.role === "MANAGER" ||
                user?.role === "SALES_DEPARTMENT" ||
                user?.role === "SUPER_ADMIN") && (
                <>
                  <Route path="potential-client">
                    <Route index element={<PotentialClient />}></Route>
                  </Route>
                </>
              )}
              <Route path="guide">
                <Route path="store">
                  <Route
                    path="price-change-methods"
                    element={<StorePriceChangeMethodsGuide />}
                  ></Route>
                </Route>
                <Route index element={<SaleScoutGuide />}></Route>
              </Route>
              <Route path="system-health-dashboard">
                <Route index element={<SystemHealthDashboard />}></Route>
              </Route>
              {/* <Route
                path="*"
                element={<Navigate to={`${getDefaultNavigate()}`} />}
              ></Route> */}
            </Switch>
          </Layout>
        </BrowserRouter>
      </Layout>
    </>
  );
};

export default Routes;
