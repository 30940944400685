import { Button, message, Modal } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import { useState } from "react";
import axios from "axios";
import config from "../../../config.json";

const ResetStoreActivation = ({ store }) => {
  const [visble, setVisible] = useState(false);
  const close = () => setVisible(false);
  const open = () => setVisible(true);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = () => {
    setIsLoading(true);

    axios
      .post(
        `${config.service}/api/store/${store._id}/reset-activation`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              "admin_access_token"
            )}`,
          },
        }
      )
      .then(() => {
        message.success("Активация магазина успешно сброшена");
      })
      .catch(() => {
        message.error("Не удалось сбросить активацию");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <Button
        style={{ width: 200 }}
        icon={<ReloadOutlined />}
        onClick={open}
        className="mb-2 border-danger bg-danger text-white"
      >
        Сбросить активацию
      </Button>
      <Modal
        visible={visble}
        onCancel={close}
        onOk={handleSubmit}
        title="Внимание!"
        okButtonProps={{
          loading: isLoading,
          className: "bg-danger border-danger text-white",
        }}
        okText="Cбросить"
        cancelText="Отменить"
      >
        <p className="p-0 m-0">
          Вы точно хотите сбросить активацию магазина: <b>{store.name}</b>?
        </p>
      </Modal>
    </>
  );
};

export default ResetStoreActivation;
