import { Button, Descriptions, Modal, Table } from "antd";
import { useEffect, useState } from "react";
import config from "../../../config.json";
import axios from "axios";
import { ApiOutlined } from "@ant-design/icons";

export const GetVariantsRequestHistory = ({ kaspiProductId, marketplace }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [requests, setRequests] = useState([]);

  const fetch = () => {
    setIsLoading(true);
    const url = `${config.service}/api/product-mapping/request-history?kaspiProductId=${kaspiProductId}&marketplace=${marketplace}`;

    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
        },
      })
      .then((data) => {
        setRequests(data.data.data);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    fetch();
  }, [kaspiProductId, marketplace]);

  return (
    <div>
      <Button className="mb-3" onClick={fetch} loading={isLoading}>
        Обновить
      </Button>
      <Table
        loading={isLoading}
        bordered
        size="small"
        columns={[
          {
            dataIndex: "responseMarketplace",
            title: "",
            width: 32,
          },
          {
            dataIndex: "createdAt",
            title: "Дата",
            width: 100,
          },
          {
            title: "Ссылка",
            dataIndex: "requestUrl",
          },
          {
            dataIndex: "variantsCount",
            title: "Кол-во вариантов",
          },
          {
            dataIndex: "alreadyMappedVariantsCount",
            title: "Кол-во уже проверенных вариантов",
          },
          {
            dataIndex: "source",
            title: "Источник",
          },
        ]}
        dataSource={requests.map((request) => {
          let requestUrl = request.requestUrl;

          if (request.responseMarketplace === "OZON") {
            requestUrl = `https://ozon.kz${requestUrl}`;
          }

          let marketplaceLogoUrl = "";
          if (request.responseMarketplace.toLowerCase() === "ozon") {
            marketplaceLogoUrl =
              "https://api.foxyprice.me/api/images/marketplace/ozon.png";
          } else if (
            request.responseMarketplace.toLowerCase() === "wildberries"
          ) {
            marketplaceLogoUrl =
              "https://api.foxyprice.me/api/images/marketplace/wildberries.png";
          }

          return {
            requestUrl: (
              <div className="d-flex align-items-center">
                <a
                  href={requestUrl}
                  target="_blank"
                  rel="noreferrer"
                  className="me-3"
                >
                  Открыть
                </a>
                <RequestUrlParams requestUrl={requestUrl} />
              </div>
            ),
            variantsCount: request.variantsCount,
            alreadyMappedVariantsCount: request.alreadyMappedVariantsCount,
            source: request.source,
            responseMarketplace: (
              <img style={{ width: 25 }} src={marketplaceLogoUrl} alt="" />
            ),
            createdAt: new Date(request.createdAt).toLocaleString(),
          };
        })}
      />
    </div>
  );
};

const RequestUrlParams = ({ requestUrl }) => {
  const [open, setOpen] = useState(false);

  const url = new URL(requestUrl);
  const searchParams = url.searchParams;

  const keys = searchParams.keys();

  return (
    <>
      <div
        className="border rounded px-1"
        style={{ cursor: "pointer" }}
        onClick={() => setOpen(true)}
      >
        <ApiOutlined />
      </div>
      <Modal
        title="Параметры запроса"
        open={open}
        onCancel={() => setOpen(false)}
        okButtonProps={{ style: { display: "none" } }}
        cancelText="Закрыть"
      >
        <Descriptions labelStyle={{color: '#202020'}}column={1}>
          <Descriptions.Item label="Хост">
            <b>{url.origin}</b>
          </Descriptions.Item>
          <Descriptions.Item label="Путь">
            <b>{url.pathname}</b>
          </Descriptions.Item>
        </Descriptions>
        <Descriptions labelStyle={{color: '#202020'}}column={1} title="Параметры">
          {Array.from(keys).map((key) => {
            return (
              <Descriptions.Item label={key}>
                <b>{searchParams.get(key)}</b>
              </Descriptions.Item>
            );
          })}
        </Descriptions>
        <a href={requestUrl} target="_blank" rel="noreferrer">
          {requestUrl}
        </a>
      </Modal>
    </>
  );
};
