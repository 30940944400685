import { Button, Card } from "antd";
import ProductMerchantCity from "./ProductMerchantCity";
import axios from "axios";
import config from "../../../config.json";
import { ReloadOutlined } from "@ant-design/icons";
import { useState } from "react";

const ProductMerchant = ({ store, product }) => {
  const [step, setStep] = useState(0);

  const cities = [
    {
      cityId: store.mainCity.id,
      cityName: store.mainCity.name,
    },
  ];
  if (Array.isArray(product.cities)) {
    for (const city of product.cities) {
      cities.push({
        cityId: city.cityId,
        cityName: city.cityName,
      });
    }
  }

  const resetAllProductMerchants = () => {
    axios.patch(
      `${config.service}/api/product/product-merchant/${product.masterProductSku}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
        },
      }
    );
  };

  const reload = () => {
    setStep(step + 1);
  };

  return (
    <Card>
      <Button
        type="primary"
        className="me-3"
        onClick={reload}
        icon={<ReloadOutlined />}
      >
        Обновить
      </Button>
      <Button onClick={resetAllProductMerchants}>Сбросить всех</Button>
      <div className="mt-3">
        {cities.map((city, index) => {
          return (
            <ProductMerchantCity
              key={index}
              city={city}
              masterProductSku={product.masterProductSku}
              step={step}
            />
          );
        })}
      </div>
    </Card>
  );
};

export default ProductMerchant;
