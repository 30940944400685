import { Descriptions } from "antd";
import { currencyFormat } from "../../../scripts/format";

const DailyAnalyticsProductItem = ({ product }) => {
  return (
    <div className={"pb-2 mb-2"}>
      <div>
        <a href={product.productUrl} target="_blank" rel="noreferrer">
          {product.productName}
        </a>
        <div className="text-secondary" style={{ fontSize: 12 }}>
          <i>{product.masterSku}</i>
        </div>
      </div>
      <Descriptions labelStyle={{color: '#202020'}}column={1}>
        <Descriptions.Item className="p-0 m-0" label="Кол-во продаж за 30 дн.">
          <b>{currencyFormat(product.amountOfSellsForLast30Days)}</b>
        </Descriptions.Item>
        <Descriptions.Item className="p-0 m-0" label="Оборот за 30 дн.">
          <b>{currencyFormat(product.turnOverForLast30Days)}</b>
        </Descriptions.Item>
        <Descriptions.Item className="p-0 m-0" label="Цена на Каспи">
          <b>{currencyFormat(product.minPrice)}</b>
        </Descriptions.Item>
        <Descriptions.Item className="p-0 m-0" label="Кол-во отзывов">
          <b>{currencyFormat(product.reviewsCount)}</b>
        </Descriptions.Item>
        <Descriptions.Item className="p-0 m-0" label="Кол-во продавцов">
          <b>{currencyFormat(product.offersCount)}</b>
        </Descriptions.Item>
        <Descriptions.Item className="p-0 m-0" label="Доступ">
          <b>
            {product.hasAccess ? (
              <div className="text-success">Есть</div>
            ) : (
              <div className="text-danger">Нет</div>
            )}
          </b>
        </Descriptions.Item>
        <Descriptions.Item className="p-0 m-0" label="Дата">
          <b>{product.dateKey}</b>
        </Descriptions.Item>
        <Descriptions.Item className="p-0 m-0" label="Тип">
          <b>{product.productType === "FREE" ? "Бесплатный" : "Платный"}</b>
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
};

export default DailyAnalyticsProductItem;
