import { message, Pagination, Select, Table, Popconfirm } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import config from "../../../config.json";
import { currencyFormat } from "../../../scripts/format";
import { DeleteOutlined } from "@ant-design/icons";

const Payments = () => {
  const [payments, setPayments] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(1);
  const [admins, setAdmins] = useState([]);

  useEffect(() => {
    fetchAdmins();
  }, []);

  useEffect(() => {
    fetch();
  }, [pageSize, page]);

  const fetchAdmins = () => {
    axios
      .get(`${config.service}/api/user/list?limit=${pageSize}&page=${page}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
        },
      })
      .then((data) => {
        setAdmins(data.data || []);
      });
  };

  const fetch = () => {
    setIsLoading(true);

    axios
      .get(`${config.service}/api/payment?limit=${pageSize}&page=${page}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
        },
      })
      .then((data) => {
        setPayments(data.data?.payments || []);
        setTotalCount(data.data?.totalCount || 0);
      })
      .finally(() => setIsLoading(false));
  };

  const updatePayment = (paymentId, body) => {
    console.log(paymentId, body);

    const loadingMsg = message.loading("Платеж обновляется");

    axios
      .patch(`${config.service}/api/payment/${paymentId}`, body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
        },
      })
      .then(() => {
        message.success("Платеж успешно обновлен");
      })
      .catch(() => {
        message.error("Не удалось обновить платеж");
      })
      .finally(() => loadingMsg());
  };

  const deletePayment = (paymentId) => {
    const loadingMsg = message.loading("Платеж удаляется");

    axios
      .delete(`${config.service}/api/payment/${paymentId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
        },
      })
      .then(() => {
        message.success("Платеж успешно удален");

        fetch();
      })
      .catch(() => {
        message.error("Не удалось удалить платеж");
      })
      .finally(() => loadingMsg());
  };

  return (
    <div>
      <div className="my-3" style={{ overflow: "auto" }}>
        <Table
          loading={isLoading}
          pagination={false}
          size="small"
          bordered
          columns={[
            {
              title: "Дата",
              dataIndex: "date",
              key: "date",
              width: 40,
            },
            {
              title: "Клиент",
              dataIndex: "client",
              key: "client",
            },
            {
              title: "Магазин",
              dataIndex: "store",
              key: "store",
            },
            {
              title: "X2",
              dataIndex: "doubleSpeed",
              key: "doubleSpeed",
            },
            {
              title: "Цена",
              dataIndex: "price",
              key: "price",
            },
            {
              title: "Тариф",
              dataIndex: "tariff",
              key: "tariff",
            },
            {
              title: "Тип",
              dataIndex: "type",
              key: "type",
            },
            {
              title: "Админ",
              dataIndex: "admin",
              key: "admin",
              width: 150,
            },
            {
              title: "",
              dataIndex: "action",
              key: "action",
              width: 30,
            },
          ]}
          dataSource={payments.map((payment) => {
            return {
              date: new Date(payment?.createdAt).toLocaleDateString(),
              client: (
                <Link
                  to={`/client/${payment?.userId}`}
                >{`${payment?.user?.name} ${payment?.user?.surname}`}</Link>
              ),
              store: payment?.store ? (
                <Link
                  to={`/store/${payment?.storeId}`}
                >{`${payment?.store?.name}`}</Link>
              ) : (
                ""
              ),
              admin: (
                <Select
                  style={{ width: 150 }}
                  defaultValue={`${payment?.admin?.login || ""}`}
                  onChange={(value) => {
                    updatePayment(payment._id, {
                      activatedUserId: value,
                    });
                  }}
                  options={admins.map((admin) => {
                    return {
                      value: admin._id,
                      label: admin.login,
                    };
                  })}
                />
              ),
              price: (
                <div style={{ whiteSpace: "nowrap" }}>
                  {currencyFormat(payment?.price || 0)}
                </div>
              ),
              type: `${payment?.type}`,
              doubleSpeed: `${payment?.doubleSpeed ? "Да" : ""}`,
              tariff: payment?.tariff ? `${payment?.tariff?.name || ""}` : "",
              action: (
                <Popconfirm
                  destroyTooltipOnHide
                  placement="bottomRight"
                  title="Вы точно хотите удалить платеж?"
                  onConfirm={() => deletePayment(payment?._id)}
                >
                  <div className="border rounded px-1 py-1 border-danger text-danger cursor-pointer">
                    <DeleteOutlined style={{ fontSize: 18 }} />
                  </div>
                </Popconfirm>
              ),
            };
          })}
        />
      </div>
      <div className="d-flex justify-content-center mt-3">
        <Pagination
          current={page}
          pageSize={pageSize}
          total={totalCount}
          disabled={isLoading}
          onChange={(page, pageSize) => {
            setPageSize(pageSize);
            setPage(page);
          }}
        />
      </div>
    </div>
  );
};

export default Payments;
