import { Divider, Skeleton } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import config from "../../../config.json";
import { clearToken } from "../../../scripts/token";

const StoreList = ({ clientId }) => {
  const [stores, setStores] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchStores();
  }, [clientId]);

  const fetchStores = () => {
    axios
      .get(`${config.service}/api/store/client/${clientId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
        },
      })
      .then((data) => {
        setStores(data.data);
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          clearToken();
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div>
      <Divider />
      <h1>Магазины</h1>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        {isLoading && <Skeleton active />}
        {stores.map((store, index) => {
          return <StoreCard store={store} key={index} />;
        })}
      </div>
    </div>
  );
};

const StoreCard = ({ store }) => {
  return (
    <a
      href={`/store/${store._id}`}
      style={{
        border: "1px solid",
        marginRight: 15,
        padding: 10,
      }}
    >
      <div>{store.name}</div>
      <div>{new Date(store.expireDate).toLocaleString()}</div>
    </a>
  );
};

export default StoreList;
