import {
  Button,
  Checkbox,
  DatePicker,
  Input,
  InputNumber,
  message,
  Modal,
} from "antd";
import { useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import config from "../../../config.json";
import axios from "axios";

const dateFormat = "DD.MM.YYYY";

window.addProxy = (
  name,
  host,
  port,
  login,
  password,
  isActive,
  type,
  expireDate,
  phoneNumber,
  mainNumber
) => {
  const body = {
    name,
    host,
    port,
    login,
    password,
    isActive,
    type,
    expireDate,
    phoneNumber,
    mainNumber
  };
  const options = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
    },
  };
  const url = `${config.service}/api/proxy`;

  axios
    .post(url, body, options)
    .then((data) => {
      console.log("Добавлено", data);
    })
    .catch((data) => {
      console.log("Не удалось сохранить", data);
    });
};

const AddProxy = ({ onSaved }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [name, setName] = useState("");
  const [host, setHost] = useState("");
  const [port, setPort] = useState("");
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [type, setType] = useState("SHOP");
  const [expireDate, setExpireDate] = useState();
  const [phoneNumber, setNumber]=useState("");
  const [mainNumber,setMainNumber]=useState("");
  const close = () => {
    setVisible(false);
  };

  const open = () => {
    setVisible(true);
  };

  const save = () => {
    setIsLoading(true);

    const body = {
      name,
      host,
      port,
      login,
      password,
      isActive,
      type,
      expireDate,
      phoneNumber,
      mainNumber
    };
    const options = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
      },
    };
    const url = `${config.service}/api/proxy`;

    axios
      .post(url, body, options)
      .then(() => {
        message.success("Добавлено");
        // onSaved();
        // close();
      })
      .catch((data) => {
        let msgText = data?.response?.data?.message || "Не удалось сохранить";

        if (msgText) {
          message.error(msgText);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  console.log(name);

  return (
    <>
      <Button icon={<PlusOutlined />} type="primary" onClick={open}>
        Добавить
      </Button>
      <Modal
        destroyOnClose
        okButtonProps={{
          loading: isLoading,
        }}
        onOk={save}
        okText="Сохранить"
        cancelText="Закрыть"
        title="Добавить прокси"
        visible={visible}
        onCancel={close}
      >
        <div>Название:</div>
        <Input
          placeholder="Название..."
          className=""
          value={name}
          onChange={(v) => {
            setName(v.target.value);
          }}
        />
        <div className="mt-3">Хост и порт:</div>
        <Input.Group compact>
          <Input
            placeholder="Хост..."
            addonBefore="http://"
            className="w-75"
            addonAfter=":"
            onChange={(v) => {
              setHost(v.target.value);
            }}
          />
          <InputNumber
            placeholder="Порт..."
            onChange={(v) => {
              setPort(v);
            }}
          />
        </Input.Group>
        <div className="mt-3">Логин и пароль:</div>
        <Input.Group compact>
          <Input
            placeholder="Логин..."
            className="w-50"
            onChange={(v) => {
              setLogin(v.target.value);
            }}
          />
          <Input
            placeholder="Пароль..."
            className="w-50"
            onChange={(v) => {
              setPassword(v.target.value);
            }}
          />
        </Input.Group>
        <div className="mt-3">Оплачен до (включительно):</div>
        <DatePicker
          // value={dayjs(expireDate, dateFormat)}
          format={dateFormat}
          onChange={(v) => {
            console.log(v);
            setExpireDate(v.toDate());
          }}
          className="w-100"
        />
        <div className="mt-3">Тип:</div>
        <Input.Group compact>
          <Input
            placeholder="Тип..."
            value={type}
            onChange={(v) => {
              setType(v.target.value);
            }}
          />
        </Input.Group>
        <div className="mt-3">Номер телефона:</div>
        <Input.Group compact>
          <Input
            placeholder="Текущий номер..."
            className="w-50"
            onChange={(v) => {
              setNumber(v.target.value);
            }}
          />
          <Input
            placeholder="Главный номер..."
            className="w-50"
            onChange={(v) => {
              setMainNumber(v.target.value);
            }}
          />
        </Input.Group>
        <Checkbox
          checked={isActive}
          className="mt-3"
          onChange={(v) => {
            setIsActive(v.target.checked);
          }}
        >
          Активный
        </Checkbox>
      </Modal>
    </>
  );
};

export default AddProxy;
