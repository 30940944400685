import "./StorePriceChangeMethodsGuide.css";

import { Alert, BackTop, Card, Image } from "antd";
import { PageHeader } from '@ant-design/pro-components';
import ChangePriceInKaspiImg from "../../assets/photo/change-price-in-kaspi.png";
import { useState } from "react";
import SaleScoutXmlFileLinkExample from "../../assets/photo/salescout-xml-file-link-example.png";
import SetXmlLinkToKaspiExample from "../../assets/photo/set-xml-link-to-kaspi-example.png";
import StoreSettingsExternalXmlLink from "../../assets/photo/store-settings-external-xml-link.png";
import useIsMobile from "../../hooks/useIsMobile";

const priceChangeMethods = [
  {
    title: "Cтандарт",
    key: "standart",
    children: (
      <div>
        <div>
          В режиме "Стандарт", SaleScout отправляет запросы в Каспи, имитируя
          действия человека. Это включает в себя выполнение всех операций
          согласно ограничениям и правилам, установленным Каспи. Бот ограничен
          возможностью отправки максимум 250 запросов в течение 30 минут, что
          является установленным лимитом со стороны Kaspi.
        </div>
        <div className="mt-3">
          <img
            className="m-0 p-0"
            alt="change-price-in-kaspi"
            src={ChangePriceInKaspiImg}
            style={{ maxWidth: 500 }}
          />
        </div>
      </div>
    ),
  },
  {
    title: "XML",
    key: "xml",
    children: (
      <div>
        <div>
          <h4>Активация Функции XML</h4>
          <ol className="ms-4">
            <li>
              <h4 className="m-0">Открытие Настроек Магазина</h4>
              <ul>
                <li>Войдите в админку SaleScout.</li>
                <li>Перейдите на страницу магазина.</li>
                <li>Откройте раздел "Настройки".</li>
              </ul>
            </li>
            <li>
              <h4 className="m-0">Выбор Метода Ценообразования</h4>
              <ul>
                <li>В "Настройках", найдите раздел "Метод Ценообразования".</li>
                <li>Из выпадающего списка выберите "XML".</li>
                <li>
                  Это действие инициирует создание XML файла согласно правилам
                  Каспи.{" "}
                  <a
                    href="https://kaspi.kz/merchantcabinet/support/display/Support/XML"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Ссылка на правила Каспи
                  </a>
                </li>
              </ul>
            </li>
          </ol>
        </div>
        <div>
          <h4>Использование XML Файла</h4>
          <ol className="ms-4">
            <li>
              <h4 className="m-0">Авторизация</h4>
              <ul>
                <li>Авторизуйтесь в кабинет каспи продавца.</li>
              </ul>
            </li>
            <li>
              <h4 className="m-0">Переход к Автоматической Загрузке</h4>
              <ul>
                <li>
                  На главной странице перейдите в раздел "Загрузить прайс-лист".
                </li>
                <li>Затем выберите "Автоматическая загрузка".</li>
              </ul>
            </li>
            <li>
              <h4 className="m-0">Вставка Ссылки на XML Файл</h4>
              <ul>
                <li>
                  <div>
                    В поле "Допустимый формат файла: [XML]" вставьте ссылку.
                    Затем нажмите на кнопку "Проверить", после успешной проверки
                    нажмите на "Сохранить". Если вдруг файл не прошел проверку
                    обратитесь к разрабочикам.
                  </div>
                  <img
                    src={SetXmlLinkToKaspiExample}
                    style={{ maxWidth: 500 }}
                    alt="SetXmlLinkToKaspiExample"
                  />
                </li>
                <li>
                  <div>
                    Ссылку найдете в разделе "Информация о магазине" под
                    названием "Ссылка на наш XML файл" после обновления
                    страницы.
                  </div>
                  <img
                    src={SaleScoutXmlFileLinkExample}
                    style={{ maxWidth: 250 }}
                    alt="SaleScoutXmlFileLinkExample"
                  />
                </li>
              </ul>
            </li>
          </ol>
        </div>
        <div>
          <h4>Интеграция SaleScout с Каспи</h4>
          <ol className="ms-4">
            <li>
              <h4 className="m-0">Обновление Цен и Наличия Товаров:</h4>
              <ul>
                <li>
                  После того как Каспи получает XML файл от SaleScout, на
                  платформе Каспи происходит автоматическое обновление
                  информации о ценах и наличии товаров.
                </li>
                <li>
                  Это обновление включает как цены, так и информацию о наличии
                  товаров на складах.
                </li>
              </ul>
            </li>
          </ol>
        </div>
        <div>
          <h4>Автоматизированный Процесс</h4>
          <ol className="ms-4">
            <li>
              <h4 className="m-0">Частота Обновлений:</h4>
              <ul>
                <li>
                  SaleScout автоматически обновляет информацию о ценах и наличии
                  товаров в складах в XML файле каждые 10 минут. Источником
                  правды является SaleScout. Это означает, что любые изменения в
                  ценах или наличии товаров на складе будут отражены в XML файле
                  в течение этого времени.
                </li>
                <li>
                  Когда Каспи забирает XML файл (обычно каждый час), они
                  получают самую свежую информацию о ценах и наличии товаров в
                  SaleScout.
                </li>
              </ul>
            </li>
          </ol>
        </div>
        <Alert
          type="warning"
          description={
            <div>
              <b>Важно:</b> Ссылка на XML файл будет активна в течение 10 минут
              после её активации в системе.
            </div>
          }
        />
        <Alert
          className="mt-3"
          type="warning"
          description={
            <div>
              <b>Важно:</b> Метод ценообразования XML работает только для Каспи
              магазинов.
            </div>
          }
        />
      </div>
    ),
  },
  // {
  //   title: "Внешняя XML интеграция",
  //   key: "xml-integration",
  //   children: (
  //     <div>
  //       <div className="mb-4">
  //         <h4>Описание</h4>
  //         <div>
  //           Внешняя XML интеграция с комбинированными запросами в SaleScout
  //           позволяет клиентам использовать свои собственные XML файлы,
  //           созданные через различные внешние сервисы (например, fit.kz, Bitrix,
  //           1C и другие), для актуализации информации о товарах в их магазинах
  //           на SaleScout.
  //         </div>
  //       </div>
  //       <div>
  //         <h4>Процесс Интеграции</h4>
  //         <ol className="ms-4">
  //           <li>
  //             <h4 className="m-0">Создание XML Файла:</h4>
  //             <ul>
  //               <li>
  //                 Клиенты создают свои XML файлы с помощью внешних сервисов,
  //                 таких как fit.kz, Bitrix, 1C и другие.
  //               </li>
  //             </ul>
  //           </li>
  //           <li>
  //             <h4 className="m-0">Вставка Ссылки на XML Файл:</h4>
  //             <ul>
  //               <li>
  //                 <div>
  //                   Клиенты вставляют ссылку на свой внешний XML файл в
  //                   настройках своего магазина на SaleScout.
  //                 </div>
  //                 <Image
  //                   src={StoreSettingsExternalXmlLink}
  //                   alt="StoreSettingsExternalXmlLink"
  //                   style={{ maxWidth: 800 }}
  //                 />
  //               </li>
  //             </ul>
  //           </li>
  //           <li>
  //             <h4 className="m-0">Обработка и Импорт Данных:</h4>
  //             <ul>
  //               <li>
  //                 SaleScout автоматически обращается к этим внешним XML файлам,
  //                 обрабатывает и импортирует данные для обновления информации о
  //                 товарах в системе.
  //               </li>
  //             </ul>
  //           </li>
  //         </ol>
  //       </div>
  //       <div>
  //         <h4>Использование XML Файла</h4>
  //         <ol className="ms-4">
  //           <li>
  //             <h4 className="m-0">Авторизация</h4>
  //             <ul>
  //               <li>Авторизуйтесь в кабинет каспи продавца.</li>
  //             </ul>
  //           </li>
  //           <li>
  //             <h4 className="m-0">Переход к Автоматической Загрузке</h4>
  //             <ul>
  //               <li>
  //                 На главной странице перейдите в раздел "Загрузить прайс-лист".
  //               </li>
  //               <li>Затем выберите "Автоматическая загрузка".</li>
  //             </ul>
  //           </li>
  //           <li>
  //             <h4 className="m-0">Вставка Ссылки на XML Файл</h4>
  //             <ul>
  //               <li>
  //                 <div>
  //                   В поле "Допустимый формат файла: [XML]" вставьте ссылку.
  //                   Затем нажмите на кнопку "Проверить", после успешной проверки
  //                   нажмите на "Сохранить". Если вдруг файл не прошел проверку
  //                   обратитесь к разрабочикам.
  //                 </div>
  //                 <img
  //                   src={SetXmlLinkToKaspiExample}
  //                   style={{ maxWidth: 500 }}
  //                   alt="SetXmlLinkToKaspiExample"
  //                 />
  //               </li>
  //               <li>
  //                 <div>
  //                   Ссылку найдете в разделе "Информация о магазине" под
  //                   названием "Ссылка на наш XML файл" после обновления
  //                   страницы.
  //                 </div>
  //                 <img
  //                   src={SaleScoutXmlFileLinkExample}
  //                   style={{ maxWidth: 250 }}
  //                   alt="SaleScoutXmlFileLinkExample"
  //                 />
  //               </li>
  //             </ul>
  //           </li>
  //         </ol>
  //       </div>
  //       <div>
  //         <h4>Как это работает?</h4>
  //         <ol className="ms-4">
  //           <li>
  //             Каждые 10 минут система автоматически обращается к внешнему XML
  //             файлу.
  //           </li>
  //           <li>
  //             При каждом обращении к файлу, система вычисляет его хеш-сумму.
  //             Хеш-сумма — это специальный уникальный код, создаваемый из
  //             содержания файла. Этот код меняется даже при малейших изменениях в
  //             файле, например, если добавляется дополнительная запятая.
  //           </li>
  //           <li>
  //             Система сравнивает новую хеш-сумму с хеш-суммой, полученной при
  //             последнем обращении к файлу.
  //             <ol className="ms-5">
  //               <li>
  //                 Если во внешнем xml, который мы только что загрузили ничего не
  //                 изменилось по сравнению с предыдущем загруженным xml файлом,
  //                 то SaleScout берет новый только что загруженный xml, обновляет
  //                 все данные о наличии товаров из SaleScout (то есть если клиент
  //                 снял товар в кабинете SaleScout, но ничего не менял в своем
  //                 1C, то мы передадим в каспи данные о том что товар снят с
  //                 продажи), обогощаем его с новыми ценами из SaleScout и
  //                 загружаем в каспи.
  //               </li>
  //               <li>
  //                 Если во внешнем xml, который мы только что загрузили что-то
  //                 изменилось по сравнению с предыдущем загруженным xml файлом,
  //                 ты мы берем только что загруженный файл, обогощаем его с
  //                 новыми ценами из SaleScout и загружаем в каспи.
  //               </li>
  //             </ol>
  //           </li>
  //         </ol>
  //       </div>
  //       <Alert
  //         type="warning"
  //         description={
  //           <div>
  //             <b>Важно:</b> Ссылка на XML файл будет активна в течение 10 минут
  //             после её активации в системе.
  //           </div>
  //         }
  //       />
  //       <Alert
  //         className="mt-3"
  //         type="warning"
  //         description={
  //           <div>
  //             <b>Важно:</b> Метод ценообразования "Внешняя XML интеграция"
  //             работает только для Каспи магазинов.
  //           </div>
  //         }
  //       />
  //     </div>
  //   ),
  // },
  // {
  //   title: "Внешняя XML интеграция с комбинированными запросами",
  //   key: "xml-integration-combined-request",
  //   children: (
  //     <div>
  //       <div className="mb-4">
  //         <h4>Описание</h4>
  //         <div>
  //           Функция "Внешняя XML Интеграция с Комбинированными Запросами"
  //           позволяет одновременно использовать оба подхода:{" "}
  //           <span
  //             className="cursor-pointer text-primary"
  //             onClick={() => scrollToId("standart")}
  //           >
  //             Стандартную Интеграцию
  //           </span>{" "}
  //           и{" "}
  //           <span
  //             className="cursor-pointer text-primary"
  //             onClick={() => scrollToId("xml-integration")}
  //           >
  //             Внешнюю XML Интеграцию
  //           </span>
  //           .
  //         </div>
  //       </div>
  //       <div>
  //         <h4 className="m-0">Процесс</h4>
  //         <li>
  //           <ul className="ms-4">
  //             <li>
  //               Обе системы работают параллельно и независимо друг от друга.
  //             </li>
  //             <li>
  //               Это обеспечивает гибкость в управлении данными и возможность
  //               использования преимуществ каждого метода.
  //             </li>
  //           </ul>
  //         </li>
  //       </div>
  //       <div>
  //         <h4 className="m-0">Проверка Наличия Предзаказов</h4>
  //         <div>
  //           Перед активацией функции "Внешняя XML интеграция с комбинированными
  //           запросами" в SaleScout, крайне важно уточнить у клиента наличие
  //           предзаказов на товарах.
  //         </div>
  //       </div>
  //       <div>
  //         <h4 className="m-0 mt-2">Почему это важно?</h4>
  //         <ol className="ms-4">
  //           <li>
  //             <b>Установка Предзаказа через XML</b> - При загрузке информации о
  //             товарах через XML, система может автоматически устанавливать
  //             статус предзаказа на товарах.
  //           </li>
  //           <li>
  //             <b>Конфликт с Стандартными Запросами</b> - Когда SaleScout
  //             отправляет стандартные запросы, статус предзаказа, установленный
  //             через XML, может быть сброшен. Это приводит к конфликту между
  //             данными, загруженными через XML, и данными, обновленными через
  //             стандартные запросы.
  //           </li>
  //         </ol>
  //       </div>
  //       <div>
  //         <h4 className="m-0 mt-2">Рекомендации</h4>
  //         <ol className="ms-4">
  //           <li>
  //             <b>Проверка перед Включением</b> - Убедитесь, что клиент
  //             осведомлен о возможном конфликте и проверьте наличие предзаказов
  //             на товарах перед включением функции.
  //           </li>
  //           <li>
  //             <b>Оценка Влияния на Бизнес</b> - Помогите клиенту оценить, как
  //             влияние этого конфликта может повлиять на их бизнес, и принять
  //             информированное решение.
  //           </li>
  //         </ol>
  //       </div>
  //     </div>
  //   ),
  // },
  {
    title: "Комбинированные запросы",
    key: "xml-combined-request",
    children: (
      <div>
        <div className="mb-4">
          <h4>Описание</h4>
          <div>
            Функция "Комбинированные запросы" позволяет одновременно
            использовать оба подхода:{" "}
            <span
              className="cursor-pointer text-primary"
              onClick={() => scrollToId("standart")}
            >
              Стандартный метод 
            </span>{" "}
            и{" "}
            <span
              className="cursor-pointer text-primary"
              onClick={() => scrollToId("xml")}
            >
              XML
            </span>
            .
          </div>
        </div>
        <div>
          <h4 className="m-0">Процесс</h4>
          <li>
            <ul className="ms-4">
              <li>
                Обе системы работают параллельно и независимо друг от друга.
              </li>
              <li>
                Это обеспечивает гибкость в управлении данными и возможность
                использования преимуществ каждого метода.
              </li>
            </ul>
          </li>
        </div>
        <div>
          <h4 className="m-0">Проверка Наличия Предзаказов</h4>
          <div>
            Перед активацией функции "XML с Комбинированными Запросами" в
            SaleScout, крайне важно уточнить у клиента наличие предзаказов на
            товарах.
          </div>
        </div>
        <div>
          <h4 className="m-0 mt-2">Почему это важно?</h4>
          <ol className="ms-4">
            <li>
              <b>Установка Предзаказа через XML</b> - При загрузке информации о
              товарах через XML, система может автоматически устанавливать
              статус предзаказа на товарах.
            </li>
            <li>
              <b>Конфликт с Стандартными Запросами</b> - Когда SaleScout
              отправляет стандартные запросы, статус предзаказа, установленный
              через XML, может быть сброшен. Это приводит к конфликту между
              данными, загруженными через XML, и данными, обновленными через
              стандартные запросы.
            </li>
          </ol>
        </div>
        <div>
          <h4 className="m-0 mt-2">Рекомендации</h4>
          <ol className="ms-4">
            <li>
              <b>Проверка перед Включением</b> - Убедитесь, что клиент
              осведомлен о возможном конфликте и проверьте наличие предзаказов
              на товарах перед включением функции.
            </li>
            <li>
              <b>Оценка Влияния на Бизнес</b> - Помогите клиенту оценить, как
              влияние этого конфликта может повлиять на их бизнес, и принять
              информированное решение.
            </li>
          </ol>
        </div>
      </div>
    ),
  },
  {
    title: "Ручная загрузка XML в Kaspi",
    key: "manual-xml-upload",
    children: (
      <div>
        <div className="mb-4">
          <h4>Описание</h4>
          <div>
            Функция "Ручная загрузка XML в Kaspi" в SaleScout позволяет
            имитировать ручную загрузку данных в систему Kaspi. Этот метод
            полностью независим от других методов ценообразования в SaleScout.
          </div>
        </div>
        <div>
          <h4 className="m-0">Процесс ручной загрузки</h4>
          <ul className="ms-4">
            <li>
              <b>Генерация XML файла</b> - система SaleScout автоматически
              генерирует XML файл, используя данные из базы SaleScout.
            </li>
            <li>
              <b>Загрузка в Kaspi</b> - затем этот файл загружается в систему
              Kaspi, имитируя процесс ручной загрузки.
            </li>
          </ul>
        </div>
        <div>
          <h4 className="m-0">Ограничения Kaspi</h4>
          <ul className="ms-4">
            <li>
              <b>Лимит Загрузок</b> - важно учитывать, что в Kaspi существует
              ограничение: возможность загрузки только 2 файлов в течение 30
              минут.
            </li>
          </ul>
        </div>

        <div>
          <h4 className="m-0 mt-2">Как включить функцию?</h4>
          <ol className="ms-4">
            <li>
              <b>Переход на страницу магазина в админке SaleScout</b>
            </li>
            <li>
              <b>Вкладка настройки</b>
            </li>
            <li>
              <b>Активация функции</b> - поставьте галочку напротив опции
              "Ручная загрузка XML в Kaspi".
            </li>
          </ol>
        </div>
        <div>
          <h4 className="m-0 mt-2">Важные Аспекты</h4>
          <ol className="ms-4">
            <li>
              <b>Независимость Метода</b> - Этот метод работает независимо от
              других настроек ценообразования в SaleScout.
            </li>
          </ol>
        </div>
      </div>
    ),
  },
];

const scrollToId = (id) => {
  const elem = document.getElementById(id);
  if (!elem) {
    return;
  }

  elem.scrollIntoView({ behavior: "smooth" });
};

const StorePriceChangeMethodsGuide = () => {
  const [selectedElem, setSelectedElem] = useState("");
  const isMobile = useIsMobile()
  return (
    <Card className={isMobile ? 'mx-2' : "mx-3"}>
      <BackTop />
      <PageHeader title="Методы ценообразования" />
      <div>
        <div className="ms-4">
          <ol>
            {priceChangeMethods.map((data, index) => {
              return (
                <li
                  className="text-primary cursor-pointer"
                  key={index}
                  onClick={() => {
                    setSelectedElem(data.key);
                    scrollToId(data.key);
                  }}
                >
                  {data.title}
                </li>
              );
            })}
          </ol>
        </div>
        <div>
          {priceChangeMethods.map((data) => {
            return (
              <Card
                key={data.key}
                className={`mb-3 rounded ${
                  data.key === selectedElem ? "border-success" : ""
                }`}
                id={data.key}
              >
                <h3>{data.title}</h3>
                <div className>{data.children}</div>
              </Card>
            );
          })}
        </div>
      </div>
    </Card>
  );
};

export default StorePriceChangeMethodsGuide;
