import { Button, Form, message, Modal } from "antd";
import axios from "axios";
import { useState } from "react";
import config from "../../../config.json";

const ActivatePrivilegedStore = ({ store }) => {
  const [visible, setVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  if (!store) {
    return <></>;
  }

  const onSubmit = () => {
    setIsLoading(true);

    axios
      .post(
        `${config.service}/api/privileged-store/activate`,
        {
          storeId: store._id,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              "admin_access_token"
            )}`,
          },
        }
      )
      .then(() => {
        message.success("Магазин успешно активирован");
      })
      .catch(() => {
        message.error("Не удалось активировать магазина");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      {!store.privilege && (
        <Button
          className="bg-success text-white px-4 mb-2"
          onClick={() => setVisible(true)}
          style={{ width: 200 }}
        >
          Сделать VIP магазином
        </Button>
      )}
      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        title="Сделать VIP магазином"
        destroyOnClose={true}
        okButtonProps={{
          style: {
            display: "none",
          },
        }}
      >
        <div>
          Магазин: <b>{store?.name}</b>
        </div>
        <div
          className="w-100 d-flex align-items-center justify-content-center"
          style={{
            height: 150,
          }}
        >
          <Button
            loading={isLoading}
            type="primary px-5"
            style={{ height: 50, width: 250 }}
            onClick={onSubmit}
          >
            Активировать
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default ActivatePrivilegedStore;
