import "./Bootstrap.css";
import "./App.css";
// import LoginForm from "./components/Forms/LoginForm";
import Routes from "./Routes";
import { ConfigProvider } from "antd";
import ruRU from "antd/es/locale/ru_RU";


function App() {
  return (
    <ConfigProvider locale={ruRU}>
      
      <Routes />
    </ConfigProvider>
  );
}

export default App;
