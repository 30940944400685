import { Button, Form, Input, InputNumber, message, Modal } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useState } from "react";
import { isNumber } from "class-validator";
import axios from "axios";
import config from "../../../config.json";
import { useForm } from "antd/lib/form/Form";

const AdditionalCity = ({ store }) => {
  const [visble, setVisible] = useState(false);
  const [form] = useForm();
  const close = () => {
    setVisible(false);
    setIsLoading(false);
    form.resetFields();
  };
  const open = () => setVisible(true);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = () => {
    const citiesCount = form.getFieldValue("citiesCount");
    console.log(citiesCount);
    if (!isNumber(citiesCount)) {
      message.error("Введите кол-во городов");

      return;
    }
    setIsLoading(true);

    axios
      .post(
        `${config.service}/api/store/${store._id}/additional-city`,
        {
          cityLimit: citiesCount,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              "admin_access_token"
            )}`,
          },
        }
      )
      .then(() => close())
      .catch(() => {
        message.error("Не удалось добавить городов");
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <Button
        className="mb-2"
        icon={<PlusOutlined />}
        onClick={open}
        style={{ width: 200 }}
      >
        Добавить город
      </Button>
      <Modal
        destroyOnClose
        visible={visble}
        onCancel={close}
        title="Добавить город"
        okButtonProps={{
          loading: isLoading,
          icon: <PlusOutlined />,
        }}
        okText="Добавить"
        onOk={handleSubmit}
      >
        <Form form={form} initialValues={{ citiesCount: 0 }}>
          <div>Магазин:</div>
          <Form.Item>
            <Input disabled value={store.name} />
          </Form.Item>
          <div>Кол-во городов</div>
          <Form.Item name="citiesCount">
            <InputNumber min={0} className="w-100" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AdditionalCity;
