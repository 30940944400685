import { Button, message } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { useState } from "react";
import config from "../../../config.json";
import axios from "axios";

const DeleteProxy = ({ proxy, onDelete }) => {
  const [isLoading, setIsLoading] = useState(false);

  const deleteProxy = () => {
    setIsLoading(true);

    const options = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
      },
    };
    const url = `${config.service}/api/proxy/${proxy._id}`;

    axios
      .delete(url, options)
      .then(() => {
        message.success("Удалено");

        onDelete();
      })
      .catch(() => {
        message.error("Не удалось удалить");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <Button
        loading={isLoading}
        className={"text-danger"}
        icon={<DeleteOutlined />}
        onClick={deleteProxy}
      ></Button>
    </>
  );
};

export default DeleteProxy;
