import { Pagination, Table, Segmented, Button } from "antd";
import { useEffect, useState } from "react";
import config from "../../../config.json";
import { clearToken } from "../../../scripts/token";
import axios from "axios";

const XmlUploadToKaspiHistory = ({ store }) => {
  const storeId = store._id;

  const [limit, setLimit] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [xmlRequestHistories, setXmlRequestHistories] = useState([]);
  const [xmlUploadHistories, setXmlUploadHistories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [filter, setFilter] = useState("auto-upload");

  const filters = [
    {
        label: "Ручная загрузка",
        value: "auto-upload",
    },
    {
        label: "Автоматическая загрузка",
        value: "xml",
    },
  ];

  const fetchXmlRequestHistory = (currentPage) => {
    setIsLoading(true);

    axios
      .get(`${config.service}/api/xml-request-history/${storeId}/store?&limit=${limit}&page=${currentPage}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
        },
      })
      .then((data) => {
        setXmlRequestHistories(data.data.xmlRequestHistories);
        setTotalPageCount(data.data.totalCount);
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          clearToken();
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetchXmlUploadHistory = (currentPage) => {
    setIsLoading(true);

    axios
      .get(`${config.service}/api/xml-upload-history/${storeId}/store?&limit=${limit}&page=${currentPage}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
        },
      })
      .then((data) => {
        setXmlUploadHistories(data.data.xmlUploadHistories);
        setTotalPageCount(data.data.totalCount);
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          clearToken();
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (filter === "auto-upload") {
        fetchXmlUploadHistory(currentPage);
    } else if (filter === "xml") {
        fetchXmlRequestHistory(currentPage)
    }
  }, [storeId, limit, currentPage, filter]);

  const columns = [
    {
      title: "Дата",
      dataIndex: "data",
      key: "data",
    },
    {
      title: "Время",
      dataIndex: "time",
      key: "time",
    },
    {
      title: "Статус",
      dataIndex: "status",
      key: "status",
    },
  ];

  const getXmlUploadToKaspiHistoryPagination = () => {
    return (
      <Pagination
        current={currentPage}
        disabled={isLoading}
        total={totalPageCount}
        onChange={(value) => {
          if (value === currentPage) {
            return;
          }

          setCurrentPage(value);
        }}
        onShowSizeChange={async (current, size) => {
          if (size === limit) {
            return;
          }

          setLimit(size);
        }}
        defaultPageSize={limit}
        size={limit}
      />
    );
  };

  const xmlRequestDataSource = xmlRequestHistories.map((item) => ({
    ...item,
    key: item._id,
    status: item.isXmlFileFound ? "Успешно" : "Неуспешно",
    data: new Date(item.createdAt).toLocaleDateString(),
    time: new Date(item.createdAt).toLocaleTimeString(),
  }));

  const xmlUploadDataSource = xmlUploadHistories.map((item) => ({
    ...item,
    key: item._id,
    status: item.uploadStatus,
    data: new Date(item.createdAt).toLocaleDateString(),
    time: new Date(item.createdAt).toLocaleTimeString(),
  }));

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <Segmented
          disabled={isLoading}
          options={filters}
          className="my-4"
          value={filter}
          onChange={(v) => {
            setFilter(v);
            setCurrentPage(1);
          }}
        />
      </div>
      {filter === "auto-upload" && <div>
      <Button className="mb-3" onClick={fetchXmlUploadHistory}>
        Обновить
      </Button>
      <div>
        <i>* Данные за последние 24 часа</i>
      </div>
      </div>
      }
      <Table size={"small"} bordered loading={isLoading} columns={columns} dataSource={filter === "auto-upload" ? xmlUploadDataSource : xmlRequestDataSource} pagination={false}></Table>
      {getXmlUploadToKaspiHistoryPagination()}
    </div>
  );
};

export default XmlUploadToKaspiHistory;
