import { Table } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import config from "../../../config.json";
import "react-funnel-pipeline/dist/index.css";
import { Funnel } from "@ant-design/charts";
import { currencyFormat } from "../../../scripts/format";
import { Link } from "react-router-dom";

const fromD = new Date(
  new Date().getFullYear(),
  new Date().getMonth(),
  new Date().getDate(),
  0,
  0,
  0,
  0
);
const tillD = new Date(
  new Date().getFullYear(),
  new Date().getMonth(),
  new Date().getDate(),
  23,
  59,
  59,
  999
);

const UtmStatistics = ({ setError }) => {
  const [utmData, setUtmData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [fromDate, setFromDate] = useState(fromD);

  const [tillDate, setTillDate] = useState(tillD);
  const [selectedFilterKey, setSelectedFilterKey] = useState("today");

  useEffect(() => {
    setIsLoading(true);

    axios
      .get(`${config.service}/api/dashboard?filter=${selectedFilterKey}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
        },
      })
      .then((data) => {
        setUtmData(data.data);
      })
      .catch((err) => {
        let status = 500;
        let title = "500";
        let subTitle = "Неизвестная ошибка";

        if (err?.response?.status) {
          if (err?.response?.status === 403) {
            status = 403;
            title = "403";
            subTitle = "Доступ закрыт";
          }
        }

        setError({
          isError: true,
          status,
          title,
          subTitle,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [fromDate, tillDate]);

  const columns = [
    {
      title: "Source",
      dataIndex: "utmSource",
      key: "utmSource",
    },
    {
      title: "Medium",
      dataIndex: "utmMedium",
      key: "utmMedium",
    },
    {
      title: "Campaign",
      dataIndex: "utmCampaign",
      key: "utmCampaign",
    },
    {
      title: "Content",
      dataIndex: "utmContent",
      key: "utmContent",
    },
    {
      title: "Открыли сайт",
      dataIndex: "openLanding",
      key: "openLanding",
      sorter: (a, b) => a.openLanding - b.openLanding,
    },
    {
      title: "Открыли систему",
      dataIndex: "openSystem",
      key: "openSystem",
      sorter: (a, b) => a.openSystem - b.openSystem,
    },
    {
      title: "Регистрация",
      dataIndex: "registration",
      key: "registration",
      sorter: (a, b) => a.registration - b.registration,
    },
    {
      title: "Добавили магазин",
      dataIndex: "storeCount",
      key: "storeCount",
      sorter: (a, b) => a.storeCount - b.storeCount,
    },
    {
      title: "Проходит тест",
      dataIndex: "inTest",
      key: "inTest",
      sorter: (a, b) => a.inTest - b.inTest,
    },
    {
      title: "Оплатили",
      dataIndex: "paidCount",
      key: "paidCount",
      sorter: (a, b) => a.paidCount - b.paidCount,
    },
    {
      title: "Сумма",
      dataIndex: "paidSum",
      key: "paidSum",
      sorter: (a, b) => a.data.paymentTotalPrice - b.data.paymentTotalPrice,
    },
  ];

  const dataSource = [];
  let i = 0;
  for (let data of utmData) {
    dataSource.push({
      key: i,
      data: data,
      utmSource: data.utmSource || "organic",
      utmMedium: data.utmMedium,
      utmCampaign: data.utmCampaign,
      openLanding: data.openLandingCount,
      registration: data.registrationCount,
      paidCount: data.paymentCount,
      storeCount: data.addStoreCount,
      paidSum: currencyFormat(data.paymentTotalPrice),
      inTest: data.inTestCount,
      utmContent: data.utmContent,
      openSystem: data.openSystemCount,
    });
    i++;
  }

  const filters = [
    {
      key: "today",
      label: "Сегодня",
      fromDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
        0,
        0,
        0
      ),
      tillDate: new Date(),
    },
    {
      key: "yesterday",
      label: "Вчера",
      fromDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 1,
        0,
        0,
        0
      ),
      tillDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 1,
        23,
        59,
        59
      ),
    },
    {
      key: "week",
      label: "Неделя",
      fromDate: new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000),
      tillDate: new Date(),
    },
    {
      key: "month",
      label: "Месяц",
      fromDate: new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000),
      tillDate: new Date(),
    },
  ];

  return (
    <div>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          margin: "10px 0",
        }}
      >
        {filters.map((filter) => {
          return (
            <div
              onClick={() => {
                setSelectedFilterKey(filter.key);
                setFromDate(filter.fromDate);
                setTillDate(filter.tillDate);
              }}
              style={{
                width: 100,
                textAlign: "center",
                border: "1px solid #1890ff",
                borderRadius: 5,
                margin: "0 5px",
                backgroundColor:
                  filter.key === selectedFilterKey ? "#1890ff" : "white",
                color: filter.key === selectedFilterKey ? "white" : "#1890ff",
                cursor: "pointer",
              }}
            >
              {filter.label}
            </div>
          );
        })}
      </div>
      <div style={{ overflow: "auto" }}>
        <Table
          showSorterTooltip={false}
          bordered
          columns={columns}
          dataSource={dataSource}
          loading={isLoading}
          pagination={false}
          expandable={{
            expandedRowRender: (record) => {
              return <UtmFunnel record={record.data} />;
            },
            rowExpandable: (record) => record.name !== "Not Expandable",
          }}
        />
      </div>
    </div>
  );
};

const UtmFunnel = ({ record }) => {
  const data = [
    {
      name: "Открыли сайт",
      value: record.openLandingCount || 0,
    },
    {
      name: "Открыли систему",
      value: record.openSystemCount || 0,
    },
    {
      name: "Зарегистрировались",
      value: record.registrationCount || 0,
    },
    {
      name: "Добавили магазин",
      value: record.addStoreCount || 0,
    },
    {
      name: "Оплатили",
      value: record.paymentCount || 0,
    },
  ];

  return (
    <div
      style={{
        margin: "0 15px",
      }}
    >
      <div style={{ overflow: "auto" }} className="mb-3">
        <Table
          bordered
          size="small"
          style={{ maxWidth: 800 }}
          pagination={false}
          columns={[
            {
              dataIndex: "user",
              title: "Пользователь",
            },
            {
              dataIndex: "store",
              title: "Магазин",
            },
            {
              dataIndex: "payment",
              title: "Оплата",
            },
            {
              dataIndex: "status",
              title: "Cтатус",
              filters: [
                {
                  text: "На тесте",
                  value: "IN_TEST",
                },
                {
                  text: "Не добавил магазин",
                  value: "NO_STORE",
                },
                {
                  text: "Оплатил",
                  value: "PAID",
                },
                {
                  text: "Не оплатил",
                  value: "NOT_PAID",
                },
              ],
              onFilter: (value, record) => record.data.statusCode === value,
            },
          ]}
          dataSource={record.userData.map((userData, index) => {
            const store = userData?.stores?.length ? userData.stores[0] : null;
            let payment = userData?.payments?.length
              ? userData.payments[0]
              : null;

            let status = <></>;
            let statusCode = "";
            if (!store) {
              status = <div className="text-danger">Не добавил магазин</div>;
              statusCode = "NO_STORE";
            } else if (store && payment) {
              status = (
                <div className="text-success d-flex justify-content-between">
                  <div>Оплатил</div>
                  <div className="text-secondary" style={{ fontSize: 12 }}>
                    ({new Date(payment.createdAt).toLocaleDateString()})
                  </div>
                </div>
              );
              statusCode = "PAID";
            } else if (store && new Date(store.expireDate) > new Date()) {
              status = (
                <div className="text-warning d-flex justify-content-between">
                  <div>На тесте</div>
                  <div className="text-secondary" style={{ fontSize: 12 }}>
                    (до {new Date(store.expireDate).toLocaleDateString()})
                  </div>
                </div>
              );
              statusCode = "IN_TEST";
            } else if (store && !payment) {
              status = (
                <div className="text-danger d-flex justify-content-between">
                  <div>Не оплатил</div>
                  <div className="text-secondary" style={{ fontSize: 12 }}>
                    (от {new Date(store.expireDate).toLocaleDateString()})
                  </div>
                </div>
              );
              statusCode = "NOT_PAID";
            }

            return {
              data: {
                statusCode,
              },
              user: (
                <div className="d-flex align-items-center justify-content-between">
                  <Link to={`/client/${userData._id}`}>
                    {userData.phoneNumber}
                  </Link>
                  <div className="ms-3 text-secondary" style={{ fontSize: 12 }}>
                    ({new Date(userData.createdAt).toLocaleDateString()})
                  </div>
                </div>
              ),
              store: store ? (
                <div className="d-flex align-items-center justify-content-between">
                  <Link to={`/store/${store._id}`}>{store.name}</Link>
                  <div className="ms-3 text-secondary" style={{ fontSize: 12 }}>
                    ({new Date(store.createdAt).toLocaleDateString()})
                  </div>
                </div>
              ) : (
                ""
              ),
              payment: payment ? (
                <div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div style={{ whiteSpace: "nowrap" }}>
                      {currencyFormat(payment.price)}
                    </div>
                    <div
                      className="ms-3 text-secondary"
                      style={{ fontSize: 12 }}
                    >
                      ({new Date(payment.createdAt).toLocaleDateString()})
                    </div>
                  </div>
                  <div className="text-secondary">
                    {payment.type || "SUBSCRIPTION"}
                  </div>
                </div>
              ) : (
                ""
              ),
              status,
            };
          })}
        />
      </div>

      <Funnel data={data} xField="name" yField="value" legend={true} />
    </div>
  );
};

export default UtmStatistics;
