import { Card } from "antd";
import React, { useState, useEffect } from "react";
import {
  ExpectTodayPaid,
  GaugeTotalPricePlan,
  PrivilegedStoreList,
  TodayPaid,
  UserReferralStats,
} from "./components";
import YesterdayUnpaidStores from "./components/YesterdayUnpaidStores";
import useIsMobile from "../../hooks/useIsMobile";

const Home = () => {
  const isMobile = useIsMobile()
  const [refreshCounter, setRefreshCounter] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setRefreshCounter((prev) => prev + 1);
    }, 30000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div
      style={{
        padding: isMobile ?  "0 8px" : "0 20px",
        marginBottom: 30,
      }}
    >
      <Card>
        <h1
          style={{
            fontSize: 18,
          }}
        >
          Главная
        </h1>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "10px",
            flexDirection:isMobile ? 'column' : 'row',
            alignItems: "flex-start",
          }}
        >
          <div
            style={{
              width: isMobile ? '100%' : "50%",
            }}
          >
            <GaugeTotalPricePlan refreshCounter={refreshCounter} />
          </div>
          <div
            style={{
              width: isMobile ? '100%' : "50%",
              maxHeight: "680px",
              overflowY: "auto",
              border: "1px solid #f0f0f0",
              padding: "8px",
            }}
          >
            <TodayPaid refreshCounter={refreshCounter} />
          </div>
          </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            width: "100%",
          }}
        >
          <ExpectTodayPaid />
          <YesterdayUnpaidStores />
        </div>
        <UserReferralStats />
      </Card>
    </div>
  );
};

export default Home;
