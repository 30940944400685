import { Button, Card, DatePicker, Empty, message, Spin } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { getEndDate, getStartDate } from "../../../scripts/format";
import config from "../../../config.json";
import { ReloadOutlined } from "@ant-design/icons";

import ProxySpeedChart from "./ProxySpeedChart";

const { RangePicker } = DatePicker;

const ProxySpeedInfo = ({ data }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDay, setSelectedDay] = useState("today");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [requests, setRequests] = useState([]);

  const onOk = (value) => {
    setSelectedDay("");

    if (value[0]._d) {
      setStartDate(value[0]);
    }
    if (value[1]) {
      setEndDate(value[1]);
    }
  };

  useEffect(() => {
    fetch();
  }, [startDate, endDate, selectedDay, data]);

  const fetch = () => {
    if (!selectedDay) {
      if (!startDate || !endDate) {
        return;
      }
    }

    setIsLoading(true);

    const proxy = data?.proxy || `http://${data?.host}:${data?.port}`;
    const gte = startDate?._d || getStartDate(selectedDay);
    const lte = endDate?._d || getEndDate(selectedDay);

    axios
      .post(
        `${config.service}/api/proxy/chart`,
        {
          proxy,
          gte,
          lte,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              "admin_access_token"
            )}`,
          },
        }
      )
      .then((data) => {
        setRequests(data.data);
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          localStorage.clear();

          window.location.reload();
        }

        message.error(
          `Не удалось загрузить данные | ${
            data?.host ? `${data.host}:${data?.port}` : ""
          }`
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Card>
      <div className="d-flex flex-column">
        <div className="d-flex align-items-center mb-3">
          <div
            className={`border rounded px-3 py-1 text-center me-3 cursor-pointer ${
              selectedDay === "today" ? "text-primary border-primary" : ""
            } ${isLoading ? "text-secondary" : ""}`}
            style={{
              width: 100,
            }}
            onClick={() => {
              if (!isLoading) {
                setStartDate(undefined);
                setEndDate(undefined);

                setSelectedDay("today");
              }
            }}
          >
            Cегодня
          </div>
          <div
            className={`border rounded px-3 py-1 text-center cursor-pointer ${
              selectedDay === "yesterday" ? "text-primary border-primary" : ""
            } ${isLoading ? "text-secondary" : ""}`}
            style={{
              width: 100,
            }}
            onClick={() => {
              if (!isLoading) {
                setStartDate(undefined);
                setEndDate(undefined);

                setSelectedDay("yesterday");
              }
            }}
          >
            Вчера
          </div>
        </div>
        <div>
          <div className="mb-1">Диапазон:</div>
          <RangePicker
            disabled={isLoading}
            value={[startDate, endDate]}
            allowClear={false}
            showTime={{ format: "HH:mm" }}
            format="YYYY-MM-DD HH:mm"
            onOk={onOk}
          />
        </div>
      </div>
      <Button
        type="primary"
        className="mt-3"
        icon={<ReloadOutlined />}
        loading={isLoading}
        onClick={fetch}
      >
        Обновить данные
      </Button>
      {isLoading && (
        <div
          className="w-100 d-flex align-items-center justify-content-center"
          style={{
            height: 300,
          }}
        >
          <Spin />
        </div>
      )}
      {requests.length === 0 && !isLoading && (
        <div
          className="my-3"
          style={{
            height: 250,
          }}
        >
          <Empty />
        </div>
      )}
      {requests.length > 0 && !isLoading && (
        <div className="d-flex flex-wrap">
          {requests.map((request, index) => {
            return <ProxySpeedChart key={index} data={request} />;
          })}
        </div>
      )}
    </Card>
  );
};

export default ProxySpeedInfo;
