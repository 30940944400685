import { Calendar, Card } from "antd";
import { useEffect, useState } from "react";
import { getTopClientCategoriesByProductsForMonthAndYear } from "../utils/fetch";
import { currencyFormat } from "../../../scripts/format";

const TopClientCategoriesByProductsForMonthAndYear = () => {
  const [
    topClientCategoriesByProductsForMonthAndYear,
    setTopClientCategoriesByProductsForMonthAndYear,
  ] = useState([]);

  useEffect(() => {
    getTopClientCategoriesByProductsForMonthAndYear().then(
      setTopClientCategoriesByProductsForMonthAndYear
    );
  }, []);

  const getListData = (value) => {
    const selectedDate = value.toDate();

    let data = null;

    for (const topClientCategoryByProductsForMonthAndYear of topClientCategoriesByProductsForMonthAndYear) {
      const date = new Date(topClientCategoryByProductsForMonthAndYear.date);

      if (
        selectedDate.getFullYear() === date.getFullYear() &&
        selectedDate.getMonth() === date.getMonth()
      ) {
        data = topClientCategoryByProductsForMonthAndYear.categories;
      }
    }

    if (!data) {
      return (
        <div className="h-100 w-100 d-flex align-items-center justify-content-center">
          <i>Нет данных</i>
        </div>
      );
    }

    return (
      <ul>
        {data.map((v) => {
          return (
            <li>
              {v.categoryName} (маг: {v.storesCount || 0}, тов:{" "}
              {currencyFormat(v.productsCount || 0)})
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <Card className="mt-3">
      <h1>ТОП-5 категории клиентов по добавленным товарам</h1>
      <Calendar monthCellRender={getListData} mode="year" />
    </Card>
  );
};

export default TopClientCategoriesByProductsForMonthAndYear;
