import axios from "axios";
import config from "../../../config.json";
import { Button, DatePicker, Modal, message } from "antd";
import { useState } from "react";

const AddStoreAnalyticsGatheringButton = ({ storeId }) => {
  const [expirationDate, setExpirationDate] = useState(
    new Date().getTime() + 2 * 60 * 60 * 1000
  );
  const [open, setOpen] = useState(false);

  const onSubmit = () => {
    axios
      .post(
        `${config.service}/api/store-product-position-analytics/stores/${storeId}/start`,
        {
          expirationDate,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              "admin_access_token"
            )}`,
          },
        }
      )
      .then(() => {
        message.success("Сборка аналитики по позициям запущена");
        setOpen(false);
      })
      .catch((err) => {
        const msgText =
          err?.response?.data?.message ||
          "Не удалось запустить сборку аналитики";

        if (err?.response.status === 401) {
          window.location.reload();
        }

        message.error(msgText);
      });
  };

  return (
    <>
      <Button onClick={() => setOpen(true)}>
        Начать собрать аналитику по позициям товаров
      </Button>
      <Modal
        destroyOnClose
        title="Начать собрать аналитику по позициям товаров"
        open={open}
        onCancel={() => setOpen(false)}
        onOk={onSubmit}
      >
        <div>
          <div>
            <b>До какого периода надо собирать аналитику?</b>
          </div>
          <div className="my-3">
            <i>
              Сбор аналитических данных доступен на протяжении 12&nbsp;часов. Важно
              учитывать, что даже при выборе более длительного срока, система
              автоматически устанавливает временной отрезок на 12&nbsp;часов вперед
              от настоящего времени, если выбранное время превышает этот период.
            </i>
          </div>
          <DatePicker
            showTime
            onChange={(v) => {
              setExpirationDate(v.toDate().getTime());
            }}
          />
        </div>
      </Modal>
    </>
  );
};

export default AddStoreAnalyticsGatheringButton;
