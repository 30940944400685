import { Button, Card, Form, Pagination, Select } from "antd";
import Search from "antd/lib/transfer/search";
import axios from "axios";
import React, { useEffect, useState } from "react";
import config from "../../config.json";
import {
  useNavigate,
  createSearchParams,
  useSearchParams,
} from "react-router-dom";
import ShowStores from "./components/ShowStores";
import { clearToken } from "../../scripts/token";
import { SearchOutlined } from "@ant-design/icons";
import { PriceCalculator } from "../Home/components"; // калькулятор
import useIsMobile from "../../hooks/useIsMobile";

const { Option } = Select;

const Stores = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const isMobile = useIsMobile()
  const [stores, setStores] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(searchParams.get("page") || 1);
  const [limit, setLimit] = useState(searchParams.get("limit") || 10);
  const [query, setQuery] = useState(searchParams.get("q") || "");
  const [filter, setFilter] = useState(searchParams.get("filter") || "all");
  const [marketplace, setMarketplace] = useState();
  const [marketplaces, setmarketplaces] = useState([]);
  const [isMarketplaceLoading, setIsMarketplaceLoading] = useState(false);

  useEffect(() => {
    setIsMarketplaceLoading(true);

    axios
      .get(`${config.service}/api/marketplace`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
        },
      })
      .then((data) => {
        setmarketplaces([
          {
            _id: "",
            name: "Любой",
          },
          ...data.data,
        ]);
      })
      .finally(() => setIsMarketplaceLoading(false));
  }, []);

  useEffect(() => {
    fetchStores();
  }, [currentPage, limit]);

  const fetchStores = () => {
    setIsLoading(true);
    
    axios
      .get(
        `${config.service}/api/store?page=${currentPage}&limit=${limit}&q=${query}&filter=${filter}&marketplace=${marketplace}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              "admin_access_token"
            )}`,
          },
        }
      )
      .then((data) => {
        setStores(data.data.data);
        setTotalCount(data.data.total);
        navigate({
          pathname: "",
          search: createSearchParams({
            page: 1,
            q: query || "",
            limit,
            filter,
          }).toString(),
        });
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          clearToken();
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSearch = () => {
    navigate({
      pathname: "",
      search: createSearchParams({
        page: currentPage,
        q: query || "",
        limit,
        filter,
      }).toString(),
    });
    
    fetchStores();
    setCurrentPage(1)
  };

  const getSearchForm = () => {
    return (
      <Form className="store-inputs-flex"  onFinish={handleSearch}>
        <Select
          style={{
            minWidth: 150,
            width:'100%'
          }}
          value={filter}
          onChange={(value) => {
            setFilter(value);
          }}
          disabled={isLoading}
          className="me-1"
        >
          <option value="all" selected>
            Все
          </option>
          <option value="test">На тесте</option>
          <option value="paid">Оплаченные</option>
          <option value="not-paid">Не оплаченные</option>
          <option value="stopped">Остановлены</option>
          <option value="bad-credentials">Неверные уч. данные</option>
          <option value="by-phone">По номеру телефона</option>
        </Select>
        <Search
          placeholder="Поиск по ключевому слову"
          allowClear
          enterButton="Поиск"
          size="large"
          disabled={isLoading}
          value={query}
          onChange={(event) => {
            setQuery(event.target.value);
          }}
        />
        <Button
          htmlType="submit"
          type="primary"
          loading={isLoading}
          className="ms-1"
          icon={<SearchOutlined />}
        >
          Поиск
        </Button>
      </Form>
    );
  };

  const getFilterForm = () => {
    return (
      <div className="mt-3">
        <Select
          loading={isMarketplaceLoading}
          value={marketplace}
          placeholder="Выберите маркетплейс..."
          style={{ width: 300 }}
          onChange={(v) => {
            setMarketplace(v);
          }}
        >
          {marketplaces.map((m, index) => {
            return (
              <Option key={index} value={m?._id}>
                <div className="d-flex align-items-center">
                  {m?.logo && (
                    <img
                      className="me-3"
                      style={{ width: 16, height: 16 }}
                      src={m.logo}
                    />
                  )}
                  <div>{m?.name}</div>
                </div>
              </Option>
            );
          })}
        </Select>
      </div>
    );
  };

  const getPagination = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Pagination
          current={currentPage}
          total={totalCount}
          disabled={isLoading}
          onChange={(value) => {
            if (value === currentPage) {
              return;
            }

            setCurrentPage(value);

            navigate({
              pathname: "",
              search: createSearchParams({
                page: value,
                q: query || "",
                limit,
                filter,
              }).toString(),
            });
          }}
          defaultCurrent={currentPage}
          showTotal={(total, range) => `${range[0]}-${range[1]} из ${total}`}
          onShowSizeChange={async (current, size) => {
            if (size === limit) {
              return;
            }

            setLimit(size);

            navigate({
              pathname: "",
              search: createSearchParams({
                page: currentPage,
                q: query || "",
                limit: size,
                filter,
              }).toString(),
            });
          }}
          pageSize={limit}
          defaultPageSize={limit}
        />
      </div>
    );
  };

  return (
    <Card className={isMobile ? 'mx-2' : "mx-3"}>
      <div
        className="store-top-card-grid"
        
      >
        <div style={{maxWidth:'100%'}}>
          <h1
            style={{
              fontSize: 18,
              paddingBottom:10
            }}
          >
            Магазины
          </h1>
          {getSearchForm()}
          {getFilterForm()}
        </div>
        <div className="store-calc-container">
           <PriceCalculator /> 
        </div>
      
      </div>

      <ShowStores isLoading={isLoading} stores={stores} />
      {getPagination()}
    </Card>
  );
};

export default Stores;


{/* <Card className="mx-3">
      <h1
        style={{
          fontSize: 18,
        }}
      >
        Магазины
      </h1>
      {getSearchForm()}
      {getFilterForm()}
      <ShowStores isLoading={isLoading} stores={stores} />
      {getPagination()}
      <PriceCalculator />
    </Card> */}
  