import { Button, Card } from "antd";
import { useEffect, useState } from "react";
import config from "../../../config.json";
import axios from "axios";
import { Column } from "@ant-design/plots";
import { ReloadOutlined } from "@ant-design/icons";

const DynamicFirstPlacePercentage = ({
  store,
  startDate,
  endDate,
  masterSku,
}) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetch = () => {
    setIsLoading(true);

    axios
      .get(
        `${config.service}/api/store-product-position-analytics/${
          store.storeForGatheringProductAnalyticsId
        }/dynamics-first-place-percentage?startDate=${startDate}&endDate=${endDate}&masterSku=${
          masterSku || ""
        }`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              "admin_access_token"
            )}`,
          },
        }
      )
      .then((data) => setData(data.data))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    fetch();
  }, [store, startDate, endDate, masterSku]);

  const chartData = [];
  for (const d of data) {
    chartData.push({
      label: `${d.date}, ${d.hour + 6}:00-${d.hour + 6}:59`,
      value: d.firstPlacePercentage,
    });
  }

  return (
    <Card className="my-3">
      <h3>Динамика процента товаров на первом месте за выбранный период</h3>
      <Button
        className="my-3"
        onClick={() => fetch()}
        type="primary"
        ghost
        icon={<ReloadOutlined />}
        loading={isLoading}
      >
        Обновить
      </Button>
      <Column data={chartData} xField="label" yField="value" />
    </Card>
  );
};

export default DynamicFirstPlacePercentage;
